import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import LinkConstants from "../../../constants/LinkConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import styles from "./InvalidSubscriptionPopup.module.scss";
import { isCampaignEditor } from "../../../utils/index.ts";

const InvalidSubscriptionPopup = () => {
	const { t } = useTranslation("translation", { keyPrefix: "invalidSubscriptionPopup" });
	const { authStore, pricingStore, uiStore } = useAppStore();

	return (
		<ConfirmationPopup
			paperClassName={styles.invalidSubscriptionPopup}
			onClose={() => {
				if (!iframeStore.isIframe() && !isCampaignEditor) {
					uiStore.hideDialog("InvalidSubscriptionPopup");
				}
			}}
			title={t("title")}
			confirmButtonText={t(iframeStore.isIframe() ? "buttonTextIframe" : "buttonText")}
			hideCloseIcon={iframeStore.isIframe() || isCampaignEditor}
			hideCancel={true}
			description={
				<Typography className={styles.description}>
					{t("description")}
				</Typography>
			}
			open={
				(!iframeStore.isIframe() || (!iframeStore.isPreview() && iframeStore.isInitialized)) &&
				authStore.isSubscriptionInactive &&
				uiStore.InvalidSubscriptionPopup &&
				!pricingStore.isPricingFlowOpen
			}
			onClick={() => {
				if (iframeStore.isIframe()) {
					window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank");
				} else {
					pricingStore.openPricingFlow();
				}
			}}
		/>
	);
};

export default observer(InvalidSubscriptionPopup);

import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import ThumbDown from "../../../assets/images/icons/ThumbDown.tsx";
import ThumbUp from "../../../assets/images/icons/ThumbUp.tsx";
import BriaButtonGroup from "../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../hooks/useStores";
import { PlaygroundImage } from "../../../models/image-to-image";
import styles from "./FeedbackButtons.module.scss";
import FeedbackPopup from "./FeedbackPopup.tsx";

export type FeedbackButtonsProps = {
	image: PlaygroundImage;
	appName: string;
	projectName?: string;
	projectId?: string;
	modelName?: string;
	modelId?: string;
};

export type Feedback = {
	appName: string;
	type: string;
	imageUrl: string;
	text?: string;
	projectName?: string;
	projectId?: string;
	modelName?: string;
	modelId?: string;
};

export enum FeedbackTypeEnum {
	Positive = "Positive",
	Negative = "Negative",
}

const FeedbackButtons = ({ image, appName, projectName, projectId, modelName, modelId }: FeedbackButtonsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "feedbackButtons" });
	const { playgroundStore } = useAppStore();
	const [openFeedbackBox, setOpenFeedbackBox] = useState(false);
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);

	const sendPositiveFeedback = async (e: any, imageUrl: string) => {
		e.stopPropagation();
		setOpenFeedbackBox(false);

		let feedback: Feedback = {
			appName: appName,
			type:  FeedbackTypeEnum.Positive,
			imageUrl: imageUrl,
		};

		if (modelId !== undefined) {
			feedback = {
				...feedback,
				projectName: projectName,
				projectId: projectId,
				modelName: modelName,
				modelId: modelId,
			};
		}

		const alreadySent = playgroundStore.feedbackHistory.find(
			(feedback) => feedback.imageUrl === imageUrl && feedback.type === FeedbackTypeEnum.Positive,
		);

		if (!alreadySent) {
			await playgroundStore.addOrUpdateFeedback(feedback);
		}
	};

	const handleMouseClick = (e: React.MouseEvent<HTMLElement>) => {
        const { offsetLeft, offsetTop } = e.currentTarget;
        const mouseX = e.clientX - offsetLeft;
        const mouseY = e.clientY - offsetTop;
        setLeft(mouseX);
        setTop(mouseY - 400);
    };

	return (
		<Box className={clsx(styles.container, "container")}>
			<Box className={clsx(styles.imageFooter)}>
				<Box className={styles.feedbackWrapper}>
					<BriaButtonGroup className={styles.feedbackBtns}>
						<BriaTooltip title={t("like")}>
							<IconButton
								className={clsx("iconHover", styles.icon, {
									[styles.selected]: playgroundStore.feedbackHistory.find(
										(feedback) =>
											feedback.imageUrl === image.url &&
											feedback.type === FeedbackTypeEnum.Positive,
									),
								})}
								onClick={(e) => sendPositiveFeedback(e, image.url)}
							>
								<ThumbUp />
							</IconButton>
						</BriaTooltip>
						<BriaTooltip title={t("disLike.tooltip")}>
							<IconButton
								className={clsx(styles.icon, "dislikeIconRef", "iconHover", {
									[styles.selected]: playgroundStore.feedbackHistory.find(
										(feedback) =>
											feedback.imageUrl === image.url &&
											feedback.type === FeedbackTypeEnum.Negative,
									),
								})}
								onClick={(e) => {
									e.stopPropagation();
									handleMouseClick(e);
									setOpenFeedbackBox(!openFeedbackBox);
								}}
							>
								<ThumbDown />
							</IconButton>
						</BriaTooltip>
					</BriaButtonGroup>

					<FeedbackPopup
						onClose={() => {setOpenFeedbackBox(false);}}
						open={openFeedbackBox}
						left={left}
						top={top}
						appName={appName}
						image={image}
						projectName={projectName}
						projectId={projectId}
						modelName={modelName}
						modelId={modelId}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(FeedbackButtons);
export default ObservedComponent;

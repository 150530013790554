import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import EmailIcon from "../../../assets/images/icons/EmailIcon.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import { firebaseAuth } from "../../../config/firebase.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { navigateWithParams } from "../../../utils";
import styles from "./AccountVerification.module.scss";
import { APPS } from "../../../constants/AppsConstants.ts";

const AccountVerification = () => {
	const { t } = useTranslation("translation", { keyPrefix: "accountVerification" });
	const { authStore } = useAppStore();
	const { sendEmailVerificationFunction } = useAuthService();
	const [isSent, setIsSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const queryParams = new URLSearchParams(location.search);
	const continueUrl = queryParams.get("continueUrl") || undefined;
	const { state } = useLocation();
	const navigate = useSecureNavigate();
	const { showError } = useErrorToast();

	useEffect(() => {
		if (firebaseAuth.currentUser?.emailVerified) {
			if (continueUrl?.includes(APPS.AUTOMOTIVE)) {
				navigateWithParams(navigate, RouterConstants.AUTOMOTIVE.path);
			} else {
				navigateWithParams(navigate, RouterConstants.CONSOLE.fullPath);
			}
		}
	}, []);

	const handleSubmit = async () => {
		try {
			setIsSent(false);
			setLoading(true);
			await sendEmailVerificationFunction(continueUrl, state);
			setIsSent(true);
		} catch {
			showError();
			setIsSent(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box className={styles.accountVerificationContainer}>
			<Box className={styles.emailSent}>
				<Typography className={styles.mainTitle}>{t("title")}</Typography>
			</Box>

			<EmailIcon className={styles.icon} width={60} />

			<Box className={styles.descriptionContainer} textAlign="center" padding="10px">
				<Typography className={styles.description}>
					<Trans i18nKey={"accountVerification.description"} values={{ email: authStore.user?.email }} />
				</Typography>
				{isSent && (
					<Typography className={clsx(styles.description, styles.bold)}>
						<Trans i18nKey={"accountVerification.successMessage"} />
					</Typography>
				)}
			</Box>

			<Box className={styles.buttonContainer}>
				<BriaButton buttonType={"primaryMedium"} onClick={handleSubmit} loading={loading}>
					{isSent ? t("sendVerificationAgain") : t("sendVerificationEmail")}
				</BriaButton>
				<BriaButton buttonType={"secondaryMedium"} onClick={authStore.logout}>
					{t("useDifferentAccount")}
				</BriaButton>
			</Box>
		</Box>
	);
};

export default AccountVerification;

import { Block } from "baseui/block";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useTemplateUtils from "../../../../../DesignEditor/CustomUtils/UseTemplateUtils.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { CampaignEntityStatus } from "../../../../../models/common";
import { UrlPathEntities } from "../../../../campaign-store.tsx";
import { PanelType } from "../../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../../contexts/DesignEditor";
import useAppContext from "../../../../hooks/useAppContext";
import useIsSidebarOpen from "../../../../hooks/useIsSidebarOpen";
import GuideTooltip from "../TemplatesCreationGuideSection/GuideTooltip/GuideTooltip.tsx";
import useGuideTemplatesUtils from "../TemplatesCreationGuideSection/useGuideTemplatesUtils.tsx";
import panelItems from "./panelItems";

interface State {
	panel: string;
}
function PanelsList() {
	const { getCampaignDetailsFromURL } = useTemplateUtils();
	const pathParts = window.location.pathname.split("/");
	const selectedType = pathParts[3];
	const { campaignStore, uiStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const [state, setState] = React.useState<State>(
		isPopupView
			? { panel: PanelType.TEMPLATES }
			: selectedType === UrlPathEntities.TEMPLATES && !campaignStore.isSavingToMyCampaign
			? { panel: PanelType.TEMPLATES }
			: { panel: PanelType.CAMPAIGNS },
	);
	const isSidebarOpen = useIsSidebarOpen();
	const { activePanel, activeSubMenu } = useAppContext();
	const { id: campaignId } = getCampaignDetailsFromURL();
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplatesGuideSection" });

	const { getSpecificTemplateTourPageIndex } = useGuideTemplatesUtils();
	const duplicateAndResizeIndex = getSpecificTemplateTourPageIndex(t("duplicateAndResizeScene.title"));

	React.useEffect(() => {
		setState({ panel: activePanel });
	}, [activePanel]);

	React.useEffect(() => {
		if (activeSubMenu) {
			setState({ panel: activeSubMenu });
		} else {
			setState({ panel: activePanel });
		}
	}, [activeSubMenu]);

	// @ts-ignore
	const Component = panelItems[state.panel];
	return (
		<Block
			id="EditorPanelItem"
			$style={{
				width: isSidebarOpen ? "324px" : 0,
				marginBottom: "8px",
				flex: "none",
				display: isSidebarOpen ? "flex" : "none",
				transition: "ease width 0.1s",
				borderRadius: "8px",
				border: "1px solid #E7E7E7",
				background: "#FFF",
				boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.04)",
				// paddingRight: isSidebarOpen ? "16px" : "",
				// paddingLeft: isSidebarOpen ? "16px" : "",
			}}
		>
			{Component && (
				<Component
					key={state.panel}
					type={
						activePanel.toString() == "Campaigns" ? CampaignEntityStatus.DRAFT : CampaignEntityStatus.ACTIVE
					}
					campaignId={campaignId}
				/>
			)}
			{uiStore.selectedGuideIndex !== duplicateAndResizeIndex && <GuideTooltip />}
		</Block>
	);
}

const ObservedComponent = observer(PanelsList);
export default ObservedComponent;

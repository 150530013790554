import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis, TooltipProps } from "recharts";
import {
    ValueType,
    NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import styled from "styled-components";

const StyledLineChart = styled.div`
	.recharts-legend-item {
		margin-bottom: 10px;
	}
	.recharts-legend-item-text {
		color: var(--secondary-color) !important;
		font-size: 12px;
		margin-left: 5px;
	}
	.recharts-legend-wrapper {
		left: 30px !important;
		bottom: -5px !important;
	}
	.recharts-wrapper text {
		fill: var(--secondary-color) !important;
	}
	.recharts-tooltip-wrapper {
		z-index: auto!important;
	}
	.custom-tooltip {
		background-color: #fff;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 5px;
		z-index: auto;
	}
`;

interface ILineChart {
	data: any;
	colors: string[];
	total?: number;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
	if (active && payload && payload.length) {
		const filteredPayload = payload
			.filter((entry) => entry.value !== 0 && entry.value !== undefined)
			.sort((a, b) => (a.value! < b.value! ? 1 : -1));
		return (
			<div className="custom-tooltip bg-white p-2 border border-gray-300 shadow-md rounded-md">
				<p className="text-gray-700 font-semibold">{label}</p>
					{filteredPayload.map((entry) => (
						<span style={{ color: entry.color, display: "block", marginBottom: 3 }}>{entry.name}: <strong>{entry.value}</strong></span>
					))}
			</div>
		);
	}

	return null;
};

const CustomLineChart: React.FC<ILineChart> = ({ data, colors, total = 0, ...rest }) => {
	return (
		<StyledLineChart>
			<LineChart
				width={730}
				height={250}
				data={data}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
				{...rest}
			>
				<CartesianGrid strokeDasharray="0" />
				<XAxis
					dataKey="name"
					tick={{ color: "#5B5B5B", fontSize: "10px", fontWeight: 600 }}
					axisLine={false}
					tickLine={false}
					tickMargin={7}
				/>
				<YAxis
					tick={{ color: "#5B5B5B", opacity: "0.5", fontSize: "10px", fontWeight: 600 }}
					axisLine={false}
					tickLine={false}
					tickMargin={25}
				/>
				<Tooltip itemStyle={{ fontSize: 16 }} content={<CustomTooltip />} />
				{total > 0 && <Legend align="left" iconSize={16} />}

				{data &&
					data.length > 0 &&
					Object.keys(data[0]).map(
						(key, idx) =>
							key !== "name" && (
								<Line
									key={idx}
									type="linear"
									strokeWidth={2}
									legendType="square"
									dot={{ fill: colors[idx % colors.length] }}
									dataKey={key}
									stroke={colors[idx]}
								/>
							),
					)}
			</LineChart>
		</StyledLineChart>
	);
};

export default CustomLineChart;

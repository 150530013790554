import { Box, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../assets/images/icons/CloseIcon.tsx";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import { useGuideItems } from "../TemplatesTourPagesData.tsx";
import useGuideTemplatesUtils from "../useGuideTemplatesUtils.tsx";
import styles from "./GuideTooltip.module.scss";
const GuideTooltip = () => {
	const { uiStore } = useAppStore();
	const guideItems = useGuideItems();
	const { handleBack, handleForward, getSpecificTemplateTourPageIndex } = useGuideTemplatesUtils();
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplatesGuideSection" });
	const duplicateAndResizeIndex = getSpecificTemplateTourPageIndex(t("duplicateAndResizeScene.title"));
	return (
		<>
			{uiStore.showGuideToolTip &&
				uiStore.selectedGuideIndex !== 0 &&
				guideItems[uiStore.selectedGuideIndex - 1]?.tooltipTitle &&
				guideItems[uiStore.selectedGuideIndex - 1]?.tooltipDescription && (
					<Box>
						<Tooltip
							arrow
							open={uiStore.showGuideToolTip}
							placement={uiStore.selectedGuideIndex !== duplicateAndResizeIndex ? "right" : "top"}
							onClose={() => {
								uiStore.setProperty("showGuideToolTip", false);
							}}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							componentsProps={{
								popper: {
									className:
										uiStore.selectedGuideIndex !== duplicateAndResizeIndex
											? styles.styledTooltipPopper
											: styles.customStyledTooltipPopper,
								},
								tooltip: {
									className: styles.styledTooltip,
								},
								arrow: {
									className: styles.tooltipArrow,
								},
							}}
							title={
								<Box className={styles.tooltipContent}>
									<CloseIcon
										className={styles.closeIcon}
										onClick={() => uiStore.setProperty("showGuideToolTip", false)}
									/>
									<Typography className={styles.toolTipTitle}>
										{guideItems[uiStore.selectedGuideIndex - 1]?.tooltipTitle}
									</Typography>
									<Typography className={styles.toolTipDescription}>
										{guideItems[uiStore.selectedGuideIndex - 1]?.tooltipDescription}
									</Typography>
									<Box className={styles.toolTipButtons}>
										<BriaButton
											className={styles.previousButton}
											onClick={handleBack}
											buttonType="textMedium"
										>
											{t("previous")}
										</BriaButton>
										<BriaButton
											className={styles.nextButton}
											onClick={handleForward}
											buttonType="primary"
										>
											{t("next")}
										</BriaButton>
									</Box>
								</Box>
							}
						>
							<span />
						</Tooltip>
					</Box>
				)}
		</>
	);
};
const ObservedComponent = observer(GuideTooltip);
export default ObservedComponent;

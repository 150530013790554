import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable.tsx";
import ProjectCard from "../../../components/common/ProjectCard/ProjectCard.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import DataDashboardLayout from "../../../layout/DataDashboard/DataDashboardLayout.tsx";
import { PlaygroundProject } from "../../Playground/store/playground-projects-store.tsx";
import AutomotiveProjectActionsPopover from "./ActionsPopover/AutomotiveProjectActionsPopover.tsx";
import styles from "./AutomotiveProjects.module.scss";
import AutomotiveProjectsEmpty from "./EmptyState/AutomotiveProjectsEmpty.tsx";

const AutomotiveProjects = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.projects" });
	const { uiStore, playgroundStore } = useAppStore();
	const { playgroundProjectsStore } = playgroundStore;

	const navigate = useSecureNavigate();
	const paginationOptions: PaginationOptions<PlaygroundProject> = {
		rowsPerPage: playgroundProjectsStore.rowsPerPage,
		totalRows: playgroundProjectsStore.paginatedProjects.total,
		loadNextPage: playgroundProjectsStore.getProjects,
	};
	const [filterBy, setFilterBy] = useState<FilterByOptions<PlaygroundProject>>();

	useEffect(() => {
		uiStore.showSideBar();
		if (playgroundProjectsStore.paginatedProjects.items.length === 0) {
			playgroundProjectsStore.getProjects(1, { filters: { from_app: APPS.AUTOMOTIVE } });
		}
	}, [uiStore, playgroundProjectsStore]);

	const openProject = () => {};

	const openCreateProject = () => {
		navigate(RouterConstants.NEW_AUTOMOTIVE_PROJECT.fullPath);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions<PlaygroundProject> = {
			...filterBy,
			search: {
				text: e.target.value,
				fields: ["name", "description"],
			},
		};
		setFilterBy(updatedFilterBy);
		await playgroundProjectsStore.getProjects(1, updatedFilterBy);
	};

	return (
		<DataDashboardLayout
			className={styles.layout}
			primaryButton={
				<BriaButton buttonType="primaryMedium" onClick={openCreateProject} className={styles.actionBtn}>
					{t("createNewProject")}
				</BriaButton>
			}
			title={t("title")}
			titleClassName={styles.title}
			description={t("subTitle")}
			descriptionClassName={styles.description}
			enableSearch
			searchInputVisible
			searchBoxPosition="left"
			handleSearchChange={handleSearchChange}
			paginationOptions={paginationOptions}
			filterBy={filterBy}
			loading={playgroundProjectsStore.loadingOrgProjects}
		>
			{!playgroundProjectsStore.paginatedProjects?.items?.length ? (
				<AutomotiveProjectsEmpty />
			) : (
				<Box className={styles.cards}>
					{playgroundProjectsStore.paginatedProjects?.items?.map((project) => {
						const cardFields = [
							{
								key: t("card.vehicleModel"),
								value: project.extra_info?.vehicleModel,
							},
						];

						const buttonProps =
							project.sessions?.length > 0
								? {
										onSecondaryAction: () => openProject(),
										secondaryButtonLabel: t("card.openProject"),
								  }
								: {
										onPrimaryAction: () => openProject(),
										primaryButtonLabel: t("card.createFirstSession"),
								  };
						return (
							<ProjectCard
								className={styles.projectCard}
								key={project.id}
								name={project.name}
								description={project.description}
								cardFields={cardFields}
								actionsPopover={<AutomotiveProjectActionsPopover project={project} />}
								{...buttonProps}
							/>
						);
					})}
				</Box>
			)}
		</DataDashboardLayout>
	);
};

export default observer(AutomotiveProjects);

import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../assets/images/icons/CloudUpload";
import ImageUploadPopup from "../../../../components/Popups/ImageUploadPopup/ImageUploadPopup.tsx";
import ImageUploader from "../../../../components/common/ImageUploader/ImageUploader.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { useManualPlacementConfig } from "../../../../hooks/useManualPlacementConfig.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import iframeStore from "../../../IframeNew/iframe-store.tsx";
import styles from "./EmptyPlayground.module.scss";

interface IEmptyPlayground {
	header?: string;
	subHeader?: string;
	hideSubHeader?: boolean;
}

const EmptyPlayground = ({ header, subHeader, hideSubHeader = false }: IEmptyPlayground) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.emptyState" });
	const { playgroundStore, imageToImageStore, textToImageStore, sandboxAPIStore, authStore } = useAppStore();
	const { checkResolutions } = useManualPlacementConfig();
	useEffect(() => {
		if (sandboxAPIStore.hasImageUpload && playgroundStore.isUploadModalOpened === null) {
			playgroundStore.openUploadModal();
		} else if (!sandboxAPIStore.hasImageUpload) {
			playgroundStore.closeUploadModal();
		}
	}, []);

	const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		await imageToImageStore.handleUploadImages(e);
		checkResolutions();
	};

	return (
		<>
			{(playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE ||
				playgroundStore.selectedConfig === APPS.AUTOMOTIVE) &&
			(!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_upload) ? (
				<Box className={styles.imageToImage}>
					<ImageUploader
						className={styles.imageUploader}
						emptyStateClassName={styles.emptyState}
						icon={<CloudUploadSVG />}
						showPreview={false}
						description={
							<Box className={styles.imageUploaderDesc}>
								<Box className={styles.header}>{header ?? t("header")}</Box>
								{!hideSubHeader && (
									<Box className={styles.subHeader}>{subHeader ?? t("subHeader")}</Box>
								)}
							</Box>
						}
						onUpload={(e) => handleUpload(e)}
						inputProps={{ multiple: true }}
						maxFilesLimit={imageToImageStore.MAX_FILES_LIMIT}
						loading={authStore.isLoadingOrgSubscriptions}
						disabled={authStore.isLoadingOrgSubscriptions || authStore.orgPassedFreeLimit || authStore.isSubscriptionInactive}
						// maxFileSize={imageToImageStore.MAX_FILE_SIZE}
					/>
				</Box>
			) : playgroundStore.selectedConfig === APPS.SANDBOX_API && sandboxAPIStore.hasImageUpload ? (
				<Box className={styles.sandboxAPI}>
					<ImageUploader
						className={styles.imageUploader}
						emptyStateClassName={styles.emptyState}
						descriptionClassName={styles.sandboxapiSupport}
						icon={<CloudUploadSVG />}
						description={
							<Box className={styles.imageUploaderDesc}>
								<Box className={styles.header}>{t("oneImageHeader")}</Box>
							</Box>
						}
						showPreview={true}
						loading={sandboxAPIStore.isUploadingImage}
						onUpload={sandboxAPIStore.handleUploadImages}
						onDelete={sandboxAPIStore.handleUploadDelete}
						inputProps={{ multiple: false }}
						maxFilesLimit={sandboxAPIStore.MAX_FILES_LIMIT}
						maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
					/>
					<ImageUploadPopup
						open={!!playgroundStore.isUploadModalOpened}
						loading={sandboxAPIStore.isUploadingImage}
						onUpload={sandboxAPIStore.handleUploadImages}
						handleCloseClick={playgroundStore.closeUploadModal}
						description={
							<Box className={styles.imageUploadModalDesc}>
								<Box className={styles.header}>{t("oneImageHeader")}</Box>
							</Box>
						}
						paperClassName={`${styles.uploadModalContainer}`}
						hideCloseIcon={true}
					/>
				</Box>
			) : (
				<Box className={`${styles.textToImage}`}>
					{Array.from({ length: 4 }).map((_, _index) => (
						<Box
							width="25%"
							className={styles.skeleton}
							style={{
								aspectRatio: textToImageStore.config.aspect_ratio.replace(":", "/"),
							}}
							key={_index}
						></Box>
					))}
				</Box>
			)}
		</>
	);
};

export default observer(EmptyPlayground);

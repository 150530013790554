import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { capitalizeFirstLetter } from "../../../utils";
import CopyToClipboard from "../../common/CopyToClipboard/CopyToClipboard.tsx";
import LoadingPlaceholder from "../../common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./OrgInfoPopup.module.scss";

const OrgInfoPopup = () => {
	const { uiStore, authStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "orgInfoPopup" });

	const onClose = () => {
		uiStore.hideDialog("OrgInfoPopup");
	};

	const Row = ({ name, value, allowCopy = true }: { name: string; value?: string; allowCopy?: boolean }) => (
		<Typography className={styles.row}>
			<Typography className={styles.boldText}>{name}</Typography>
			{allowCopy ? (
				<CopyToClipboard textClassName={styles.text} className={styles.copyToClipboard} textToCopy={value} />
			) : (
				<Typography className={styles.text}>{value}</Typography>
			)}
		</Typography>
	);

	return (
		<Dialog
			classes={{
				paper: clsx(styles.container),
			}}
			onClose={onClose}
			open={uiStore.OrgInfoPopup}
		>
			<IconButton onClick={onClose} className={styles.closeButton}>
				<Close />
			</IconButton>
			<LoadingPlaceholder isLoading={authStore.isLoadingOrgSubscriptions}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("title")}</Typography>
					<Box className={styles.body}>
						<Row
							name={t("orgName")}
							value={capitalizeFirstLetter(getSelectedOrganization()?.organization?.name ?? "")}
						/>
						<Row name={t("orgId")} value={getSelectedOrganization()?.organization?.uid} />
						<Row name={t("ownerId")} value={getSelectedOrganization()?.organization?.owner_uid} />
						<Row
							name={t("enabledFeatures")}
							value={authStore.orgSubscription?.features?.join(", ")}
							allowCopy={false}
						/>
					</Box>
				</Box>
			</LoadingPlaceholder>
		</Dialog>
	);
};

export default observer(OrgInfoPopup);

import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import SearchIcon from "../../../assets/images/icons/SearchIcon";
import DebouncedInput, { DebouncedInputProps } from "../DebouncedInput/DebouncedInput";
import styles from "./SearchInputField.module.scss";

type SearchInputFieldProps = {
	value?: string;
	searchIcon?: boolean;
	height?: string;
	placeHolder?: string;
	showInput?: boolean;
} & DebouncedInputProps;

const SearchInputField = ({
	value,
	searchIcon = false,
	height = "40px",
	placeHolder,
	showInput = false,
	className,
	...rest
}: SearchInputFieldProps) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = () => setIsFocused(false);

	return (
		<Box className={className} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			{isHovered || isFocused || value || showInput ? (
				<DebouncedInput
					placeholder={placeHolder || ""}
					value={value}
					onFocus={handleFocus}
					onBlur={handleBlur}
					InputProps={{
						className: styles.input,
						startAdornment: searchIcon && (
							<IconButton className={styles.iconButton} disableRipple>
								<SearchIcon className={styles.searchIconStyle} />
							</IconButton>
						),
					}}
					{...rest}
				/>
			) : (
				<IconButton>
					<SearchIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default SearchInputField;

import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../../../components/common/BriaTooltip/BriaTooltip";
import PropertiesIcon from "../../../../../../../assets/svgs/PropertiesIcon.svg";
import FabricObjectsPropertiesPopover from "../../../../Common/FabricObjectsPropertiesPopover/FabricObjectsPropertiesPopover";
import styles from "./FabricObjectsProperties.module.scss";

const FabricObjectsProperties = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });

	const [fabricObjectsPropertiesAnchorEl, setFabricObjectsPropertiesAnchorElAnchorEl] = useState<HTMLButtonElement>();

	const handleOpenPropertiesDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFabricObjectsPropertiesAnchorElAnchorEl(event.currentTarget);
	};
	const handlePropertiesAnchorElClose = () => {
		setFabricObjectsPropertiesAnchorElAnchorEl(undefined);
	};

	return (
		<Box className={styles.propertiesButtonContainer}>
			<BriaTooltip title={t("proporties")} placement="bottom">
				<BriaButton
					buttonType="textMedium"
					onClick={handleOpenPropertiesDialog}
					className={clsx({
						[styles.propertiesButtonWrapper]: true,
						[styles.propertiesButtonSelected]: !!fabricObjectsPropertiesAnchorEl,
					})}
				>
					<img src={PropertiesIcon} />
				</BriaButton>
			</BriaTooltip>
			<FabricObjectsPropertiesPopover
				open={!!fabricObjectsPropertiesAnchorEl}
				handleClose={handlePropertiesAnchorElClose}
				anchorEl={fabricObjectsPropertiesAnchorEl}
			/>
		</Box>
	);
};

const ObservedComponent = observer(FabricObjectsProperties);
export default ObservedComponent;

import { ChevronRight } from "@mui/icons-material";
import { Box, Divider, Link, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import StopIcon from "../../../../../assets/images/icons/StopIcon.tsx";
import StoppableButton from "../../../../../components/common/StoppableButton/StoppableButton.tsx";
import { APPS } from "../../../../../constants/AppsConstants.ts";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../../constants/ImageToImageConstants.ts";
import LinkConstants from "../../../../../constants/LinkConstants.ts";
import { useImageToImageConfig } from "../../../../../hooks/useImageToImageConfig.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import ExpandableMenuButton from "../../../../../layout/ExpandableMenu/Button/ExpandableMenuButton.tsx";
import ExpandableMenuLayout from "../../../../../layout/ExpandableMenu/Menu/ExpandableMenuLayout.tsx";
import {
	ImageEditingConfigsProps,
	ImageEditingViewMode,
	ImageToImageConfigType,
} from "../../../../../models/image-to-image.ts";
import { isPlaygroundLoadingImages } from "../../../../../utils/index.ts";
import iframeStore from "../../../../IframeNew/iframe-store.tsx";
import ConfigButtonLabel from "../ConfigButtonLabel/ConfigButtonLabel.tsx";
import ConfigDisplay from "../ConfigDisplay/ConfigDisplay";
import BackgroundConfig from "../Features/Background/BackgroundConfig";
import EraseObject from "../Features/EraseObject/EraseObject.tsx";
import ForegroundPlacement from "../Features/ForegroundPlacement/ForegroundPlacement";
import ManualPlacement from "../Features/ManualPlacement/ManualPlacement.tsx";
import SizeConfig from "../Features/Size/SizeConfig";
import styles from "../ImageToImageConfig.module.scss";

const ImageEditingConfigs = observer(({ handleGenerateImageToImage }: ImageEditingConfigsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const {
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
	} = useImageToImageConfig();

	const [activeConfig, setActiveConfig] = useState<string>("");
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [isSingleMode, setIsSingleMode] = useState<boolean>(false);

	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.IMAGE_TO_IMAGE);

	useEffect(() => {
		setIsSingleMode(playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE);
	}, [playgroundStore.imageEditingViewMode]);

	const handleDeleteConfig = (configKey: keyof ImageToImageConfigType, field: string, value: any) => {
		const currentConfig = { ...(imageToImageStore.config[configKey] as Record<string, any> | undefined) };

		imageToImageStore.handleConfigChange(configKey, {
			...currentConfig,
			[field]: value,
		});
	};

	const onSubMenuOpen = (open: boolean) => {
		setIsSubMenuOpen(open);
	};

	const ToolTipInfo = ({ tooltip, href }: { tooltip: string; href: string }) => (
		<>
			{tooltip}
			{!iframeStore.isIframe() && (
				<Box className={styles.apiDocContainer}>
					<Link href={href} target={"_blank"} className={styles.link}>
						{t("apiDocumentation")} <ChevronRight />
					</Link>
				</Box>
			)}
		</>
	);
	return (
		<Box className={styles.imageToImageConfigsWrapper}>
			<ExpandableMenuLayout
				className={clsx(styles.config, styles.imageToImageConfigs)}
				setActiveConfig={setActiveConfig}
				isSubMenuOpen={isSubMenuOpen}
			>
				<Box className={styles.configHeader}>
					<Typography variant="h6" className={styles.title}>
						{isSingleMode ? t("singleModeTitle") : t("bulkModeTitle")}
					</Typography>
					<Typography className={styles.subTitle}>
						{isSingleMode ? t("singleModeSubTitle") : t("bulkModeSubTitle")}
					</Typography>
				</Box>
				<ExpandableMenuButton
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
					isActive={activeConfig === t("features.size.title")}
					hidden={isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.size.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.size.tooltip")}
								href={LinkConstants.BRIA_API_IMAGE_EXPANSTION}
							/>,
						)}
					/>
				</ExpandableMenuButton>
				{!isSingleMode && (
					<ConfigDisplay
						config={imageToImageStore.config.size}
						onDelete={(configKey: string, value: string) => {
							handleDeleteConfig("size", configKey, value);
							updateSelectedSizeConfigs();
						}}
						expandTo={<SizeConfig />}
						subMenuPanelProps={{ title: t("features.size.title") }}
					/>
				)}
				<ExpandableMenuButton
					expandTo={<ManualPlacement />}
					subMenuPanelProps={{
						title: t("features.manualPlacement.title"),
						subTitle: t("features.manualPlacement.subTitle"),
					}}
					isActive={activeConfig === t("features.manualPlacement.title")}
					hidden={!isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.manualPlacement.button")}
						info={t("features.manualPlacement.tooltip")}
					/>
				</ExpandableMenuButton>
				<Divider />
				<ExpandableMenuButton
					expandTo={
						<BackgroundConfig
							onSubMenuOpen={onSubMenuOpen}
							handleGenerateImageToImage={handleGenerateImageToImage}
						/>
					}
					subMenuPanelProps={{
						title: t("features.background.title"),
						subTitle: t("features.background.subTitle"),
					}}
					isActive={activeConfig === t("features.background.title")}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.background.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.background.tooltip")}
								href={LinkConstants.BRIA_API_BACKGROUND_REMOVE}
							/>,
						)}
					/>
				</ExpandableMenuButton>
				{!isSingleMode && (
					<ConfigDisplay
						config={imageToImageStore.config.background}
						onDelete={(configKey: string, value: string) => {
							handleDeleteConfig("background", configKey, value);
							updateSelectedBackgroundConfigs();
						}}
						expandTo={
							<BackgroundConfig
								onSubMenuOpen={onSubMenuOpen}
								handleGenerateImageToImage={handleGenerateImageToImage}
							/>
						}
						subMenuPanelProps={{ title: t("features.background.title") }}
					/>
				)}
				<Divider />
				<ExpandableMenuButton
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					isActive={activeConfig === t("features.foregroundPlacement.title")}
					hidden={isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.foregroundPlacement.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.foregroundPlacement.tooltip")}
								href={LinkConstants.BRIA_API_TEXT_PROMPTS_LIFESTYLE}
							/>,
						)}
					/>
				</ExpandableMenuButton>
				{!isSingleMode && (
					<ConfigDisplay
						config={imageToImageStore.config.foreground_placement}
						onDelete={(configKey: string, value: string) => {
							handleDeleteConfig("foreground_placement", configKey, value);
							updateSelectedForegroundPlacementConfigs();
						}}
						expandTo={<ForegroundPlacement />}
						subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					/>
				)}

				<ExpandableMenuButton
					expandTo={<EraseObject />}
					subMenuPanelProps={{
						title: t("features.eraseObject.title"),
						subTitle: t("features.eraseObject.subTitle"),
					}}
					isActive={activeConfig === t("features.eraseObject.title")}
					hidden={!isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.eraseObject.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.eraseObject.tooltip")}
								href={LinkConstants.BRIA_API_REMOVE_OBJECT}
							/>,
						)}
					/>
				</ExpandableMenuButton>
			</ExpandableMenuLayout>
			{!isSingleMode && (
				<StoppableButton
					condition={
						hasLoadingImages &&
						(!imageToImageStore.isEraseConfigsEnabled || !imageToImageStore.isGeneratingImages)
					}
					onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
					onButtonClick={handleGenerateImageToImage}
					disabled={!isGenerateEnabled()}
					buttonLabel={
						<>
							{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
								? t("tooManySelected")
								: t("button")}
							{imageToImageStore.getGeneratedImagesCount() > 0 &&
								isGenerateEnabled() &&
								` ${imageToImageStore.getGeneratedImagesCount()} ${t("results")}`}
						</>
					}
					stopButtonLabel={
						<>
							<StopIcon /> {t("stopGenerating")}
						</>
					}
					fullWidth
					className={`generate-image-to-image ${styles.stopGeneratingBt}`}
				/>
			)}
		</Box>
	);
});

export default ImageEditingConfigs;

import { MoreHoriz } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { StyledMenuItem } from "../../../../components/common/BriaDropDown/BriaDropDown";
import ConfirmationPopup from "../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import { useAppStore } from "../../../../hooks/useStores";
import { PlaygroundProject } from "../../../Playground/store/playground-projects-store";
import EditProjectDialog from "../../EditProjectDialog/EditProjectDialog";
import styles from "./AutomotiveProjectActionsPopover.module.scss";

type AutomotiveProjectActionsPopoverProps = {
	project: PlaygroundProject;
};

const AutomotiveProjectActionsPopover = ({ project }: AutomotiveProjectActionsPopoverProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.projects.card.actions" });
	const { playgroundStore } = useAppStore();
	const { playgroundProjectsStore } = playgroundStore;
	const [anchorEl, setAnchorEl] = useState<SVGSVGElement>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [errorDelete, setErrorDelete] = useState("");

	const handleOpen = (event: React.MouseEvent<SVGSVGElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const deleteProject = async () => {
		try {
			setErrorDelete("");
			await playgroundProjectsStore.deleteProject(project.id);
			handleClose();
			setOpenDeletePopup(false);
		} catch (err: any) {
			setErrorDelete(err);
		}
	};

	const editProject = () => {
		playgroundProjectsStore.projectForm = project;
		setOpenEditDialog(true);
	};

	const onCloseEditDialog = () => {
		setOpenEditDialog(false);
		handleClose();
	};

	const onCloseDeletePopup = () => {
		setOpenDeletePopup(false);
		handleClose();
	};

	return (
		<>
			<MoreHoriz className={styles.iconButton} onClick={handleOpen} />
			<Popover
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<>
					<StyledMenuItem onClick={editProject}>{t("edit")}</StyledMenuItem>
					<StyledMenuItem onClick={() => setOpenDeletePopup(true)}>{t("delete")}</StyledMenuItem>
				</>
			</Popover>
			<ConfirmationPopup
				open={openDeletePopup}
				onClick={deleteProject}
				loading={playgroundProjectsStore.loadingDeleteProject}
				title={t("deletePopup.title")}
				description={<Trans>{t("deletePopup.description")}</Trans>}
				subDescription={<Trans>{t("deletePopup.subDescription")}</Trans>}
				subTitle={t("deletePopup.subTitle")}
				confirmButtonText={t("deletePopup.confirmBtn")}
				onClose={onCloseDeletePopup}
				errorMessage={errorDelete}
			/>
			<EditProjectDialog open={openEditDialog} onClose={onCloseEditDialog} />
		</>
	);
};

export default observer(AutomotiveProjectActionsPopover);

export const USER_DEFAULT_AVATAR =
	"https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1697705199~exp=1697705799~hmac=0ce79d0ad1618e211eef0e882f46b832d072a6b9aa1158fbe4dfd8e48407af8f";

export enum USER_ROLES {
	ADMIN = "admin",
	VIEWER = "view",
}

export enum USER_ORGANIZATION_ROLES {
	OWNER = "owner",
	ADMIN = "admin",
	ADMIN_HIDDEN = "admin hidden",
	USER = "user",
	USER_HIDDEN = "user hidden",
}

export enum ORGANIZATIONS {
	BRIA = "bria",
}

export enum USER_SETTINGS {
	hidePlatformWelcomePopup = "hidePlatformWelcomePopup",
	hideFreeUserBanner = "hideFreeUserBanner",
	hidePlatformAppsWelcomePopup = "hidePlatformAppsWelcomePopup",
	hidePromotionPopup = "hidePromotionPopup",
	hideCampaignTemplateTourPopup = "hideCampaignTemplateTourPopup",
}

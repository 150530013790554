import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../../../hooks/useStores";
import Icons from "../../../../../components/Icons";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { PanelType } from "../../../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import { useEditor } from "../../../../../hooks/useEditor";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import FabricObjectsProperties from "../../Panels/panelItems/Common/FabricObjectsProperties/FabricObjectsProperties.tsx";
import { ObjectsEnum } from "../../Panels/panelItems/index.ts";
import BrandSettings from "../BrandSettings/BrandSettings.tsx";
import styles from "./Canvas.module.scss";

const Canvas = () => {
	const editor = useEditor();
	const activeObject = useActiveObject() as any;
	const { setActivePanel } = useAppContext();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { campaignStore, uiStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const [_state, setState] = React.useState({ fill: "#000000" });
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });
	const initialFrame = editor?.objects.findOneById(ObjectsEnum.InitialFrame);

	useEffect(() => {
		if (editor) {
			setState({ fill: editor.canvas.backgroundColor as string });
		}
	}, [editor]);

	useEffect(() => {
		const watcher = async () => {
			(campaignStore.canvasColorPicker =
				editor && editor?.frame?.background?.fill && typeof editor.frame.background.fill == "string"
					? editor.frame.background.fill
					: "#fff"),
				setState({ fill: editor?.canvas.backgroundColor as string });
		};

		if (editor) {
			editor.on("canvas:updated", watcher);
		}

		return () => {
			if (editor) {
				editor.off("canvas:updated", watcher);
			}
		};
	}, [editor, activeObject]);

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box
				className={clsx({
					[styles.canvasControlsContainer]: true,
					[styles.noMarginRight]: uiStore.showGuideSection,
				})}
			>
				<Box className={styles.canvasControlsWrapper}>
					<BriaButton
						buttonType="textMedium"
						onClick={() => {
							setIsSidebarOpen(true);
							setActivePanel(PanelType.RESIZE);
						}}
						className={styles.ControlButtonWrapper}
					>
						<Icons.Resize size={24} />
						<Typography className={styles.buttonLabel}>{t("Resize")}</Typography>
					</BriaButton>
					<Divider className={styles.divider} orientation={"vertical"} />
					<Box
						style={{
							backgroundColor: campaignStore.canvasColorPicker,
							background: campaignStore.canvasColorPicker,
							opacity: editor?.frame?.background?.opacity,
						}}
						onClick={() => {
							setIsSidebarOpen(true);
							setActivePanel(PanelType.CANVASCOLOR);
						}}
						className={styles.colorBox}
					></Box>
					<Divider className={styles.divider} orientation={"vertical"} />
					<FabricObjectsProperties />
				</Box>

				<Box>
					<EditAllAdsSwitch />
				</Box>
			</Box>
			<BrandSettings activeObject={initialFrame} showBrandColorDropdown={true} />
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
};

const ObservedComponent = observer(Canvas);
export default ObservedComponent;

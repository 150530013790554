import { Avatar, Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import { useGuideItems } from "../TemplatesTourPagesData";
import useGuideTemplatesUtils from "../useGuideTemplatesUtils";
import styles from "./TemplateCreationWelcomeGuide.module.scss";

const TemplateCreationWelcomeGuide = () => {
	const guideItems = useGuideItems();
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplatesGuideSection.welcomeSection" });
	const { handleOpenGuide } = useGuideTemplatesUtils();

	return (
		<Box className={styles.welcomeGuide}>
			<Typography className={styles.Welcome}>{t("title")}</Typography>
			<Typography className={styles.description}>{t("description")}</Typography>
			<Typography className={styles.extraDescription}>{t("extraDescription")}</Typography>
			<ul className={styles.capabilitiesList}>
				<li>
					<Typography className={styles.point}>{t("template")}</Typography>
				</li>
				<li>
					<Typography className={styles.point}>{t("brandTheme")}</Typography>
				</li>
			</ul>
			<Typography className={styles.description}>{t("buttonDescription")}</Typography>
			<BriaButton
				className={styles.button}
				onClick={() => {
					handleOpenGuide(1);
				}}
				buttonType="primaryMedium"
			>
				{t("buttonTitle")}
			</BriaButton>
			<Divider className={styles.divider} />
			<Typography className={styles.subTitle}>{t("templateCreationGuides")}</Typography>
			<Box>
				{guideItems.map((item, index) => (
					<Box key={index} className={styles.listItem}>
						<Avatar className={styles.avatar}>{item.number}</Avatar>
						<Typography
							onClick={() => {
								handleOpenGuide(index + 1);
							}}
							className={styles.itemsText}
						>
							{item.title}
						</Typography>
					</Box>
				))}
			</Box>
			<Divider className={styles.divider} />
			<Typography className={styles.brandTitle}>{t("brandThemeCreation")}</Typography>
			<Typography className={styles.description}>{t("brandThemeCreationDescription")}</Typography>
			<Typography className={styles.description}>
				{<span className={styles.note}>{t("note")}</span>}
				{<span> {t("brandThemeCreationExtraDescription")}</span>}
			</Typography>
			<BriaButton
				className={styles.button}
				onClick={() => {
					handleOpenGuide(8);
				}}
				buttonType="primaryMedium"
			>
				{t("brandCreationGuideButton")}
			</BriaButton>
		</Box>
	);
};
const ObservedComponent = observer(TemplateCreationWelcomeGuide);
export default ObservedComponent;

import { Box } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip";

import styles from "./InputWithHighlightedPrefix.module.scss";

interface InputWithPrefixProps {
	value: string;
	prefix?: string;
	info?: string;
	className?: string;
	onChange: (text: string) => void;
	onEnterPress?: () => void;
}

const InputWithHighlightedPrefix: React.FC<InputWithPrefixProps & TextFieldProps> = ({
	value = "",
	info = "",
	prefix = "",
	className,
	onChange,
	onEnterPress,
	...rest
}) => {
	const { t } = useTranslation("translation");

	const prefixText = (
		<Box className={styles.highlightedPrefix}>
			<strong>{t("prefix")}</strong>
			{prefix}
		</Box>
	);

	const highlightedPrefix = info ? <BriaTooltip title={info}>{prefixText}</BriaTooltip> : prefixText;

	return (
		<BriaInput
			disabled={false}
			onChange={(e) => onChange(e.target.value)}
			className={clsx(styles.briaInput)}
			rootClass={clsx(styles.root, className)}
			onEnterPress={onEnterPress}
			customStartAdornment={highlightedPrefix}
			minRows={rest.minRows}
			maxRows={rest.maxRows}
			multiline={rest.multiline}
		/>
	);
};

export default InputWithHighlightedPrefix;

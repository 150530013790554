import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useAppStore } from "../../../../../hooks/useStores";
import GuideCardComponent from "./GuideCardComponent/GuideCardComponent";
import GuideContainer from "./GuideContainer/GuideContainer";
import TemplateCreationWelcomeGuide from "./TemplateCreationWelcomeGuide/TemplateCreationWelcomeGuide";
import styles from "./TemplatesCreationGuideSection.tsx.module.scss";
import { useGuideItems } from "./TemplatesTourPagesData";
import useGuideTemplatesUtils from "./useGuideTemplatesUtils";

const TemplatesCreationGuideSection = () => {
	const { uiStore } = useAppStore();
	const guideItems = useGuideItems();
	const { handleBack, handleForward } = useGuideTemplatesUtils();

	const onClose = () => {
		uiStore.setProperty("showGuideSection", false);
		uiStore.setProperty("showGuideToolTip", false);
	};

	useEffect(() => {
		const guideSectionContainerRef = uiStore.guideSectionContainerRef;
		if (guideSectionContainerRef.current) {
			guideSectionContainerRef.current.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [uiStore.selectedGuideIndex]);

	return (
		<>
			{uiStore.showGuideSection && (
				<GuideContainer handleBack={handleBack} handleForward={handleForward} onClose={onClose}>
					{uiStore.selectedGuideIndex !== 0 ? (
						<Box className={styles.guideItemContainter}>
							{guideItems[uiStore.selectedGuideIndex - 1].cards?.map((card, index) => (
								<GuideCardComponent
									key={index}
									card={card}
									lastCard={index === guideItems[uiStore.selectedGuideIndex - 1].cards.length - 1}
									firstCard={index === 0}
								/>
							))}
						</Box>
					) : (
						<TemplateCreationWelcomeGuide />
					)}
				</GuideContainer>
			)}
		</>
	);
};
const ObservedComponent = observer(TemplatesCreationGuideSection);
export default ObservedComponent;

import { ChangeEvent, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAppStore } from "../../hooks/useStores.tsx";
import useDesignEditorUtils from "../CustomUtils/UseDesignEditor.tsx";
import useTemplateUtils from "../CustomUtils/UseTemplateUtils.tsx";
import { DesignEditorContext } from "../contexts/DesignEditor.tsx";
import { BrandDefinition } from "../models/brandDefinition.ts";
import { Image } from "../models/image.ts";
import { ILayer, IScene } from "../types";
import {
	CampaignApiMainObject,
	CampaignApiParameters,
	CampaignCustomOptions,
	CampaignResponseMessages,
	LayerImage,
	LayerText,
	SmartImageBackgroundOperation,
} from "../types/campaign.ts";
import {
	BackgroundOptionsEnum,
	BackgroundRoute,
	ISmartImageParams,
	ObjectsEnum,
} from "../views/DesignEditor/components/Panels/panelItems";
import { useSmartImageGeneration, useSmartImageUtils } from "../views/DesignEditor/utils/smartImageUtils.ts";
import { useEditor } from "./useEditor.tsx";

const useCampaignAPI = () => {
	const editor = useEditor();
	const { imagesStore } = useAppStore();
	const { loadTemplate } = useTemplateUtils();
	const {
		uploadAndRegisterBackgroundImages,
		updateLayerStaticContent,
		getSmartImageSize,
		findObjectFromScene,
		drawSmartImage,
		resetRectangleInAGroup
	} = useSmartImageUtils();
	const { generateCampaignSmartImages } = useSmartImageGeneration();
	const { applyBrandConfigrationOnScene } = useDesignEditorUtils();
	const { setScenes, setCurrentScene } = useContext(DesignEditorContext);
	const { designEditorStore, brandsDefinitionStore, campaignStore } = useAppStore();
	const pushResponseMessage = (status: string, message: string, template?: any) => {
		const response: any = {
			type: "campaign_response",
			status: status,
			campaign: {},
		};

		if (status === "failed") {
			response.campaign.message = message;
		} else {
			response.campaign.template = template;
		}

		window.messages.push(response);
	};

	const updateEditorStep = async (step: number, delay: number = 1000) => {
		await new Promise((resolve) => setTimeout(resolve, delay));
		designEditorStore.setProperty("newStep", step);
	};

	const isValidHexColor = (value: string) => {
		if (value === "") return true;
		const hexColorRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;
		return hexColorRegex.test(value);
	};

	const setCampaignApiConfigs = async (campaign: CampaignApiParameters): Promise<IScene | undefined> => {
		designEditorStore.setProperty("isTemplateEmptyState", false);
		designEditorStore.setProperty("isLoadingTemplate", false);
		if (
			campaign.mainObject.operation === SmartImageBackgroundOperation.Generate &&
			campaign.mainObject.backgroundPrompt
		) {
			imagesStore.handleSmartImageChange("backgroundOption", BackgroundOptionsEnum.REPLACE_BACKGROUND);
			if (isValidHexColor(campaign.mainObject.backgroundPrompt)) {
				imagesStore.handleSmartImageChange("colorCode", campaign.mainObject.backgroundPrompt as string);
			} else {
				imagesStore.handleSmartImageChange("backgroundDescription", campaign.mainObject.backgroundPrompt);
			}
		} else {
			imagesStore.handleSmartImageChange("backgroundOption", BackgroundOptionsEnum.EXPAND_BACKGROUND);
		}
		await updateEditorStep(1, 4000);
		return editor?.scene?.exportToJSON();
	};

	const drawOriginalAndSmartImages = async (image: Image, externalScene?: IScene) => {
		try {
			if (editor) {
				const _currentScene = externalScene ?? editor.scene.exportToJSON();
				const originalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);

				if (
					!originalImage ||
					image.input_params?.originalImage.imageUrl !== imagesStore.smartImageForm.originalImage.imageUrl
				) {
					await uploadAndRegisterBackgroundImages(
						{} as ChangeEvent<HTMLInputElement>,
						image.input_params?.originalImage.imageUrl,
						_currentScene,
					);

					// Setting these states to reflects the smartImage selections
					imagesStore.handleSmartImageChange(
						"backgroundOption",
						(image.input_params as ISmartImageParams).backgroundOption,
					);
					imagesStore.handleSmartImageChange(
						"backgroundDescription",
						(image.input_params as ISmartImageParams).backgroundDescription,
					);
					imagesStore.handleSmartImageChange(
						"colorCode",
						(image.input_params as ISmartImageParams).colorCode,
					);
					imagesStore.handleSmartImageChange(
						"originalImage",
						(image.input_params as ISmartImageParams).originalImage,
					);
				}
				await drawSmartImage(image, _currentScene);
				if (image?.input_params?.backgroundOption === BackgroundOptionsEnum.EXPAND_BACKGROUND) {
					imagesStore.handleSmartImageChange("backgroundDescription", "");
				}
				designEditorStore.setProperty("isSceneFullyLoaded", false);
			}
		} catch (error) {
			console.error("Error processing smart image:", error);
		}
	};

	const applyBrandOnScene = async (currentScene: IScene, brand: BrandDefinition) => {
		if (editor && brand) {
			brandsDefinitionStore.setProperty("selectedBrand", brand);
			brandsDefinitionStore.setProperty("isApplyingBrandConfigurations", true);
			await applyBrandConfigrationOnScene(brand, currentScene, 0);
			brandsDefinitionStore.setProperty("isApplyingBrandConfigurations", false);
			setCurrentScene(currentScene);
			campaignStore.isFirstTimeBrandApplied = false;
		}
	};

	const processSmartImageBackground = async (currentScene: IScene, mainObject: CampaignApiMainObject) => {
		await updateEditorStep(2);
		if (mainObject.skip.toLowerCase() === "false") {
			await uploadAndRegisterBackgroundImages({} as ChangeEvent<HTMLInputElement>, mainObject.url, currentScene);
			if (mainObject.operation === SmartImageBackgroundOperation.Expand) {
				const smartImageSize = await getSmartImageSize(currentScene);
				if (smartImageSize) {
					await imagesStore.generateOrExpandImageBackground(BackgroundRoute.Expand, smartImageSize);
					await drawOriginalAndSmartImages(imagesStore.smartImagesHistory[0], currentScene);
				}
			} else if (mainObject.backgroundPrompt && mainObject.operation === SmartImageBackgroundOperation.Generate) {
				const smartImageSize = await getSmartImageSize(currentScene);
				const localSmartImageForm: ISmartImageParams = {
					...imagesStore.smartImageForm,
					colorCode: isValidHexColor(mainObject.backgroundPrompt) ? mainObject.backgroundPrompt : "",
					backgroundDescription: !isValidHexColor(mainObject.backgroundPrompt)
						? mainObject.backgroundPrompt
						: "",
				};
				imagesStore.setProperty("smartImageForm", localSmartImageForm);
				imagesStore.handleSmartImageChange("backgroundOption", BackgroundOptionsEnum.REPLACE_BACKGROUND);
				if (smartImageSize) {
					try {
						await imagesStore.generateOrExpandImageBackground(
							BackgroundRoute.Replace,
							smartImageSize,
							localSmartImageForm,
						);
					} catch (e) {
						if (e === "Error expanding/generating background: Request failed with status code 400") {
							pushResponseMessage("failed", "Offensive content detected in the background prompt.");
						} else {
							throw (e)
						}
					}
					await drawOriginalAndSmartImages(imagesStore.smartImagesHistory[0], currentScene);
				}
			}
		}
		// TODO: The delay is not the best solution here, I will try to remove/minimize it after the int deployment
		await updateEditorStep(3, 6000);
	};

	const handleStaticContentChanges = async (
		l_scenes: IScene[],
		currentScene: IScene,
		backgroundColor: string,
		texts: LayerText[],
		images: LayerImage[],
	) => {
		if (editor) {
			const updatedLayers = await Promise.all(
				currentScene.layers.map(async (layer) => {
					const updatedLayer = await updateLayerStaticContent(layer, {
						backgroundColor,
						texts,
						images,
					} as CampaignCustomOptions);

					return updatedLayer ? updatedLayer : null;
				}),
			);
			currentScene.layers = updatedLayers.filter((layer) => layer !== null) as Partial<ILayer>[];

			const updatedPreview = (await editor?.renderer.render(currentScene)) as string;
			const updatedScenes = l_scenes.map((scene: IScene) => {
				if (scene.id === currentScene.id) {
					return { ...currentScene, preview: updatedPreview };
				}
				return editor.scene.formalizeSceneAttributes(scene);
			}) as IScene[];
			setScenes(updatedScenes);
			setCurrentScene(updatedScenes[0]);
			return updatedScenes;
		}
		return l_scenes;
	};

	const generateCampaign = async (l_scenes: IScene[], options: CampaignCustomOptions) => {
		designEditorStore.setProperty("campaignName", uuidv4());
		designEditorStore.setProperty("isGenerateAdsLoading", true);
		let updatedScenes = await generateCampaignSmartImages(l_scenes, options);

		if (!updatedScenes || updatedScenes.length < 0) {
			return;
		}
		const finalScenes = await Promise.all(
			updatedScenes?.map(async (scene: IScene) => {
				const updatedPreview = (await editor?.renderer.render(scene)) as string;
				return { ...scene, preview: updatedPreview };
			}),
		);

		setScenes(finalScenes);
		setCurrentScene(finalScenes[0]);

		pushResponseMessage("success", "", finalScenes);
		designEditorStore.setProperty("hideCampaignResultContent", false);
	};

	const handleCampaignApiRequest = async (campaign: CampaignApiParameters): Promise<void> => {
		try {
			await loadTemplate(campaign.template.ads_json);
			const currentScene = await setCampaignApiConfigs(campaign);
			if (!currentScene) {
				throw new Error("Current scene export failed.");
			}

			await applyBrandOnScene(currentScene, campaign.brand);
			await processSmartImageBackground(currentScene, campaign.mainObject);
			const skip = campaign.mainObject.skip.toLowerCase() === "true"
			if (skip) {
				await resetRectangleInAGroup(currentScene, ObjectsEnum.OuterRectangle);
				await resetRectangleInAGroup(currentScene, ObjectsEnum.InnerRectangle);
			}
			const updatedScenes = await handleStaticContentChanges(
				campaign.template.ads_json.scenes,
				currentScene,
				campaign.backgroundColor,
				campaign.texts,
				campaign.images,
			);
			await updateEditorStep(6, 1500);
			await generateCampaign(updatedScenes, {
				backgroundColor: campaign.backgroundColor,
				texts: campaign.texts,
				images: campaign.images,
				skip: skip,
			});
		} catch (error) {
			console.error(CampaignResponseMessages.SomethingWentWrong, error);
			pushResponseMessage("failed", CampaignResponseMessages.SomethingWentWrong);
			designEditorStore.setProperty("isLoadingTemplate", false);
		}
	};

	return {
		pushResponseMessage,
		handleCampaignApiRequest,
	};
};

export default useCampaignAPI;

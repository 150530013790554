import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingPlaceholder from "../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { firebaseAuth } from "../../../config/firebase.ts";
import RouterConstants from "../../../constants/RouterConstants";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import styles from "./AuthPage.module.scss";

const AuthPage = () => {
	const { uiStore } = useAppStore();
	const { verifyUser } = useAuthService();
	const navigate = useSecureNavigate();
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	let continueUrl = queryParams.get("continueUrl");
	const mode = queryParams.get("mode");
	const oobCode = queryParams.get("oobCode");

	const navigateToTarget = () => {
		if (continueUrl) {
			const url = new URL(continueUrl);
			const prompt = url.searchParams.get("prompt");
			if (!location.state) {
				location.state = {};
			}
			if (prompt) {
				location.state.prompt = decodeURIComponent(prompt);
			}
			continueUrl = new URL(continueUrl).pathname + `?${url.searchParams.toString()}`;
			navigate(continueUrl, { state: location.state, replace: true });
		} else {
			navigate(RouterConstants.CONSOLE.path, { state: location.state, replace: true });
		}
	};

	useEffect(() => {
		if (oobCode && mode === "verifyEmail") {
			setLoading(true);
			verifyUser(oobCode)
				.then(() => {
					uiStore.showSnackBarAlert("success", "Email verification succeeded", 5000);
					firebaseAuth.currentUser?.reload().finally(() => {
						navigateToTarget();
					});
				})
				.catch((e) => {
					console.log("error", e);
					uiStore.showSnackBarAlert("error", "Email verification failed", 5000);
					navigateToTarget();
				});
		}
	}, []);

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={loading}>
			{oobCode && mode === "resetPassword" ? (
				<Navigate
					to={RouterConstants.PASSWORD_RESET.path}
					state={{ ...location.state, oobCode, mode, ...(continueUrl && { continueUrl }) }}
					replace={true}
				/>
			) : (
				<Navigate to={RouterConstants.CONSOLE.path} replace={true} />
			)}
		</LoadingPlaceholder>
	);
};

export default observer(AuthPage);

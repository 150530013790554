import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveContainer } from "recharts";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaDropDown, { DropDownItem } from "../../../components/common/BriaDropDown/BriaDropDown.tsx";
import CustomDateRangePicker from "../../../components/common/DateRangePicker/DateRangePicker.tsx";
import CustomLineChart from "../../../components/common/LineChart/LineChart.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout.tsx";
import {
	ITableUsageRecord,
	IUsageData,
	LineChartColors,
	LineChartDefaultValues,
	UsagePeriod,
	UsageType,
} from "../../../models/usage.ts";
import { capitalizeFirstLetter, convertDateToUtc, getTodayDateRange } from "../../../utils";
import classes from "../../auth/ForgotPassword/ForgotPassword.module.scss";
import styles from "./UsageAndBilling.module.scss";
import {
	DROPDOWN_HEIGHT,
	DROPDOWN_MAX_HEIGHT,
	DROPDOWN_WIDTH,
	ROUTES_MAPPER,
	computeTableData,
	convertUsageData,
	createModelLookup,
	defaultApiRoutesDropDownItems,
	getApiRoutesAndTailoredModels,
	handleDownloadAsCsvClick,
	handleHoverRange,
	handleSelectApiRoutes,
	updateApiRoutesItems,
	usagePeriodDropDownItems,
	usageTypeDropDownItems, updateTGModelsItems, TG_MODEL_DROPDOWN_WIDTH,
} from "./UsageAndBillingUtils.tsx";

const UsageAndBilling = () => {
	const { accountStore, appStore, tgStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "account.usage" });
	const [apiKeysOptions, setApiKeysOptions] = useState<DropDownItem[]>([]);
	const [selectedApiRoute, setSelectedApiRoute] = useState<string[]>([]);
	const [selectedUsageType, setSelectedUsageType] = useState<string>(UsageType.Total);
	const [selectedApiKey, setSelectedApiKey] = useState<string | number>("");
	const [selectedUsagePeriod, setSelectedUsagePeriod] = useState<UsagePeriod>(UsagePeriod.Daily);
	const [tableData, setTableData] = useState<TableRow<ITableUsageRecord>[]>([]);
	const [tableHeader, setTableHeader] = useState<string[]>([]);
	const [selectedDateRange, setSelectedRangeDate] = useState<Date[]>([]);
	const [apiRoutesDropDownItems, setApiRoutesDropDownItems] = useState<DropDownItem[]>(defaultApiRoutesDropDownItems);
	const [filteredApiRoutesDropDownItems, setFilteredApiRoutesDropDownItems] =
		useState<DropDownItem[]>(defaultApiRoutesDropDownItems);
	const [dateRange, setDateRange] = useState<[Date, Date]>(getTodayDateRange());
	const [usageData, setUsageData] = useState<IUsageData[]>([]);
	const [activeApiRoutes, setActiveApiRoutes] = useState<string[]>([]);
	const [selectedTailoredModels, setSelectedTailoredModels] = useState<number[]>([]);
	const [tailoredModelsDropDownItems, setTailoredModelsDropDownItems] = useState<DropDownItem[]>([]);
	const [activeTailoredModels, setActiveTailoredModels] = useState<string[]>([]);

	useEffect(() => {
		const loadData = async () => {
			if (!appStore.isError && appStore.apiKeys.length === 0) {
				await appStore.loadApiKeys();
			}

			let apiKeysDropDownItems: DropDownItem[] = Object.entries(appStore.apiKeys).map(([_key, apiKey]) => ({
				key: `****${apiKey.api_token.substring(28)} ${
					apiKey.key_type === "production" ? t("productionKey") : t("stagingKey")
				}`,
				value: apiKey.api_token,
			}));

			apiKeysDropDownItems = [
				{
					key: t("allApiKeys"),
					value: t("all"),
				},
				...apiKeysDropDownItems,
			];

			const range = getTodayDateRange();
			setDateRange(range);
			setSelectedRangeDate(range);
			setApiKeysOptions(apiKeysDropDownItems);
			setSelectedApiKey(apiKeysDropDownItems[0].value);

			if (
				!tgStore.tgModelStore.modelsListError &&
				tgStore.tgModelStore.modelsList.length === 0 &&
				!tgStore.tgModelStore.loadingModelsList
			) {
				await tgStore.tgModelStore.listAllModels();
			}
			updateApiRoutesItems({
				t,
				defaultApiRoutesDropDownItems,
				setSelectedApiRoute,
				setApiRoutesDropDownItems,
				setFilteredApiRoutesDropDownItems,
			});
			updateTGModelsItems({
				tgModelStore: tgStore.tgModelStore,
				setTailoredModelsDropDownItems,
			})
		};
		loadData();
	}, []);

	useEffect(() => {
		const { data, header } = computeTableData(accountStore, tgStore.tgModelStore, t);

		setTableData(data);
		setTableHeader(header);
	}, [accountStore.tabularUsageData.items, accountStore.usageData, tgStore.tgModelStore.modelsList]);

	useEffect(() => {
		const { apiRoutes, tailoredModelsIds } = getApiRoutesAndTailoredModels(selectedApiRoute, selectedTailoredModels);

		if (selectedDateRange.length > 0 && (apiRoutes.length > 0 || tailoredModelsIds.length)) {
			handleLoadUsageData(apiRoutes, tailoredModelsIds);

			accountStore.loadUsageSummary(
				convertDateToUtc(selectedDateRange[0]).toISOString(),
				convertDateToUtc(selectedDateRange[1]).toISOString(),
				selectedApiKey.toString(),
				selectedUsageType,
				apiRoutes,
				tailoredModelsIds,
			);
		}
	}, [selectedApiKey, selectedDateRange, selectedApiRoute, selectedUsageType, selectedTailoredModels]);

	useEffect(() => {
		const loadAllUsageData = async () => {
			if (selectedDateRange.length > 0) {
				const { apiRoutes, tailoredModelsIds } = getApiRoutesAndTailoredModels(
					apiRoutesDropDownItems.map((item) => item.key),
					tgStore.tgModelStore.modelsList.map((model) => model.id),
				);

				await accountStore.loadAllUsageData(
					convertDateToUtc(selectedDateRange[0]).toISOString(),
					convertDateToUtc(selectedDateRange[1]).toISOString(),
					selectedApiKey.toString(),
					apiRoutes,
					selectedUsageType,
					tailoredModelsIds,
				);

				const allUsageData = accountStore.allUsageData;
				const allRoutesInDate: string[] = [];
				const allTGModelsInDate: string[] = [];
				Object.keys(allUsageData)
					.filter(([key, value]) => key !== "name" || parseInt(value) !== 0)
					.forEach((key) => {
						let routeName: string | undefined = ROUTES_MAPPER[key as keyof typeof ROUTES_MAPPER];

						if (routeName) {
							allRoutesInDate.push(routeName);
						} else {
							let modelName = tgStore.tgModelStore.modelsList.find(
								(model: any) => model.id?.toString() === key,
							)?.name;
							if (modelName) {
								allTGModelsInDate.push(modelName);
							}
						}
					});
				setActiveApiRoutes(allRoutesInDate);
				setActiveTailoredModels(allTGModelsInDate);
			}
		};
		loadAllUsageData();
	}, [
		selectedDateRange,
		selectedUsageType,
		selectedApiKey,
		selectedTailoredModels,
		tgStore.tgModelStore,
		apiRoutesDropDownItems,
		accountStore,
	]);

	useEffect(() => {
		if (selectedApiRoute.length === 0 && selectedDateRange.length > 0 && selectedApiKey) {
			accountStore.clearStoreData();
			setUsageData([]);
			setTableData([]);
			setTableHeader([]);
		}
	}, [selectedApiRoute]);

	const handleLoadUsageData = async (apiRoutes: string[] = [], tailoredModelsIds: number[] = []) => {
		await accountStore.loadUsageData(
			convertDateToUtc(selectedDateRange[0]).toISOString(),
			convertDateToUtc(selectedDateRange[1]).toISOString(),
			selectedApiKey.toString(),
			apiRoutes,
			selectedUsageType,
			tailoredModelsIds,
		);

		// Create a model lookup map to optimize searching
		const modelLookup = createModelLookup({ models: tgStore.tgModelStore.modelsList });

		// Convert usage data
		const computedUsageData: IUsageData[] = convertUsageData({ usageData: accountStore.usageData, modelLookup });

		setUsageData(computedUsageData);
	};

	const handleSelectDateRange = (range: Date[]) => {
		setSelectedRangeDate(range);
		if (range && range.length === 2 && range[0] instanceof Date && range[1] instanceof Date) {
			setDateRange([range[0], range[1]]);
		}
	};

	const onApiRoutesSearchChange = (value: string) => {
		if (value && value.length > 0) {
			const filteredApiRoutes = apiRoutesDropDownItems.filter((item) =>
				item.value.toLowerCase().includes(value.toLowerCase()),
			);
			setFilteredApiRoutesDropDownItems(filteredApiRoutes);
		} else {
			setFilteredApiRoutesDropDownItems(apiRoutesDropDownItems);
		}
	};

	const handleTGModelsSearchChange = (value: string) => {
		if (value && value.length > 0) {
			const filteredModels = tgStore.tgModelStore.modelsList.filter((model) =>
				model.name.toLowerCase().includes(value.toLowerCase()),
			);
			setTailoredModelsDropDownItems(
				filteredModels.map((model) => ({
					key: model.name,
					value: model.id,
				})),
			);
		} else {
			setTailoredModelsDropDownItems(
				tgStore.tgModelStore.modelsList.map((model) => ({
					key: model.name,
					value: model.id,
				})),
			);
		}
	}

	const handleTGModelClose = () => {
		setTailoredModelsDropDownItems(
			tgStore.tgModelStore.modelsList.map((model) => ({
				key: model.name,
				value: model.id,
			})),
		);
	}

	const handleAPIRouteSelectionChange = (value: string[]) => {
		handleSelectApiRoutes({
			value,
			t,
			filteredApiRoutesDropDownItems,
			selectedApiRoute,
			setSelectedApiRoute,
			activeApiRoutes,
		});
	};

	const isLoadingData = () => {
		return (
			accountStore.isLoading ||
			accountStore.isLoadingSummaryReport ||
			tgStore.tgModelStore.loadingModelsList ||
			appStore.isLoading ||
			accountStore.isLoadingAllUsageData
		);
	};

	const handleResetToDefault = () => {
		setSelectedUsageType(UsageType.Total);
		setSelectedApiKey(t("all"));
		setSelectedTailoredModels([])
		handleSelectDateRange(getTodayDateRange());
		updateApiRoutesItems({
			t,
			defaultApiRoutesDropDownItems,
			setSelectedApiRoute,
			setApiRoutesDropDownItems,
			setFilteredApiRoutesDropDownItems,
		});
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.headerContainer}>
				<Typography className={styles.title}>{t("title")}</Typography>
			</Box>
			<Box className={styles.summary}>
				<Box className={styles.column}>
					<Typography
						className={clsx({
							[styles.value]: true,
							[styles.firstColumn]: true,
						})}
					>
						<Typography className={styles.title}>{t("totalCalls")}</Typography>

						<Box className={styles.totalUsageWrapper}>
							<Typography className={styles.totalUsage}>
								{accountStore.usageSummary.totalUsage?.toLocaleString()}
							</Typography>{" "}
							{isLoadingData() && (
								<Box className={classes.circleLoader}>
									<CircularProgress color="inherit" size={16} />
								</Box>
							)}
						</Box>
					</Typography>

					<Typography className={clsx(styles.value, styles.hide)}>
						<Typography className={styles.title}>{t("averageMonthlyCalls")}</Typography>
						{isLoadingData() ? (
							<Box className={classes.circleLoader}>
								<CircularProgress color="inherit" size={20} />
							</Box>
						) : (
							<>{accountStore.usageSummary.monthlyUsage?.toLocaleString()}</>
						)}
					</Typography>
				</Box>
				<Box className={styles.headerActions}>
					<BriaButton
						onClick={handleResetToDefault}
						className={styles.button}
						buttonType="textMedium"
						disabled={isLoadingData()}
					>
						{t("resetToDefault")}
					</BriaButton>
					<BriaButton
						onClick={() =>
							handleDownloadAsCsvClick({ accountStore, tgModelStore: tgStore.tgModelStore, t })
						}
						className={styles.button}
						buttonType="secondaryMedium"
						disabled={isLoadingData()}
					>
						{t("downloadAsCSV")}
					</BriaButton>
				</Box>
			</Box>

			<Box className={styles.filtersContainer}>
				<Box className={styles.filters}>
					<Box className={styles.dropdowns}>
						<BriaDropDown
							value={selectedApiKey}
							onChange={(e) => setSelectedApiKey(e.target.value as string)}
							items={apiKeysOptions}
							height={DROPDOWN_HEIGHT}
							width={DROPDOWN_WIDTH}
							disabled={isLoadingData()}
							renderValue={(selected) => {
								let valueToRender = selected;
								for (const item of apiKeysOptions) {
									if (item.value === selected) {
										valueToRender = item.key;
									}
								}
								return capitalizeFirstLetter(valueToRender as string);
							}}
						/>

						<BriaDropDown
							value={selectedUsageType}
							onChange={(e) => setSelectedUsageType(e.target.value as UsagePeriod)}
							items={usageTypeDropDownItems}
							height={DROPDOWN_HEIGHT}
							disabled={isLoadingData()}
							renderValue={(selected) => {
								return capitalizeFirstLetter(selected);
							}}
						/>
						<BriaDropDown
							value={selectedApiRoute}
							items={filteredApiRoutesDropDownItems}
							height={DROPDOWN_HEIGHT}
							maxHeight={DROPDOWN_HEIGHT}
							multiple={true}
							width={DROPDOWN_WIDTH}
							menuMaxHeight={DROPDOWN_MAX_HEIGHT}
							placeholder={t("placeholder")}
							SearchPlaceholder={t("placeholder")}
							loading={tgStore.tgModelStore.loadingModelsList}
							disabled={tgStore.tgModelStore.loadingModelsList || accountStore.isLoadingAllUsageData}
							selectMultipleCheckbox={true}
							onChange={(e) => handleAPIRouteSelectionChange(e.target.value)}
							onClose={() => setFilteredApiRoutesDropDownItems(apiRoutesDropDownItems)}
							renderValue={(selected) => {
								if (selected.length === 0) {
									return <>{t("placeholder")}</>;
								}
								return `${selected.length} ${t("apiRoutesSelected")}`;
							}}
							searchable={true}
							onSearchChange={(e) => onApiRoutesSearchChange(e.target.value)}
							searchIcon={true}
							activeItems={activeApiRoutes}
						/>
						<BriaDropDown
							value={selectedUsagePeriod}
							onChange={(e) => setSelectedUsagePeriod(e.target.value as UsagePeriod)}
							items={usagePeriodDropDownItems}
							height={DROPDOWN_HEIGHT}
							disabled={isLoadingData()}
							className={styles.hide}
						/>
						<BriaDropDown
							value={selectedTailoredModels}
							items={tailoredModelsDropDownItems}
							activeItems={activeTailoredModels}
							loading={tgStore.tgModelStore.loadingModelsList}
							disabled={tgStore.tgModelStore.loadingModelsList || accountStore.isLoadingAllUsageData}
							searchable={true}
							searchIcon={true}
							selectMultipleCheckbox={true}
							multiple={true}
							height={DROPDOWN_HEIGHT}
							maxHeight={DROPDOWN_HEIGHT}
							menuMaxHeight={DROPDOWN_MAX_HEIGHT}
							width={TG_MODEL_DROPDOWN_WIDTH}
							placeholder={t("tailoredGenerationModels")}
							SearchPlaceholder={t("tailoredGenerationModels")}
							onSearchChange={e => handleTGModelsSearchChange(e.target.value)}
							onChange={(e) => setSelectedTailoredModels(e.target.value)}
							onClose={() => handleTGModelClose()}
							renderValue={(selected) => {
								if (selected.length) {
									return `${selected.length} ${t("tailoredGenerationModelsSelected")}`;
								}
								return <>{t("tailoredGenerationModels")}</>;
							}}
						/>
					</Box>
					<Box className={styles.dateRangePicker}>
						<CustomDateRangePicker
							loading={isLoadingData()}
							placement="autoVerticalEnd"
							value={dateRange}
							hoverRange={
								selectedUsagePeriod === UsagePeriod.Daily ? handleHoverRange : selectedUsagePeriod
							}
							onChange={handleSelectDateRange}
							cleanable={false}
						/>
					</Box>
				</Box>
			</Box>
			<Box className={styles.chartArea}>
				<ResponsiveContainer>
					<CustomLineChart
						data={usageData.length === 0 ? LineChartDefaultValues : usageData}
						total={usageData.length}
						colors={LineChartColors}
					/>
				</ResponsiveContainer>
			</Box>
			{accountStore.usageData.length != 0 && (
				<Box className={styles.tableArea}>
					<TableLayout
						hideSecondaryButton={true}
						headerArray={tableHeader}
						tableData={tableData}
						enableSearch={false}
						loading={isLoadingData()}
					/>
				</Box>
			)}
		</Box>
	);
};

const ObservedComponent = observer(UsageAndBilling);
export default ObservedComponent;

import { Box, Popover, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../hooks/useEditor";
import { ILayer } from "../../../../../types";
import { ObjectsEnum } from "../../Panels/panelItems";
import styles from "./FabricObjectsPropertiesPopover.module.scss";
type Props = {
	open: boolean;
	handleClose?: () => void;
	anchorEl?: HTMLButtonElement;
};

const FabricObjectsPropertiesPopover = ({ open = false, handleClose, anchorEl }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });
	const activeObject = useActiveObject() as Required<fabric.Object>;
	const editor = useEditor();
	const [currentLayer, setCurrentLayer] = useState<Partial<ILayer> | undefined>(undefined);

	useEffect(() => {
		let watcher = async () => {
			updateCurrentLayerState();
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	useEffect(() => {
		updateCurrentLayerState();
	}, [editor, activeObject]);

	const updateCurrentLayerState = () => {
		if (editor && activeObject) {
			const currentScene = editor.scene.exportToJSON();
			let layer = currentScene?.layers.find((layer) => layer.id === activeObject.id);
			if (!layer && activeObject.id === ObjectsEnum.FRAME) {
				layer = currentScene?.layers.find((layer) => layer.id === ObjectsEnum.InitialFrame);
			}
			setCurrentLayer(layer);
		}
	};

	return (
		<Popover
			disableRestoreFocus
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<Box className={styles.propertiesContainer}>
				<Box>
					<Typography className={styles.proportiesTitle}>{t("proporties")}</Typography>
					<InputLayout showLabel={false} className={styles.inputLayout}>
						<Box className={styles.properties}>
							<Box className={styles.inputLabelContainer}>
								<Typography>{t("xLabel")}</Typography>
								<BriaInput
									disabled
									rootClass={styles.rootClass}
									value={currentLayer?.left && Math.round(currentLayer.left)}
									onChange={() => {}}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
							<Box className={styles.inputLabelContainer}>
								<Typography>{t("yLabel")}</Typography>
								<BriaInput
									disabled
									rootClass={styles.rootClass}
									value={currentLayer?.top && Math.round(currentLayer.top)}
									onChange={() => {}}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
							<Box className={styles.widthInputLabelContainer}>
								<Typography>{t("widthLabel")}</Typography>
								<BriaInput
									disabled
									rootClass={styles.rootClass}
									value={currentLayer?.width && Math.round(currentLayer.width)}
									onChange={() => {}}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
							<Box className={styles.inputLabelContainer}>
								<Typography>{t("heightLabel")}</Typography>
								<BriaInput
									disabled
									rootClass={styles.rootClass}
									value={currentLayer?.height && Math.round(currentLayer.height)}
									onChange={() => {}}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
						</Box>
					</InputLayout>
				</Box>
			</Box>
		</Popover>
	);
};

const ObservedComponent = observer(FabricObjectsPropertiesPopover);
export default ObservedComponent;

import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { fabric } from "fabric";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { EditIcon2 } from "../../../../../assets/images/icons/EditIcon2.tsx";
import BriaDropDown from "../../../../../components/common/BriaDropDown/BriaDropDown";
import { useAppStore } from "../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../CustomUtils/UseDesignEditor";
import Icons from "../../../../components/Icons";
import WizardImageHeader from "../../../../components/common/WizardImageHeader/WizardImageHeader.tsx";
import { PanelType } from "../../../../constants/app-options";
import { PopUpSteps } from "../../../../constants/mock-data.ts";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import useAppContext from "../../../../hooks/useAppContext";
import { useContextMenuRequest } from "../../../../hooks/useContextMenuRequest";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../hooks/useEditor";
import useSmartImageHeader from "../../../../hooks/useSmartImageHeader.tsx";
import { LayerType } from "../../../../types";
import useSceneOperations from "../Footer/Graphic/Scenes/utils/SceneOperations";
import { ObjectsEnum } from "../Panels/panelItems";
import styles from "./ContextMenu.module.scss";
import ContextMenuItem from "./ContextMenuItem";
import { ContextMenuData, ICON_SIZE, OBJECT_TYPES } from "./ContextMenusData";

type Position = { left: number; top: number };

interface PanelConfig {
	panels: PanelType[];
	targetPanel: PanelType;
}

function ContextMenu() {
	const [originalTop, setOriginalTop] = useState<string>("30px");
	const [subMenuAlign, setSubMenuAlign] = useState<string>("left");
	const { setContextMenuTimelineRequest, contextMenuTimelineRequest } = useDesignEditorContext();
	const editor = useEditor();
	const { campaignStore, designEditorStore } = useAppStore();
	const contextMenuRequest = useContextMenuRequest();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.dropDownActions" });
	const ContextData = ContextMenuData(t, editor, campaignStore.isAdminMode, campaignStore.isEditingAllAds);
	const [activeObject, setActiveObject] = useState(editor?.canvas?.canvas.getActiveObject()) as any;
	const [contextMenuPosition, setContextMenuPosition] = useState<Position>({
		left: activeObject?.left ?? 0,
		top: activeObject?.top ?? 0,
	});
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [isContextMoving, setIsContextMoving] = useState<boolean>(false);
	const [componentStatus, setComponentStatus] = useState("");
	const [items, setItems] = useState<any>([]);
	const { EditAllAds, fitTextIntoFixedTextBox } = useDesignEditorUtils();
	const { activePanel, setActivePanel } = useAppContext();
	const { deleteScene, duplicateScene } = useSceneOperations();

	const { handleDeleteSmartImage } = useSmartImageHeader();
	const WIZARD_IMAGE_INDEX = PopUpSteps.indexOf("WizardImages");

	useEffect(() => {
		handleUpdateActiveObjectAndPosition();
		setContextMenuOpen(false);
		const canvas = editor?.canvas?.canvas;
		if (activeObject) {
			const isStaticText =
				activeObject?.name === OBJECT_TYPES.STATIC_TEXT ||
				activeObject?.type === OBJECT_TYPES.STATIC_TEXT ||
				activeObject?._objects?.[0]?.type === OBJECT_TYPES.STATIC_TEXT;
			const isStaticImage =
				activeObject?.name === OBJECT_TYPES.STATIC_IMAGE ||
				activeObject?.type === OBJECT_TYPES.STATIC_IMAGE ||
				activeObject?._objects?.[0]?.type === OBJECT_TYPES.STATIC_IMAGE;
			const isFabricShape =
				activeObject?.type === LayerType.STATIC_RECT ||
				activeObject?.type === LayerType.STATIC_CIRCLE ||
				activeObject?.type === LayerType.STATIC_TRIANGLE;

			setComponentStatus(
				isStaticText
					? activeObject?.textType || activeObject?._objects?.[0]?.textType
					: isStaticImage
					? activeObject?.imageType || activeObject?._objects?.[0]?.imageType
					: activeObject?.shapeType || activeObject?._objects?.[0]?.shapeType,
			);
			setItems(
				isStaticText
					? ContextData.textComponentStatusMenu
					: isStaticImage
					? ContextData.imageComponentStatusMenu
					: isFabricShape
					? ContextData.basicShapeComponentStatusMenu
					: ContextData.shapeComponentStatusMenu,
			);
		}

		if (canvas) {
			canvas.on("object:moving", handleObjectMoving);
			canvas.on("text:changed", handleObjectEditing);
			canvas.on("object:modified", handleUpdateActiveObjectAndPosition);
			canvas.on("object:scaling", handleUpdateActiveObjectAndPosition);
			window.addEventListener("resize", handleUpdateActiveObjectAndPosition);
		}
		const panelMap: Partial<Record<LayerType, PanelConfig>> = {
			[LayerType.STATIC_TEXT]: {
				panels: [PanelType.SHAPEFILLCOLOR, PanelType.RESIZE, PanelType.CANVASCOLOR],
				targetPanel: PanelType.FONTSELECTOR,
			},
			[LayerType.STATIC_VECTOR]: {
				panels: [PanelType.RESIZE, PanelType.CANVASCOLOR, PanelType.TEXTFILL, PanelType.FONTSELECTOR],
				targetPanel: PanelType.SHAPEFILLCOLOR,
			},
			[LayerType.STATIC_IMAGE]: {
				panels: [
					PanelType.RESIZE,
					PanelType.CANVASCOLOR,
					PanelType.TEXTFILL,
					PanelType.FONTSELECTOR,
					PanelType.SHAPEFILLCOLOR,
				],
				targetPanel: PanelType.IMAGES,
			},
		};

		if (activeObject?.type && activeObject.type in panelMap && !isPopupView) {
			const layerType = activeObject.type as LayerType;
			const panelConfig = panelMap[layerType];
			if (panelConfig && panelConfig.panels.includes(activePanel)) {
				setActivePanel(panelConfig.targetPanel);
			}
		}

		return () => {
			if (canvas) {
				canvas.off("object:moving", handleObjectMoving);
				canvas.off("text:changed", handleObjectEditing);
				canvas.off("object:modified", handleUpdateActiveObjectAndPosition);
				canvas.off("object:scaling", handleUpdateActiveObjectAndPosition);
				window.removeEventListener("resize", handleUpdateActiveObjectAndPosition);
			}
		};
	}, [activeObject, editor?.canvas?.canvas.getActiveObject()]);

	useEffect(() => {
		const isSmartImageSelected =
			activeObject?.name === OBJECT_TYPES.GROUP &&
			activeObject._objects.length >= 2 &&
			activeObject._objects.some((obj: fabric.Object) => obj.id === ObjectsEnum.SmartImage);
		designEditorStore.setProperty("isSmartImageObjectSelected", isSmartImageSelected);

		editor?.canvas?.canvas.on("text:editing:exited", async function (e) {
			const modifiedObject = e.target;
			if (modifiedObject?.type === "StaticText") {
				const newTextValue = modifiedObject.text;
				EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.TEXT, newTextValue);
			}
		});
		return () => {
			if (editor?.canvas?.canvas) {
				editor?.canvas?.canvas.off("text:editing:exited");
			}
		};
	}, [activeObject]);

	const reAssignTheFixedWidthAndHeightToTheActiveObject = (active: fabric.Object) => {
		if (
			active &&
			active?.type === LayerType.STATIC_TEXT &&
			active.fixedSize &&
			active.fixedWidthValue &&
			active.fixedHeightValue &&
			active.width &&
			active.height &&
			active.fontSize
		) {
			if (active.width > active.fixedWidthValue) {
				active.set("width", active.fixedWidthValue);
			}
			if (active.height > active.fixedHeightValue) {
				active.set("height", active.fixedHeightValue);
			}
			active.set("width", active.fixedWidthValue);
			active.set("height", active.fixedHeightValue);
		}
	};

	const handleObjectMoving = () => {
		const active = editor?.canvas?.canvas.getActiveObject();
		if (active) {
			const activeObject: fabric.Object = active;
			reAssignTheFixedWidthAndHeightToTheActiveObject(activeObject);
		}
		setIsContextMoving(true);
	};

	const handleUpdateActiveObjectAndPosition = () => {
		setIsContextMoving(false);
		const active: any = editor?.canvas?.canvas.getActiveObject();
		setActiveObject(active);
		setContextMenuPosition({ left: active?.lineCoords?.tr?.x ?? 0, top: active?.lineCoords?.tl?.y ?? 0 });

		if (activeObject?.type === LayerType.STATIC_TEXT && activeObject?.fixedSize) {
			activeObject?.setControlsVisibility({
				mt: false,
				mb: false,
				ml: false,
				mr: false,
				bl: false,
				br: false,
				tl: false,
				tr: false,
				mtr: false,
			});
		}

		// Dynamically update menu position , so the menu will be always visible
		const canvas = editor?.canvas?.canvas;
		const originalCanvasHeight = editor?.scene.exportToJSON().frame?.height;

		const displayedCanvasHeight = canvas?.height;
		const originalCanvasWidth = editor?.scene.exportToJSON().frame?.width;
		const displayedCanvasWidth = canvas?.width;

		if (displayedCanvasHeight && originalCanvasHeight && displayedCanvasWidth && originalCanvasWidth) {
			const scaleFactorY = displayedCanvasHeight / originalCanvasHeight;
			const scaleFactorX = displayedCanvasWidth / originalCanvasWidth;
			const _currentScene = editor.scene.exportToJSON();

			for (const layer of _currentScene.layers) {
				if (layer?.width && layer?.scaleX && layer !== undefined && activeObject?.id === layer.id) {
					const layerTop = layer?.top;
					const top = layerTop && layerTop * scaleFactorY;
					const layerLeft = layer?.left;
					const left = layerLeft && layerLeft * scaleFactorX;
					const width = layer?.width * layer?.scaleX;
					const canvasRect = editor?.canvas?.canvas?.getElement()?.getBoundingClientRect();

					// Calculate the object's top position relative to the viewport
					const objectTopInViewport = top && canvasRect?.top + top;
					if (objectTopInViewport && objectTopInViewport > 290) {
						setOriginalTop("-200px");
					} else {
						setOriginalTop("30px");
					}
					if (width && left && left + width < displayedCanvasWidth / 2) {
						setSubMenuAlign("left");
					} else {
						setSubMenuAlign("right");
					}
				}
			}
		}
		reAssignTheFixedWidthAndHeightToTheActiveObject(active);
	};
	const handleObjectEditing = () => {
		const active = editor?.canvas?.canvas.getActiveObject();
		if (active) {
			const activeObject: fabric.Object = active;
			reAssignTheFixedWidthAndHeightToTheActiveObject(activeObject);
			setActiveObject(activeObject);
			if (
				activeObject?.type === LayerType.STATIC_TEXT &&
				activeObject.fixedSize &&
				activeObject.fixedWidthValue &&
				activeObject.fixedHeightValue
			) {
				fitTextIntoFixedTextBox(activeObject);
				activeObject.height = activeObject.fixedHeightValue;
			}
		}
	};
	const showContextMenu = () => {
		editor?.cancelContextMenuRequest();
		setContextMenuOpen(!contextMenuOpen);
	};

	const renderMenuItems = (menuItems: any[]) => {
		return menuItems.map((item, index) => (
			<Box key={index}>
				{item.divider ? (
					<hr className={styles.divider} />
				) : (
					<ContextMenuItem
						disabled={item.disabled}
						onClick={item.onClick}
						icon={item.icon}
						label={item.label}
						isSubMenu={item.isSubMenu}
						iconOnly={item.iconOnly}
						Selected={item.Selected}
					>
						{item.children && (
							<div
								className={`${styles.subMenu} ${
									subMenuAlign === "left" ? styles.alignLeft : styles.alignRight
								}`}
							>
								{renderMenuItems(item.children)}
							</div>
						)}
					</ContextMenuItem>
				)}
			</Box>
		));
	};

	const renderContextMenu = (menuItems: any[]) => {
		return (
			<Box
				onContextMenu={(e) => e.preventDefault()}
				className={styles.contextMenu}
				style={{
					top: contextMenuRequest ? `${contextMenuRequest.top}px` : originalTop,
					left: `${contextMenuRequest ? contextMenuRequest.left : -132}px`,
				}}
			>
				{renderMenuItems(menuItems)}
			</Box>
		);
	};
	const renderContextMenuItems = () => {
		if (!contextMenuRequest || !contextMenuRequest.target || isPopupView) {
			return null;
		}
		if (
			contextMenuRequest.target.type === OBJECT_TYPES.FRAME ||
			contextMenuRequest.target.type === OBJECT_TYPES.INITIAL_FRAME
		) {
			return renderContextMenu(ContextData.backgroundMenu);
		}
		if (contextMenuRequest.target.locked) {
			return renderContextMenu(ContextData.lockedMenu);
		}
		if (contextMenuOpen) {
			setContextMenuOpen(false);
		}
		return renderContextMenu(ContextData.mainMenu);
	};

	const handleComponentStatusChange = (e: SelectChangeEvent<string>) => {
		const value = e.target.value;
		setComponentStatus(value);

		if (activeObject.name === LayerType.STATIC_TEXT || activeObject.type === LayerType.STATIC_TEXT) {
			activeObject.textType = value;
		} else if (activeObject.name === LayerType.STATIC_IMAGE || activeObject.type === LayerType.STATIC_IMAGE) {
			activeObject.imageType = value;
		} else {
			activeObject.shapeType = value;
		}
		editor?.history.save();
	};

	return (
		<>
			{!isContextMoving && activeObject && !isPopupView && (
				<Box
					onContextMenu={(e) => e.preventDefault()}
					className={styles.actionsMenu}
					style={{
						top: `calc(${contextMenuPosition?.top}px - 45px)`,
						left:
							activeObject.type === OBJECT_TYPES.STATIC_VECTOR ||
							activeObject.type === OBJECT_TYPES.FRAME ||
							activeObject.name === OBJECT_TYPES.GROUP
								? `calc(${contextMenuPosition.left}px - 100px)`
								: `calc(${contextMenuPosition.left}px - 270px)`,
					}}
				>
					{/* we hide FixedTextBoxSizeCheckBox for now and we will add it in future with an updated design  */}
					{/* {activeObject.type === LayerType.STATIC_TEXT && <FixedTextBoxSizeCheckBox />} */}
					{activeObject.type !== OBJECT_TYPES.FRAME && activeObject.name !== OBJECT_TYPES.GROUP && (
						<BriaDropDown
							value={componentStatus}
							onChange={handleComponentStatusChange}
							items={items}
							height="20px"
							width="170px"
							className={
								activeObject.type === LayerType.STATIC_TEXT
									? styles.staticTextComponentStatus
									: styles.componentStatus
							}
							renderValue={(selected) => {
								if (selected) {
									return <Typography className={styles.renderedValue}>{selected}</Typography>;
								} else {
									return (
										<Typography className={styles.renderedValue}>
											{activeObject.type === OBJECT_TYPES.STATIC_TEXT
												? t("configureHeadingLevel")
												: activeObject.type === OBJECT_TYPES.STATIC_IMAGE
												? t("configureImageLevel")
												: activeObject.type === OBJECT_TYPES.STATIC_VECTOR
												? t("configureShapeLevel")
												: t("configureBasicShapeLevel")}
										</Typography>
									);
								}
							}}
							placeholder={
								activeObject.type === OBJECT_TYPES.STATIC_TEXT
									? t("configureHeadingLevel")
									: activeObject.type === OBJECT_TYPES.STATIC_IMAGE
									? t("configureImageLevel")
									: activeObject.type === OBJECT_TYPES.STATIC_VECTOR
									? t("configureShapeLevel")
									: t("configureBasicShapeLevel")
							}
						/>
					)}
					<ContextMenuItem
						onClick={async () => {
							if (activeObject?.type == OBJECT_TYPES.FRAME) {
								if (!contextMenuTimelineRequest.id && editor) {
									setContextMenuTimelineRequest({
										...contextMenuTimelineRequest,
										id: editor?.scene.exportToJSON().id,
									});
								}
								await duplicateScene(editor?.scene.exportToJSON().id);
							} else {
								await editor?.objects.copy();
								editor?.objects.paste();
								editor?.cancelContextMenuRequest();
							}
						}}
						icon={<Icons.Duplicate size={ICON_SIZE} />}
						iconOnly={true}
					/>
					<ContextMenuItem
						onClick={async () => {
							if (activeObject?.type == OBJECT_TYPES.FRAME) {
								if (!contextMenuTimelineRequest.id && editor) {
									setContextMenuTimelineRequest({
										...contextMenuTimelineRequest,
										id: editor?.scene.exportToJSON().id, // Replace with the current scene ID
									});
								}
								await deleteScene(editor?.scene.exportToJSON().id);
							} else {
								const activeObject = editor?.canvas.canvas.getActiveObject();

								const isRectangleObject = (object: fabric.Object) =>
									[
										ObjectsEnum.InnerRectangle as string,
										ObjectsEnum.OuterRectangle as string,
									].includes(object.id);

								if (activeObject?.name === LayerType.GROUP.toLowerCase()) {
									activeObject._objects?.some((item) => {
										if (isRectangleObject(item)) {
											handleDeleteSmartImage();
											return true;
										}
										return false;
									});
								} else if (activeObject && isRectangleObject(activeObject)) {
									handleDeleteSmartImage();
								}

								editor?.objects.remove();
								editor?.cancelContextMenuRequest();
							}
						}}
						icon={<Icons.Delete size={ICON_SIZE} />}
						iconOnly={true}
					/>
					<ContextMenuItem onClick={showContextMenu} icon={<Icons.Dots size={ICON_SIZE} />} iconOnly={true}>
						{contextMenuOpen &&
							(!activeObject?.locked
								? activeObject.type === OBJECT_TYPES.FRAME ||
								  activeObject.type === OBJECT_TYPES.INITIAL_FRAME
									? renderContextMenu(ContextData.backgroundMenu)
									: renderContextMenu(ContextData.mainMenu)
								: renderContextMenu(ContextData.lockedMenu))}
					</ContextMenuItem>
				</Box>
			)}

			{isPopupView &&
				activeObject?.type === LayerType.STATIC_IMAGE &&
				designEditorStore.activeStep === WIZARD_IMAGE_INDEX && (
					<WizardImageHeader contextMenuPosition={contextMenuPosition} />
				)}
			{renderContextMenuItems()}
		</>
	);
}

const ObservedComponent = observer(ContextMenu);
export default ObservedComponent;

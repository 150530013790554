export function fileDownload(
	fileUrl: string,
	passedFileName: string,
	forceExt?: string,
) {
	try {
		const link = document.createElement("a");
		link.href = fileUrl;
		const filenameFromUrl = new URL(fileUrl).pathname.split("/").pop();
		let ext = forceExt ?? filenameFromUrl?.split(".").pop();
		const filename = `${passedFileName}.${ext}`;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	} catch (error) {
		console.log(error);
		throw error;
	}
}
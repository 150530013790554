import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useDesignEditorContext from "../../../../../../hooks/useDesignEditorContext";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import useSceneOperations from "../Scenes/utils/SceneOperations";
import styles from "./SceneContextMenu.module.scss";

const SceneContextMenu = () => {
	const { setContextMenuTimelineRequest, contextMenuTimelineRequest } = useDesignEditorContext();
	const { deleteScene, duplicateScene } = useSceneOperations();
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.scenes" });
	const ref = React.useRef<HTMLDivElement | null>(null);
	useOnClickOutside(ref, () => {
		setContextMenuTimelineRequest({ ...contextMenuTimelineRequest, visible: false });
	});

	const timelineItemsContainerBounds = document.getElementById("TimelineItemsContainer")?.getBoundingClientRect() || {
		bottom: "100px",
		left: 0,
	};
	return (
		<Box
			ref={ref}
			className={styles.contextMenu}
			sx={{
				bottom: "80px",
				left: `${contextMenuTimelineRequest.left - timelineItemsContainerBounds.left}px`,
			}}
		>
			<Box
				onClick={async () => {
					await duplicateScene(undefined);
				}}
				className={styles.menuItem}
			>
				{t("duplicateScene")}
			</Box>
			<Box
				onClick={() => {
					deleteScene(undefined);
				}}
				className={styles.menuItem}
			>
				{t("deleteScene")}
			</Box>
		</Box>
	);
};

export default SceneContextMenu;

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate";
import styles from "./AutomotiveProjectsEmpty.module.scss";

const AutomotiveProjectsEmpty = () => {
	const { t } = useTranslation("translation", { keyPrefix: "automotiveApp.projects" });
	const navigate = useSecureNavigate();

	return (
		<Box className={styles.header}>
			<Typography className={styles.title}>{t("emptyStatus")}</Typography>
			<BriaButton
				buttonType="primaryMedium"
				onClick={() => navigate(RouterConstants.NEW_AUTOMOTIVE_PROJECT.fullPath)}
				className={styles.actionBtn}
			>
				{t("createNewProject")}
			</BriaButton>
		</Box>
	);
};

export default observer(AutomotiveProjectsEmpty);

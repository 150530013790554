import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import RouterConstants from "../../../../../constants/RouterConstants.ts";
import styles from "./ModelDownloadGuidePopup.module.scss";

type ModelDownloadGuidePopupProps = {
	open: boolean;
	onClose: () => void;
	onClick: () => void;
};

const ModelDownloadGuidePopup = ({ open, onClose, onClick }: ModelDownloadGuidePopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.popups.ModelDownloadGuide" });
	const guidelines = t("guidelines", { returnObjects: true });

	return (
		<ConfirmationPopup
			open={open}
			title={t("header")}
			onClick={onClick}
			maxWidth="616px"
			hideCancel={true}
			confirmButtonText={t(`primaryBtn`)}
			onClose={onClose}
			paperClassName={styles.paperWrap}
		>
			<Box>
				<ul className={styles.guidelines}>
					{Object.keys(guidelines).map((guideline) => (
						<li dangerouslySetInnerHTML={{ __html: t(`guidelines.${guideline}`) as string }} />
					))}
					<li>
						{t("accessDetails1")}
						<strong>{t("accessDetails2")}</strong>
						{t("accessDetails3")}
						<Link
							className={styles.link}
							onClick={(_e: React.MouseEvent<any>) => {
								window.open(RouterConstants.ACCESS_MODELS.fullPath, "_blank");
							}}
						>
							{t("clickHere")}
						</Link>
					</li>
				</ul>
			</Box>
		</ConfirmationPopup>
	);
};

export default observer(ModelDownloadGuidePopup);

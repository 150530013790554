import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import { APPS } from "../../../constants/AppsConstants";
import { PaginatedItems } from "../../../models/common";
import QueryService from "../../../utils/QueryService";
import { IPlaygroundStore } from "./playground-store";

export interface IPlaygroundProjectsStore {
	paginatedProjects: PaginatedItems<PlaygroundProject>;
	rowsPerPage: number;
	projectForm: PlaygroundProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundProject>]: boolean };
	};

	loadingProject: boolean;
	loadingOrgProjects: boolean;
	loadingCreateProject: boolean;
	loadingUpdateProject: boolean;
	loadingDeleteProject: boolean;

	handleFormChange: <K extends keyof PlaygroundProject>(key: K, value: PlaygroundProject[K]) => void;
	getProjects: (
		page?: number,
		filterBy?: FilterByOptions<PlaygroundProject>,
	) => Promise<PaginatedItems<PlaygroundProject>>;
	getProject: (id: string) => Promise<PlaygroundProject>;
	createProject: (projectToCreate: PlaygroundProject) => Promise<PlaygroundProject>;
	updateProject: (id: string, updatedProject: PlaygroundProject) => Promise<PlaygroundProject>;
	deleteProject: (id: string) => Promise<void>;
}

export class PlaygroundProjectsStore implements IPlaygroundProjectsStore {
	private queryService: QueryService = new QueryService("/playground-projects");
	playgroundStore: IPlaygroundStore;
	paginatedProjects: PaginatedItems<PlaygroundProject> = { items: [], total: 0 };
	rowsPerPage: number = 12;

	projectForm: PlaygroundProject = defaultProject;
	formErrors: {
		save?: boolean;
		requiredFields: { [key in keyof Partial<PlaygroundProject>]: boolean };
	} = {
		requiredFields: { name: false },
	};

	loadingOrgProjects: boolean = false;
	loadingProject: boolean = false;
	loadingCreateProject: boolean = false;
	loadingUpdateProject: boolean = false;
	loadingDeleteProject: boolean = false;

	loadingProjectModels: boolean = false;
	loadingProjectDatasets: boolean = false;

	constructor(playgroundStore: IPlaygroundStore) {
		makeAutoObservable(this);
		this.playgroundStore = playgroundStore;
	}

	handleFormChange = <K extends keyof PlaygroundProject>(key: K, value: PlaygroundProject[K]) => {
		this.projectForm = { ...this.projectForm, [key]: value };
	};

	getProjects = async (page?: number, filterBy?: FilterByOptions<PlaygroundProject>) => {
		try {
			this.loadingOrgProjects = true;
			const paginatedProjects: PaginatedItems<PlaygroundProject> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: page,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedProjects = paginatedProjects;
				this.loadingOrgProjects = false;
			});

			return this.paginatedProjects;
		} catch (err: any) {
			this.loadingOrgProjects = false;
			return Promise.reject(err);
		}
	};

	getProject = async (id: string) => {
		try {
			this.loadingProject = true;
			const project = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingProject = false;
			});

			return project;
		} catch (err: any) {
			this.loadingProject = false;
			return Promise.reject(err);
		}
	};

	createProject = async (projectToCreate: PlaygroundProject) => {
		try {
			this.loadingCreateProject = true;
			const newProject = await this.queryService.post(`/`, projectToCreate);

			runInAction(() => {
				this.loadingCreateProject = false;
			});

			return newProject;
		} catch (err: any) {
			this.loadingCreateProject = false;

			return Promise.reject(err);
		}
	};

	updateProject = async (id: string, projectToUpdate: PlaygroundProject) => {
		try {
			this.loadingUpdateProject = true;
			const updatedProject = await this.queryService.put(`/`, projectToUpdate);

			runInAction(() => {
				this.paginatedProjects.items = this.paginatedProjects?.items.map((project) =>
					project.id === id ? updatedProject : project,
				);
				projectToUpdate.id = id;
				this.loadingUpdateProject = false;
			});
			return projectToUpdate;
		} catch (err: any) {
			this.loadingUpdateProject = false;
			return Promise.reject(err);
		}
	};

	deleteProject = async (id: string) => {
		try {
			this.loadingDeleteProject = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.paginatedProjects = {
					items: this.paginatedProjects.items.filter((project) => project.id !== id),
					total: this.paginatedProjects.total - 1,
				};
				this.projectForm.id = id;
				this.loadingDeleteProject = false;
			});
		} catch (err: any) {
			this.loadingDeleteProject = false;
			const parsed = JSON.parse(err.response.data.message);
			const message = parsed.detail.trim();

			return Promise.reject(message);
		}
	};
}

export type PlaygroundSession = {
	id: string;
	project_id: number;
	form_app: string;
	created_at?: string;
	updated_at?: string;
};

export type PlaygroundProject = {
	id: string;
	user_id?: string;
	organization_id?: string;
	name: string;
	description?: string;
	from_app?: APPS;
	extra_info?: any;
	created_at: string;
	updated_at: string;
	sessions: string[];
};

export const defaultProject: PlaygroundProject = {
	id: "",
	name: "",
} as PlaygroundProject;

import { Box, Collapse, FormControlLabel, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Vector from "../../../../../assets/images/svgs/VectorIcon.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import BriaSlider from "../../../../../components/common/BriaSlider/BriaSlider";
import InputWithHighlightedPrefix from "../../../../../components/common/InputWithHighlightedPrefix/InputWithHighlightedPrefix";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { ImageEditingViewMode } from "../../../../../models/image-to-image";
import TgModelPlaygroundGallery from "./Images/TgModelPlaygroundGallery";
import styles from "./TgModelPlayground.module.scss";

const stepsConfig = {
	fast: {
		min: 4,
		max: 20,
		default: 8,
	},
	not_fast: {
		min: 20,
		max: 50,
		default: 30,
	},
};

const TgModelPlayground = () => {
	const { modelId } = useParams();
	const { t, i18n } = useTranslation("translation", { keyPrefix: "newTg.models.viewer.playground" });
	const { t: t1 } = useTranslation("translation");
	const { tgStore, playgroundStore } = useAppStore();
	const { tgModelStore, tgPlaygroundStore } = tgStore;
	const stepsSettings = tgPlaygroundStore.playgroundForm.fast ? stepsConfig.fast : stepsConfig.not_fast;
	const [showError, setShowError] = useState(false);
	const disableGenerate =
		!modelId ||
		!(tgPlaygroundStore.playgroundForm.prompt || tgModelStore.modelToView.generation_prefix) ||
		tgPlaygroundStore.playgroundImages.some((img) => img.loading);

	useEffect(() => {
		// THATS NOT OK, should seperate BriaImage completely from the apps Playground
		// If we want to add common style for BriaImage, it should be added as a component prop and not depend on playgroundStore
		playgroundStore.imageEditingViewMode = ImageEditingViewMode.GALLERY;
		tgPlaygroundStore.playgroundForm.fast = tgModelStore.modelToView.training_version === "light";
	}, [tgModelStore.modelToView]);

	useEffect(() => {
		tgPlaygroundStore.playgroundForm.num_steps = stepsSettings.default;
	}, [stepsSettings]);

	const handleGenerate = async () => {
		if (modelId && !disableGenerate) {
			setShowError(false);
			try {
				await tgPlaygroundStore.generate(modelId, tgModelStore.modelToView.generation_prefix);
			} catch (err) {
				if (
					i18n.exists(
						`newTg.models.viewer.playground.prompt.errors.${tgPlaygroundStore.formErrors.generate?.status}`,
					)
				) {
					setShowError(true);
				}
			}
		}
	};

	return (
		<Box>
			<InputLayout label={t("prompt.label")}>
				<Box className={styles.promptWrapper}>
					<Box className={styles.inputWrapper}>
						{tgModelStore.modelToView.generation_prefix ? (
							<InputWithHighlightedPrefix
								className={styles.inputWithPrefix}
								key={tgModelStore.modelToView.id}
								value={tgPlaygroundStore.playgroundForm.prompt}
								info={t1("prefixTooltip")}
								prefix={tgModelStore.modelToView.generation_prefix}
								onChange={(text) => {
									tgPlaygroundStore.handleFormChange("prompt", text as string);
								}}
								onEnterPress={handleGenerate}
								multiline={true}
								minRows={1}
								maxRows={3}
							/>
						) : (
							<BriaInput
								className={styles.searchBox}
								placeholder={t("prompt.placeholder")}
								value={tgPlaygroundStore.playgroundForm.prompt}
								onChange={(e) => tgPlaygroundStore.handleFormChange("prompt", e.target.value)}
								InputProps={{
									className: styles.inputBase,
								}}
								onEnterPress={handleGenerate}
							/>
						)}
						<Collapse
							in={showError && !!tgPlaygroundStore.formErrors.generate}
							onExited={() => (tgPlaygroundStore.formErrors.generate = undefined)}
						>
							<Box className={styles.errorMessageContainer}>
								<img src={Vector} />
								<Typography className={styles.errorMessage}>
									{t(`prompt.errors.${tgPlaygroundStore.formErrors.generate?.status}`)}
								</Typography>
							</Box>
						</Collapse>
					</Box>

					{tgModelStore.modelToView.training_version === "light" && (
						<FormControlLabel
							className={styles.fastSwitch}
							control={
								<BriaCheckbox
									checked={tgPlaygroundStore.playgroundForm.fast}
									onChange={(e) => tgPlaygroundStore.handleFormChange("fast", e.target.checked)}
								/>
							}
							label={t("prompt.switch.fast")}
						/>
					)}
					<BriaButton
						buttonType="primaryMedium"
						onClick={handleGenerate}
						loading={tgPlaygroundStore.loadingGenerate}
						disabled={disableGenerate}
					>
						{t("prompt.button")}
					</BriaButton>
				</Box>
			</InputLayout>
			<Box className={styles.sliders}>
				<InputLayout label={t("influence.label")} info={t("influence.info")}>
					<BriaSlider
						className={styles.slider}
						value={tgPlaygroundStore.playgroundForm.model_influence}
						onChange={(_, value) => tgPlaygroundStore.handleFormChange("model_influence", value as number)}
						step={0.05}
						min={0}
						max={1}
						marks={[
							{ value: 0, label: t("influence.low") },
							{ value: 1, label: t("influence.high") },
						]}
						valueLabelDisplay="auto"
						labelsAlwaysGray
					/>
				</InputLayout>
				<InputLayout label={t("steps.label")} info={t("steps.info")}>
					<BriaSlider
						className={styles.slider}
						value={tgPlaygroundStore.playgroundForm.num_steps || stepsSettings.default}
						onChange={(_, value) => tgPlaygroundStore.handleFormChange("num_steps", value as number)}
						step={1}
						min={stepsSettings.min}
						max={stepsSettings.max}
						marks={[
							{ value: stepsSettings.min, label: stepsSettings.min.toString() },
							{ value: stepsSettings.max, label: stepsSettings.max.toString() },
						]}
						valueLabelDisplay="auto"
						labelsAlwaysGray
					/>
				</InputLayout>
			</Box>
			<TgModelPlaygroundGallery />
		</Box>
	);
};

export default observer(TgModelPlayground);

import { Box } from "@mui/material";
import clsx from "clsx";
import { useContext } from "react";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import Icons from "../../../../../components/Icons/index.ts";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import useSmartImageHeader from "../../../../../hooks/useSmartImageHeader.tsx";
import FabricObjectsProperties from "../../Panels/panelItems/Common/FabricObjectsProperties/FabricObjectsProperties.tsx";
import styles from "./StaticPath.module.scss";

const StaticPath = () => {
	const { isPopupView } = useContext(DesignEditorContext);
	const { handleDeleteSmartImage } = useSmartImageHeader();
	const { uiStore } = useAppStore();

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box
				className={clsx({
					[styles.staticPathControlsContainer]: true,
					[styles.noMarginRight]: uiStore.showGuideSection,
				})}
			>
				<Box onClick={handleDeleteSmartImage} className={styles.StaticPathControlsWrapper}>
					<Icons.Delete size={20} />
				</Box>
				<FabricObjectsProperties />
			</Box>
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
};

export default StaticPath;

import { Box, Typography } from "@mui/material";

import * as Sentry from "@sentry/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import BriaDropDown from "../../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput";
import useStaticDropdown from "../../../../../../components/common/DropDowns/useStaticDropdown";
import { APPS, IMAGES_RESOLUTIONS_LIST } from "../../../../../../constants/AppsConstants";
import { useManualPlacementConfig } from "../../../../../../hooks/useManualPlacementConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import {
	ImageEditingConfigComponentProps,
	ImageEditingViewMode,
	Platform,
	PlaygroundImage,
	ResponsePlatformDestination,
} from "../../../../../../models/image-to-image";
import { hasOrganization, isPlaygroundLoadingImages } from "../../../../../../utils";
import Config from "../../Config/Config";
import styles from "./ManualPlacement.module.scss";

const ManualPlacementConfig: React.FC<ImageEditingConfigComponentProps> = ({ onBackButtonClick }) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.imageToImage.config.features.manualPlacement",
	});
	const { items: AspectRatiosList } = useStaticDropdown({ tKeyPrefix: "aspectRatio" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const {
		selectedConfig,
		setSelectedConfig,
		selectedAspectRatio,
		selectedPlatform,
		selectedPlatformDimensions,
		cropConfig,
		platformsList,
		customWidth,
		setCustomWidth,
		customHeight,
		setCustomHeight,
		handleConfigChange,
		handleMediumDestinationChange,
		handleAspectRatioSelection,
		handlePlatformSelection,
		setSelectedAspectRatio,
		setSelectedPlatformDimensions,
		checkResolutions,
		exceededResolutions,
		isExpandEnabled,
		isImageInsideCanvas,
		calculateImageDimensions,
		getCropConfig,
	} = useManualPlacementConfig();

	const [selectedImage, setSelectedImage] = useState<PlaygroundImage | null>();
	const hasTextToImageLoadingImages =
		isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.TEXT_TO_IMAGE) || false;

	useEffect(() => {
		imageToImageStore.toggleManualPlacementConfig(true);
		getCropConfig();
		return () => {
			imageToImageStore.toggleManualPlacementConfig(false);
		};
	}, [getCropConfig, imageToImageStore]);

	useEffect(() => {
		setOriginalAspectRatioDefault();
	}, []);

	useEffect(() => {
		if (playgroundStore.imageEditingViewMode !== ImageEditingViewMode.SINGLE) {
			onBackButtonClick?.();
		}
		checkResolutions();
	}, [playgroundStore.imageEditingViewMode, onBackButtonClick]);

	useEffect(() => {
		playgroundStore.playgroundResults.length > 0
			? setSelectedImage(playgroundStore.singleSelectedImage || playgroundStore.getSelectedImages()[0])
			: setSelectedImage(null);
		calculateImageDimensions();
	}, [playgroundStore.playgroundResults, playgroundStore.singleSelectedImage]);

	const setOriginalAspectRatioDefault = () => {
		setSelectedConfig(t("aspectRatio"));
		handleAspectRatioSelection("original");
	};

	const handleExpandBackground = async () => {
		const selectedImage =
			playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages().find((image) => image.selected);
		if (selectedImage) {
			try {
				await imageToImageStore.expandBackground(selectedImage);
				setOriginalAspectRatioDefault();
			} catch (e) {
				Sentry.captureMessage(`Error expanding object: ${e}`);
				throw new Error(`Error expanding object: ${e}`);
			}
		}
	};

	const handleCropImage = async () => {
		if (selectedImage) {
			try {
				await imageToImageStore.cropImage(selectedImage);
				setOriginalAspectRatioDefault();
			} catch (e) {
				Sentry.captureMessage(`Error cropping the image: ${e}`);
				console.error("Error cropping the image:", e);
			}
		}
	};

	const resetOtherConfigs = (currentConfig: string) => {
		if (currentConfig !== t("aspectRatio")) {
			setSelectedAspectRatio("");
		}
		if (currentConfig !== t("customSize")) {
			setCustomHeight("");
			setCustomWidth("");
		}
		if (currentConfig !== t("mediumDestination")) {
			setSelectedPlatformDimensions({});
		}
	};

	const renderAspectRatioConfig = () => (
		<Config
			title={t("aspectRatio")}
			checked={selectedConfig === t("aspectRatio")}
			onClick={() => {
				setSelectedConfig(t("aspectRatio"));
				resetOtherConfigs(t("aspectRatio"));
				handleAspectRatioSelection(selectedAspectRatio);
			}}
			isActive={selectedConfig == t("aspectRatio")}
			setIsActive={setSelectedConfig}
			titleOnly
		>
			<Box className={styles.configContent}>
				<Box className={styles.aspectRatioList}>
					{AspectRatiosList?.map((aspectRatio, index) => {
						const value = aspectRatio.value as string;
						const isSelected = value === selectedAspectRatio;
						return (
							<BriaButton
								key={index}
								onClick={() => {
									setSelectedConfig(t("aspectRatio"));
									resetOtherConfigs(t("aspectRatio"));
									handleAspectRatioSelection(value);
								}}
								className={clsx(styles.aspectRatioBtn, { [styles.selected]: isSelected })}
								sx={{ aspectRatio: value.replace(":", "/") }}
							>
								{value}
							</BriaButton>
						);
					})}
				</Box>
				{renderActions()}
			</Box>
		</Config>
	);

	const renderCustomSizeConfig = () => (
		<Config
			title={t("customSize")}
			checked={selectedConfig === t("customSize")}
			onClick={() => {
				setSelectedConfig(t("customSize"));
				resetOtherConfigs(t("customSize"));
				if (customWidth && customHeight) {
					handleConfigChange("width", parseInt(customWidth));
					handleConfigChange("height", parseInt(customHeight));
				}
			}}
			isActive={selectedConfig == t("customSize")}
			setIsActive={setSelectedConfig}
			titleOnly
		>
			<Box className={clsx(styles.configContent)}>
				<Box className={styles.dimensions}>
					<InputLayout label={t("width")} className={styles.inputLayout}>
						<BriaInput
							value={customWidth}
							onChange={(e) => {
								const target = e.target as HTMLInputElement;
								setCustomWidth(target.value);
								handleConfigChange("width", parseInt(target.value));
								if (customHeight) {
									resetOtherConfigs(t("customSize"));
								}
							}}
							type="number"
							customEndAdornmentText="px"
							height="40px"
						/>
					</InputLayout>

					<InputLayout label={t("height")} className={styles.inputLayout}>
						<BriaInput
							value={customHeight}
							onChange={(e) => {
								const target = e.target as HTMLInputElement;
								setCustomHeight(target.value);
								handleConfigChange("height", parseInt(target.value));
								if (customWidth) {
									resetOtherConfigs(t("customSize"));
								}
							}}
							type="number"
							customEndAdornmentText="px"
							height="40px"
						/>
					</InputLayout>
				</Box>

				{renderActions()}
			</Box>
		</Config>
	);

	const renderMediumDestinationConfig = () => (
		<Config
			title={t("mediumDestination")}
			checked={selectedConfig === t("mediumDestination")}
			onClick={() => {
				setSelectedConfig(t("mediumDestination"));
				resetOtherConfigs(t("mediumDestination"));
				handlePlatformSelection(selectedPlatformDimensions);
			}}
			isActive={selectedConfig == t("mediumDestination")}
			setIsActive={setSelectedConfig}
			titleOnly
		>
			<Box className={styles.configContent}>
				<BriaDropDown
					value={selectedPlatform}
					onChange={(value) => {
						handleMediumDestinationChange(value);
						setSelectedConfig(t("mediumDestination"));
						resetOtherConfigs(t("mediumDestination"));
					}}
					placeholder={t("dropDownPlaceholder")}
					items={platformsList}
					className={styles.dropDown}
					width="-webkit-fill-available"
				/>
				{selectedPlatform && (
					<Box className={styles.platformItems}>
						{cropConfig.map(
							(platform: Platform) =>
								platform.get?.(selectedPlatform)?.map((item: ResponsePlatformDestination) => {
									const isSelected =
										item.name === selectedPlatformDimensions?.name &&
										item.width === selectedPlatformDimensions?.width &&
										item.height === selectedPlatformDimensions.height;
									return (
										<BriaButton
											key={`${selectedPlatform}-${item.name}`}
											onClick={() => {
												setSelectedConfig(t("mediumDestination"));
												resetOtherConfigs(t("mediumDestination"));
												handlePlatformSelection(item);
											}}
											className={clsx(styles.platformItem, { [styles.selected]: isSelected })}
										>
											<Box
												className={styles.platformOption}
												sx={{
													width: item.resize.width ?? 0,
													height: item.resize.height ?? 0,
												}}
											/>
											<Box>
												<Typography className={styles.label}>{item.name}</Typography>
												<Typography className={styles.label}>
													{item.width} x {item.height}
												</Typography>
											</Box>
										</BriaButton>
									);
								}),
						)}
					</Box>
				)}
				{renderActions()}
			</Box>
		</Config>
	);

	const renderIncreaseResolutionConfig = () => {
		const resolutionsList = IMAGES_RESOLUTIONS_LIST.filter((item) => item.key !== "X1");

		const handleIncreaseResolution = (resolution: string) => {
			const resFactor = parseInt(resolution.replace("X", ""));
			if (selectedImage) {
				imageToImageStore.increaseImageResolution(selectedImage, resFactor);
			}
		};
		return (
			<Config
				title={t("increaseResolution")}
				checked={selectedConfig === t("increaseResolution")}
				titleOnly
				tooltip={t("increaseResolutionTooltip")}
				isActive
				className={styles.increaseResolutionConfig}
			>
				<Box className={clsx(styles.configContent, styles.increaseResolutionList)}>
					{resolutionsList?.map((resolution) => (
						<BriaButton
							key={resolution.key}
							disabled={
								!selectedImage ||
								imageToImageStore.isResizingImages ||
								exceededResolutions?.includes(resolution.value) ||
								imageToImageStore.isGeneratingImages ||
								(hasTextToImageLoadingImages as boolean)
							}
							buttonType="secondarySmall"
							onClick={() => handleIncreaseResolution(resolution.value)}
							size="small"
						>
							{resolution.value}
						</BriaButton>
					))}
				</Box>
			</Config>
		);
	};

	const renderActions = () => {
		return (
			<Box className={styles.actionsContainer}>
				<BriaButton
					className={styles.actionBtn}
					buttonType="secondarySmall"
					disabled={
						!selectedImage ||
						imageToImageStore.isResizingImages ||
						!isImageInsideCanvas() ||
						imageToImageStore.isGeneratingImages ||
						(hasTextToImageLoadingImages as boolean)
					}
					onClick={handleCropImage}
					size="small"
				>
					{t("crop")}
				</BriaButton>
				<BriaButton
					className={styles.actionBtn}
					buttonType="primary"
					disabled={
						!selectedImage ||
						!isExpandEnabled() ||
						imageToImageStore.isResizingImages ||
						imageToImageStore.isGeneratingImages ||
						(hasTextToImageLoadingImages as boolean)
					}
					onClick={handleExpandBackground}
					size="small"
				>
					{t("expandBackground")}
				</BriaButton>
			</Box>
		);
	};

	return (
		<Box>
			<Box className={styles.manualPlacementConfigContainer}>
				{renderAspectRatioConfig()}
				{renderCustomSizeConfig()}
				{renderMediumDestinationConfig()}
				{hasOrganization() && renderIncreaseResolutionConfig()}
			</Box>
		</Box>
	);
};

export default observer(ManualPlacementConfig);

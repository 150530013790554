import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../assets/images/icons/CloseIcon.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import styles from "./GuideContainer.module.scss";
interface GuideContainerProps {
	children: ReactNode;
	handleBack: () => void;
	handleForward: () => void;
	onClose: () => void;
}
const GuideContainer = ({ children, handleBack, handleForward, onClose }: GuideContainerProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplatesGuideSection" });
	const { uiStore } = useAppStore();
	return (
		<Box className={styles.guideContainter} ref={uiStore.guideSectionContainerRef}>
			<Box className={styles.header}>
				<Typography className={styles.title}>{t("userGuide")}</Typography>
				<Box className={styles.controlButtons}>
					<IconButton className={styles.backIconButton} onClick={handleBack}>
						<ArrowBack className={styles.icon} />
					</IconButton>
					<IconButton className={styles.forwardIconButton} onClick={handleForward}>
						<ArrowForward className={styles.forwardIcon} />
					</IconButton>
					<CloseIcon onClick={onClose} className={styles.closeIcon} />
				</Box>
			</Box>
			<Box>{children}</Box>
		</Box>
	);
};
const ObservedComponent = observer(GuideContainer);
export default ObservedComponent;

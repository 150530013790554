import { Close } from "@mui/icons-material";
import { Box, Dialog, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import { IMAGES_FORMATS, IMAGES_FORMATS_LIST, IMAGES_RESOLUTIONS } from "../../../constants/AppsConstants";
import { useLogDownloadEvents } from "../../../hooks/useLogDownloadEvents";
import { useAppStore } from "../../../hooks/useStores";
import InputLayout from "../../../layout/InputLayout/InputLayout";
import { PlaygroundImage } from "../../../models/image-to-image";
import { TextToImageConfigType } from "../../../models/text-to-image";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import { checkImageResolution, hasOrganization } from "../../../utils";
import useImageUtils from "../../../utils/useImageUtils";
import BriaButton from "../BriaButton/BriaButton";
import BriaDropDown, { DropDownItem } from "../BriaDropDown/BriaDropDown";
import BriaIconButton from "../BriaIconButton/BriaIconButton";
import BriaInput from "../BriaInput/BriaInput";
import styles from "./ExportImagePopup.module.scss";

export type ExportImageProps = {
	downloadProps?: { fileName: string };
	onDownload?: () => void;
	images?: PlaygroundImage[];
	analyticsEvents?: ANALYTICS_EVENTS[];
};

type ExportImagePopupProps = {
	open: boolean;
};

export default function ExportImagePopup({ open }: ExportImagePopupProps) {
	const { uiStore, playgroundStore, textToImageStore, authStore } = useAppStore();
	const { downloadProps, onDownload, images } = uiStore.exportImageProps;
	const { exportImage, uploadImageAndExportPsd } = useImageUtils();
	const { t } = useTranslation("translation", { keyPrefix: "playground.exportImagesPopup" });
	const { logImageDownloadEvents } = useLogDownloadEvents();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedImages, setSelectedImages] = useState<PlaygroundImage[]>([]);
	const [resolutionsList, setResolutionsList] = useState<DropDownItem[]>([]);
	const isTextToVector = textToImageStore.isTextToVector();
	const defaultFileType = isTextToVector ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG;

	const onClose = () => {
		uiStore.setProperty("ExportImagePopup", false);
	};

	const filteredFormatsList = useMemo(
		() =>
			IMAGES_FORMATS_LIST.filter(
				(format) =>
					format.value !==
						(defaultFileType === IMAGES_FORMATS.PNG ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG) &&
					(!iframeStore.isIframe() ||
						format.value !== IMAGES_FORMATS.PSD ||
						iframeStore.iframe.config.general_config?.enable_psd),
			),
		[defaultFileType],
	);

	const [exportConfig, setExportConfig] = useState({
		fileName: downloadProps?.fileName || "",
		fileType: defaultFileType as string,
		resolution: IMAGES_RESOLUTIONS.X1 as string,
	});

	useEffect(() => {
		const imagesList = images ? images : playgroundStore.getSelectedImagesForExport();
		setSelectedImages(imagesList);
		if (imagesList.length > 0) {
			const firstImage = imagesList[0];
			setExportConfig({
				fileName: playgroundStore.getFileName(firstImage),
				fileType: ((firstImage?.config as TextToImageConfigType)?.format?.toUpperCase() ||
					defaultFileType) as string,
				resolution: IMAGES_RESOLUTIONS.X1,
			});
		}
	}, []);

	useEffect(() => {
		filterSupportedResolutions();
	}, [selectedImages]);

	const filterSupportedResolutions = async () => {
		const enableIncreaseRes =
			!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_increase_res;
		const resolutionsToCheck = enableIncreaseRes
			? [IMAGES_RESOLUTIONS.X1, IMAGES_RESOLUTIONS.X2, IMAGES_RESOLUTIONS.X4]
			: [IMAGES_RESOLUTIONS.X1];
		const filteredResolutions: Set<string> = new Set(resolutionsToCheck);
		if (enableIncreaseRes) {
			const resolutionFactors = resolutionsToCheck.map((res) => parseInt(res.replace("X", "")));
			setIsLoading(true);
			for (const image of selectedImages) {
				for (const factor of resolutionFactors) {
					const exceed = await checkImageResolution(image.url, factor);
					if (exceed) {
						resolutionsToCheck.forEach((res) => {
							const resFactor = parseInt(res.replace("X", ""));
							if (resFactor >= factor) {
								filteredResolutions.delete(res);
							}
						});
					}
				}
			}
		}

		setResolutionsList(
			Array.from(filteredResolutions).map((res) => ({
				key: res,
				value: res,
			})),
		);
		setIsLoading(false);
	};

	const handleLogDownloadEvents = (resolution: number) => {
		logImageDownloadEvents(selectedImages, exportConfig.fileType, resolution);
	};

	const handleExportSingleImage = useCallback(
		async (resolution: number, action: "download" | "save") => {
			setIsLoading(true);
			try {
				if (selectedImages.length > 1) {
					await playgroundStore.exportImages(selectedImages, undefined, resolution, action);
				} else {
					await exportImage(selectedImages[0].url, exportConfig.fileName, resolution, action);
				}
			} catch (error) {
				console.error("Failed to export image:", error);
			} finally {
				setIsLoading(false);
				onDownload?.();
			}
		},
		[selectedImages, exportConfig.fileName, exportConfig.resolution, exportImage, playgroundStore, onDownload],
	);

	const exportImageAsPsd = useCallback(
		async (resolution: number, action: "download" | "save") => {
			setIsLoading(true);
			try {
				await Promise.all(
					selectedImages.map((image) =>
						uploadImageAndExportPsd(image.url, exportConfig.fileName, resolution, action),
					),
				);
			} catch (error) {
				console.error("Failed to upload image as PSD:", error);
			} finally {
				setIsLoading(false);
			}
		},
		[selectedImages, uploadImageAndExportPsd, exportConfig.fileName],
	);

	const handleExportImage = async (event: React.MouseEvent<HTMLElement>, action: "download" | "save") => {
		event.stopPropagation();
		try {
			const resolution = parseInt(exportConfig.resolution.replace("X", ""));
			if (exportConfig.fileType === IMAGES_FORMATS.PSD) {
				await exportImageAsPsd(resolution, action);
			} else {
				await handleExportSingleImage(resolution, action);
			}
			handleLogDownloadEvents(resolution);
		} catch (e) {
			console.error("Error exporting image:", e);
		} finally {
			onClose();
		}
	};

	return (
		<Dialog classes={{ paper: styles.paper }} onClose={onClose} open={open} maxWidth={false}>
			<Box className={styles.container}>
				<Box className={styles.dialogHeader}>
					<Typography variant="h6" className={styles.title}>
						{t("export")}
					</Typography>
					<BriaIconButton onClick={onClose} className={styles.closeButton}>
						<Close />
					</BriaIconButton>
				</Box>
				{selectedImages?.length === 1 && (
					<InputLayout label={t("fileName")}>
						<BriaInput
							type="text"
							className={styles.inputField}
							height="40px"
							value={exportConfig.fileName}
							onChange={(e) => setExportConfig((prev) => ({ ...prev, fileName: e.target.value }))}
						/>
					</InputLayout>
				)}

				<Box className={styles.inputsRow}>
					{(!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_psd) && (
						<InputLayout label={t("fileType")} className={styles.inputField}>
							<BriaDropDown
								items={filteredFormatsList}
								value={exportConfig.fileType}
								placeholder={exportConfig.fileType}
								className={styles.dropDown}
								loading={authStore.isLoadingOrgSubscriptions}
								disabled={
									authStore.orgPassedFreeLimit || authStore.isSubscriptionInactive ||
									(iframeStore.isIframe() && !iframeStore.iframe.config.general_config?.enable_psd)
								}
								onChange={(e) => setExportConfig((prev) => ({ ...prev, fileType: e.target.value }))}
							/>
						</InputLayout>
					)}
					{hasOrganization() && (!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_increase_res) && (
						<InputLayout label={t("increaseResolution")} className={styles.inputField}>
							<BriaDropDown
								items={resolutionsList}
								disabled={
									authStore.orgPassedFreeLimit || authStore.isSubscriptionInactive ||
									exportConfig.fileType === IMAGES_FORMATS.SVG ||
									(iframeStore.isIframe() &&
										!iframeStore.iframe.config.general_config?.enable_increase_res) ||
									isLoading
								}
								placeholder={exportConfig.resolution}
								loading={authStore.isLoadingOrgSubscriptions}
								value={exportConfig.resolution}
								className={styles.dropDown}
								onChange={(e) => setExportConfig((prev) => ({ ...prev, resolution: e.target.value }))}
							/>
						</InputLayout>
					)}
				</Box>
				<Box className={styles.actionsContainer}>
					{iframeStore.isIframe() && iframeStore.iframe.config.general_config?.enable_save && (
						<BriaButton
							onClick={(e) => handleExportImage(e, "save")}
							buttonType={
								iframeStore.iframe.config.general_config?.enable_download ? "textMedium" : "primary"
							}
							className={styles.actionBtn}
							disabled={isLoading || selectedImages.length === 0}
							loading={isLoading}
						>
							{iframeStore.iframe.config.general_config?.save_label
								? iframeStore.iframe.config.general_config?.save_label
								: t("save")}
						</BriaButton>
					)}
					{(!iframeStore.isIframe() || iframeStore.iframe.config.general_config?.enable_download) && (
						<BriaButton
							onClick={(e) => handleExportImage(e, "download")}
							buttonType="primary"
							className={styles.actionBtn}
							disabled={isLoading || selectedImages.length === 0}
							loading={isLoading}
						>
							{t("export")}
						</BriaButton>
					)}
				</Box>
			</Box>
		</Dialog>
	);
}

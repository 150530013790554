import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import BriaTooltip from "../../../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import border from "../../../../../assets/svgs/border-style-2.svg";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { PanelType } from "../../../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import { useEditor } from "../../../../../hooks/useEditor.tsx";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen.tsx";
import { LayerType } from "../../../../../types/layers.ts";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import FabricElementsShadow from "../../Panels/panelItems/Common/FabricElementsShadow/FabricElementsShadow.tsx";
import FabricObjectsProperties from "../../Panels/panelItems/Common/FabricObjectsProperties/FabricObjectsProperties.tsx";
import BrandSettings from "../BrandSettings/BrandSettings.tsx";
import BorderPopover from "./BorderPopover/BorderPopover.tsx";
import styles from "./FabricShapes.module.scss";
const FabricShapes = () => {
	const editor = useEditor();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const activeObject = useActiveObject() as any;
	const { setActivePanel } = useAppContext();
	const [strokeColor, setStrokeColor] = useState(activeObject?.stroke);
	const [fillColor, setFillColor] = useState(activeObject?.fill);
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { isPopupView } = useContext(DesignEditorContext);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
	const { uiStore } = useAppStore();
	const isFabricShape =
		activeObject?.type === LayerType.STATIC_RECT ||
		activeObject?.type === LayerType.STATIC_CIRCLE ||
		activeObject?.type === LayerType.STATIC_TRIANGLE;

	useEffect(() => {
		let watcher = async () => {
			if (activeObject && isFabricShape) {
				setStrokeColor(activeObject.stroke);
				setFillColor(activeObject.fill);
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);
	const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box
				className={clsx({
					[styles.shapesToolBar]: true,
					[styles.noMarginRight]: uiStore.showGuideSection,
				})}
			>
				<Box className={styles.rightSideControls}>
					<Box className={styles.leftToolBarSide}>
						<BriaTooltip title={t("fillColor")}>
							<Box className={styles.shapeColors}>
								<Box
									style={{
										backgroundColor: fillColor,
										background: fillColor,
										opacity: activeObject?.opacity ?? 1,
									}}
									onClick={() => {
										setIsSidebarOpen(true);
										setActivePanel(PanelType.SHAPEFILLCOLOR);
									}}
									className={styles.colorBox}
								></Box>
							</Box>
						</BriaTooltip>
						<BriaTooltip title={t("borderColor")}>
							<Box className={styles.shapeColors}>
								<Box
									style={{
										backgroundColor: strokeColor,
										background: strokeColor,
										opacity: activeObject?.opacity ?? 1,
									}}
									onClick={() => {
										setIsSidebarOpen(true);
										setActivePanel(PanelType.STROKECOLOR);
									}}
									className={styles.colorBox}
								></Box>
							</Box>
						</BriaTooltip>
					</Box>
					<Box className={styles.verticalDivider} />
					<Box>
						<BriaButton
							buttonType="textMedium"
							onClick={handleOpenDialog}
							className={clsx({
								[styles.BorderButtonWrapper]: true,
								[styles.selected]: !!anchorEl,
							})}
						>
							<img src={border} />
							<Typography className={styles.buttonLabel}>{t("border")}</Typography>
						</BriaButton>
						<BorderPopover open={!!anchorEl} handleClose={handleClose} anchorEl={anchorEl} />
					</Box>
					<FabricElementsShadow />
					<FabricObjectsProperties />
				</Box>
				<EditAllAdsSwitch />
			</Box>
			<BrandSettings
				activeObject={activeObject}
				showBrandColorDropdown={true}
				showStrokeBrandColorDropdown={true}
			/>
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
};

const ObservedComponent = observer(FabricShapes);
export default ObservedComponent;

import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useRef } from "react";
import DeleteIcon from "../../../../../../../../../assets/images/icons/DeleteIcon";
import { EditIcon } from "../../../../../../../../../assets/images/icons/EditIcon";
import useImageUtils from "../../../../../../../../../utils/useImageUtils";

import PlusIcon from "../../../../../../../../../assets/images/icons/PlusIcon";
import BriaIconButton from "../../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import { getSelectedOrganization } from "../../../../../../../../../helpers/localStorage";
import { BrandLogo, defaultBrandLogos } from "../../../../../../../../../models/brandLogo";
import { LogoTypeEnum, LogoTypeMappingEnum } from "../../../../../../../../../models/common.ts";
import common from "../../Common.module.scss";
import styles from "./LogoItem.module.scss";

interface LogoItemProps {
	logo: BrandLogo;
	logos: BrandLogo[];
	index: number;
	isAdminMode: boolean | undefined;
	setLogos: (logos: BrandLogo[]) => void;
}

const LogoItem: React.FC<LogoItemProps> = ({ logo, logos, index, isAdminMode, setLogos }) => {
	const logoItemRef = useRef<HTMLDivElement>(null);
	const { isS3Uri, convertS3UriToHttpsCloudFrontUrl } = useImageUtils();
	const logoKeys = Object.keys(LogoTypeMappingEnum) as Array<keyof typeof LogoTypeMappingEnum>;
	const brandImage = logo?.src ?? "";
	const src = isS3Uri(brandImage) ? convertS3UriToHttpsCloudFrontUrl(brandImage) : brandImage;
	const emptyLogo = logo?.src.trim().length === 0;

	const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const newLogo: BrandLogo = {
					...logo,
					name: file.name,
					src: reader.result as string,
					org_id: getSelectedOrganization()?.organization.uid,
					type: `LOGO_${index + 1}` as LogoTypeEnum,
					file,
				};
				const updatedLogos = [...logos];
				if (index !== null) {
					updatedLogos[index] = newLogo;
				} else {
					updatedLogos.push(newLogo);
				}
				setLogos(updatedLogos);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteLogo = () => {
		const updatedLogos = [...logos];
		updatedLogos[index] = {
			...defaultBrandLogos[0],
			id: logo.id,
		};
		setLogos(updatedLogos);
	};

	const renderItemActions = () => {
		if (isAdminMode) {
			return (
				<Box className={common.itemActions}>
					{!emptyLogo && (
						<BriaIconButton className={common.deleteBtn} onClick={() => handleDeleteLogo()}>
							<DeleteIcon />
						</BriaIconButton>
					)}

					<label>
						<input
							type="file"
							accept="image/*"
							className={styles.hiddenInput}
							onChange={(e) => handleLogoUpload(e)}
						/>
						<BriaIconButton className={common.editBtn} component="span">
							<EditIcon />
						</BriaIconButton>
					</label>
				</Box>
			);
		}
	};

	return (
		<Box ref={logoItemRef} key={index} className={clsx(common.listItem, { [common.editable]: isAdminMode })}>
			<Box className={common.itemContent}>
				<Box
					className={clsx(common.itemPreview, {
						[common.emptyStatus]: emptyLogo,
						[styles.logoItemPreviewBackGround]: !emptyLogo,
					})}
				>
					{emptyLogo && <PlusIcon />}
					{!emptyLogo && <img src={src} alt={`${logo?.name}`} />}
				</Box>
				<Typography className={common.itemLabel}>{LogoTypeMappingEnum[logoKeys[index]]}</Typography>
			</Box>
			{renderItemActions()}
		</Box>
	);
};

export default LogoItem;

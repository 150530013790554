import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import styles from "./GuideCardComponent.module.scss";
interface Point {
	title?: string;
	description?: string;
}

interface Card {
	title: string;
	description?: string;
	description2Title?: string;
	description2?: string;
	description3?: string;
	points?: Point[];
}

interface GuideCardComponentProps {
	card: Card;
	lastCard?: boolean;
	firstCard?: boolean;
}
const GuideCardComponent = ({ card, lastCard, firstCard }: GuideCardComponentProps) => {
	return (
		<Box className={styles.cardItem}>
			<Typography
				className={clsx({
					[styles.cardTitle]: true,
					[styles.firstCard]: firstCard,
				})}
			>
				{card.title}
			</Typography>
			{card.description && <Typography className={styles.cardDescription}>{card.description}</Typography>}
			<Typography
				className={clsx({
					[styles.cardDescription2]: true,
					[styles.hide]: !card.description2Title && !card.description2,
				})}
			>
				{card.description2Title && (
					<span className={styles.cardDescription2Title}>{card.description2Title}</span>
				)}
				{card.description2 && <span className={styles.cardDescription2}>{card.description2}</span>}
			</Typography>
			{card.description3 && <Typography className={styles.cardDescription3}>{card.description3}</Typography>}
			{card.points && card.points.length > 0 && (
				<ul className={styles.pointsList}>
					{card.points.map((point: Point, index: number) => (
						<li key={index} className={styles.pointItem}>
							{
								<Typography className={styles.pointText}>
									{point.title && <span className={styles.pointTitle}>{point.title}</span>}
									{point.description && (
										<span className={styles.pointDescription}> {point.description}</span>
									)}
								</Typography>
							}
						</li>
					))}
				</ul>
			)}

			{!lastCard && <Divider className={styles.divider} />}
		</Box>
	);
};
const ObservedComponent = observer(GuideCardComponent);
export default ObservedComponent;

import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { USER_DEFAULT_AVATAR } from "../../../constants/UserConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import styles from "./UserDropdown.module.scss";

const UserDropdown = () => {
	const { authStore, playgroundStore, textToImageStore, uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "userDropdown" });
	const navigate = useSecureNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return iframeStore.isIframe() ? (
		<></>
	) : (
		<>
			{authStore.user && (
				<Box className={styles.userIconConainter}>
					<BriaButton
						onClick={(event) => {
							handleClick(event);
						}}
						aria-label={t("accountOfCurrentUser")}
						className={styles.avatarButton}
						disableRipple
					>
						<Avatar
							src={authStore.user?.profilePicture ?? USER_DEFAULT_AVATAR}
							className={styles.avatarStyle}
						/>
					</BriaButton>
				</Box>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				className={styles.menuWrapper}
				// PaperProps={{
				// 	style: {
				// 		width: 150,
				// 	},
				// }}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						navigate(RouterConstants.PROFILE.fullPath);
					}}
					className={styles.menuItem}
				>
					<Typography variant="inherit">{t("profile")}</Typography>
				</MenuItem>
				{authStore.isLoggedIn && authStore.user?.isSuperAdmin() && (
					<MenuItem
						onClick={() => uiStore.showDialog("SubscriptionLinkGeneratorPopup")}
						className={styles.menuItem}
					>
						<Typography variant="inherit">{t("generateSubscriptionLink")}</Typography>
					</MenuItem>
				)}
				{authStore.isLoggedIn && authStore.user?.isSuperAdmin() && (
					<MenuItem onClick={() => uiStore.showDialog("OrgInfoPopup")} className={styles.menuItem}>
						<Typography variant="inherit">{t("viewOrgInfo")}</Typography>
					</MenuItem>
				)}
				<MenuItem
					className={styles.menuItem}
					onClick={() => {
						authStore.logout().finally(() => {
							playgroundStore.clearResults();
							textToImageStore.clearConfig();
							handleClose();
							const queryParams = new URLSearchParams(location.search);
							const continueUrl = queryParams.get("continueUrl") || undefined;

							if (
								(location.pathname && location.pathname.includes(APPS.AUTOMOTIVE)) ||
								(continueUrl && continueUrl.includes(APPS.AUTOMOTIVE))
							) {
								navigate(`${RouterConstants.LOGIN.path}?continueUrl=automotive`);
							} else if (
								(location.pathname && location.pathname.includes("apps")) ||
								(continueUrl && continueUrl.includes("apps"))
							) {
								if (
									(location.pathname && location.pathname.includes("fox")) ||
									(continueUrl && continueUrl.includes("fox"))
								) {
									navigate(RouterConstants.FOX_APPS.path);
								} else {
									navigate(RouterConstants.APPS.path);
								}
							} else {
								navigate(RouterConstants.LOGIN.path);
							}
						});
					}}
				>
					<Typography variant="inherit">{t("logout")}</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default observer(UserDropdown);

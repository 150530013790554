import { IStaticText } from "../../../types";

export const getTextProperties = (object: Required<IStaticText>, fonts: any[]) => {
	const color = object.fill;
	const family = object.fontFamily;
	const selectedFont = fonts.find((sampleFont) => sampleFont.name === family);
	const hasBold = selectedFont?.name.toLowerCase().includes("bold");
	const hasItalic = selectedFont?.name.toLowerCase().includes("italic");

	const styleOptions = {
		hasBold: !!hasBold,
		hasItalic: !!hasItalic,
		options: selectedFont,
	};
	const bold =
		selectedFont?.name.toLowerCase().includes("bold") ||
		(typeof object?.fontWeight === "number" && object.fontWeight >= 600) ||
		object?.fontWeight === "bold";
	const italic = selectedFont?.name.toLowerCase().includes("italic") || object?.fontStyle === "italic";

	return {
		color,
		family: selectedFont?.name,
		bold: bold,
		italic: italic,
		underline: object.underline,
		linethrough: object.linethrough,
		styleOptions,
	};
};

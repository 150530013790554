import { makeAutoObservable } from "mobx";
import BrandFontsStore, { IBrandFontsStore } from "../DesignEditor/brand-fonts-store.tsx";
import BrandsDefinitionStore, { IBrandsDefinitionStore } from "../DesignEditor/brands-definition-store.tsx";
import CampaignStore, { ICampaignStore } from "../DesignEditor/campaign-store.tsx";
import DesignEditorStore, { IDesignEditorStore } from "../DesignEditor/design-editor-store.tsx";
import ImagesStore, { IImagesStore } from "../DesignEditor/images-store.tsx";
import LogoStore, { ILogoStore } from "../DesignEditor/logo-store.tsx";
import AnalyticsStore, { IAnalyticsStore } from "../analytics-store.tsx";
import PricingStore, { IPricingStore } from "../components/Popups/Pricing/store.tsx";
import AIEditorStore, { IAIEditorStore } from "../pages/AIEditor/ai-editor-store.tsx";
import ObjectsStore, { IObjectsStore } from "../pages/AIEditor/objects-store.tsx";
import AccountStore, { IAccountStore } from "../pages/Account/store.tsx";
import BrandsStore, { IBrandStore } from "../pages/Campaign/Brands/brands-store.tsx";
import ChannelsStore, { IChannelsStore } from "../pages/Campaign/Channels/channels-store.tsx";
import FontsStore, { IFontsStore } from "../pages/Campaign/Fonts/fonts-store.tsx";
import LayoutsStore, { ILayoutsStore } from "../pages/Campaign/Layouts/layouts-store.tsx";
import PlacementsStore, { IPlacementsStore } from "../pages/Campaign/Placements/placements-store.tsx";
import TemplatesStore, { ITemplatesStore } from "../pages/Campaign/Templates/templates-store.tsx";
import { ITgStore, TgStore } from "../pages/NewTG/store/new-tg-stores.tsx";
import ImageToImageStore, { IImageToImageStore } from "../pages/Playground/ImageToImage/image-to-image-store.tsx";
import ProductPlacementStore, {
	IProductPlacementStore,
} from "../pages/Playground/ProductPlacement/product-placement-store.tsx";
import SandboxAPIStore, { ISandboxAPIStore } from "../pages/Playground/SandboxAPI/sandbox-api-store.tsx";
import TextToImageStore, { ITextToImageStore } from "../pages/Playground/TextToImage/text-to-image-store.tsx";
import PlaygroundStore, { IPlaygroundStore } from "../pages/Playground/store/playground-store.tsx";
import TailoredGenerationStore, { ITailoredGenerationStore } from "../pages/TailoredGeneration/store.tsx";
import AuthStore, { IAuthStore } from "../pages/auth/store";
import AppStore, { IAppStore } from "../pages/store";
import UIStore, { IUIStore } from "../ui-store.tsx";
export interface IRootStore {
	appStore: IAppStore;
	authStore: IAuthStore;
	analyticsStore: IAnalyticsStore;
	templatesStore: ITemplatesStore;
	brandsStore: IBrandStore;
	layoutsStore: ILayoutsStore;
	channelsStore: IChannelsStore;
	placementsStore: IPlacementsStore;
	fontsStore: IFontsStore;
	accountStore: IAccountStore;
	tailoredGenerationStore: ITailoredGenerationStore;
	tgStore: ITgStore;
	aiEditorStore: IAIEditorStore;
	objectsStore: IObjectsStore;
	playgroundStore: IPlaygroundStore;
	textToImageStore: ITextToImageStore;
	sandboxAPIStore: ISandboxAPIStore;
	productPlacementStore: IProductPlacementStore;
	imageToImageStore: IImageToImageStore;
	uiStore: IUIStore;
	campaignStore: ICampaignStore;
	imagesStore: IImagesStore;
	brandsDefinitionStore: IBrandsDefinitionStore;
	logoStore: ILogoStore;
	brandFontStore: IBrandFontsStore;
	designEditorStore: IDesignEditorStore;
	pricingStore: IPricingStore;
}

export default class RootStore implements IRootStore {
	appStore = new AppStore(this) as IAppStore;
	authStore = new AuthStore(this) as IAuthStore;
	analyticsStore = new AnalyticsStore(this) as IAnalyticsStore;
	templatesStore = new TemplatesStore() as ITemplatesStore;
	brandsStore = new BrandsStore() as IBrandStore;
	layoutsStore = new LayoutsStore() as ILayoutsStore;
	channelsStore = new ChannelsStore() as IChannelsStore;
	placementsStore = new PlacementsStore() as IPlacementsStore;
	fontsStore = new FontsStore() as IFontsStore;
	accountStore = new AccountStore() as IAccountStore;
	tailoredGenerationStore = new TailoredGenerationStore(this) as ITailoredGenerationStore;
	tgStore = new TgStore(this) as ITgStore;
	playgroundStore = new PlaygroundStore(this) as IPlaygroundStore;
	textToImageStore = new TextToImageStore(this) as ITextToImageStore;
	productPlacementStore = new ProductPlacementStore(this) as IProductPlacementStore;
	sandboxAPIStore = new SandboxAPIStore(this) as ISandboxAPIStore;
	imageToImageStore = new ImageToImageStore(this) as IImageToImageStore;
	aiEditorStore = new AIEditorStore() as IAIEditorStore;
	objectsStore = new ObjectsStore(this) as IObjectsStore;
	uiStore = new UIStore(this) as IUIStore;
	campaignStore = new CampaignStore(this) as ICampaignStore;
	brandsDefinitionStore = new BrandsDefinitionStore() as IBrandsDefinitionStore;
	imagesStore = new ImagesStore(this) as IImagesStore;
	logoStore = new LogoStore() as ILogoStore;
	brandFontStore = new BrandFontsStore() as IBrandFontsStore;
	designEditorStore = new DesignEditorStore(this) as IDesignEditorStore;
	pricingStore = new PricingStore(this) as IPricingStore;

	constructor() {
		makeAutoObservable(this);
	}
}

import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollPosition, trackWindowScroll } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FeedbackButtons from "../../../../../../components/common/FeedbackButtons/FeedbackButtons.tsx";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../../components/common/FullScreenViewer/FullScreenViewer";
import BriaImage from "../../../../../../components/common/Galleries/BriaImage";
import { useAppStore } from "../../../../../../hooks/useStores";
import styles from "./TgModelPlaygroundGallery.module.scss";

type TgModelPlaygroundGalleryProps = {
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition: ScrollPosition;
};

const TgModelPlaygroundGallery = ({
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
}: TgModelPlaygroundGalleryProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.models.viewer.playground" });
	const { tgStore } = useAppStore();
	const { tgPlaygroundStore } = tgStore;
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setImageToView(0);
	}, [tgPlaygroundStore.playgroundImages.length]);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	return (
		<Box className={styles.playground}>
			{!tgPlaygroundStore.playgroundImages.length ? (
				<Box className={styles.emptyState}>
					<Typography className={styles.text}>{t("emptyState")}</Typography>
				</Box>
			) : (
				<Box
					className={clsx(className, styles.resultsGallery)}
					sx={{
						"&  > span": { width: `calc( ${100 / maxInLine}% - 0.8vw)` },
					}}
				>
					{tgPlaygroundStore.playgroundImages.map((image, index) => (
						<BriaImage
							ImageOverlay={
								<FeedbackButtons
									{...{
										image,
										appName: "T.G.",
										hideAiEditorButton: true,
										projectName: tgStore?.tgProjectStore?.projectToView?.project_name,
										projectId: tgStore?.tgProjectStore?.projectToView?.id?.toString(),
										modelName: tgStore?.tgModelStore?.modelToView?.name,
										modelId: tgStore?.tgModelStore?.modelToView?.id?.toString(),
									}}
								/>
							}
							key={index}
							{...image}
							image={{ ...image } as any}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{ scrollPosition: scrollPosition }}
							async
							onSuccessPulling={() => (image.loading = false)}
							onErrorPulling={() => (image.loading = false)}
						/>
					))}
				</Box>
			)}
			<>
				{isViewerOpen && (
					<FullScreenViewer
						{...fullScreenProps}
						open={isViewerOpen}
						images={tgPlaygroundStore.playgroundImages.map((image) => ({ src: image.url }))}
						currentIndex={imageToView}
						onClose={handleCloseFullScreen}
						displayMode={displayMode}
						hideDownloadIcon
					/>
				)}
			</>
		</Box>
	);
};

export default trackWindowScroll(observer(TgModelPlaygroundGallery));

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./InvalidSubscriptionBanner.module.scss";

const InvalidSubscriptionBanner = () => {
	const { t } = useTranslation("translation", { keyPrefix: "invalidSubscriptionBanner" });
	const { authStore, pricingStore, uiStore } = useAppStore();
	console.log("uiStore.backButton", uiStore.backButton);
	return (
		!iframeStore.isIframe() &&
		!authStore.isLoadingOrgSubscriptions &&
		authStore.isSubscriptionInactive &&
		uiStore.InvalidSubscriptionBanner && (
			<Box className={styles.container}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("title")}</Typography>
				</Box>
				<BriaButton
					buttonType={"secondaryMedium"}
					onClick={() => {
						pricingStore.openPricingFlow();
					}}
				>
					{t("buttonText")}
				</BriaButton>
			</Box>
		)
	);
};

export default observer(InvalidSubscriptionBanner);

import { observer } from "mobx-react-lite";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import ThemeModeDropdown from "../../../components/common/ThemeModeDropdown/ThemeModeDropdown.tsx";
import UserDropdown from "../../../components/common/UserDropdown/UserDropdown.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { isBriaPublicOrg } from "../../../utils/index.ts";
import HeaderLayout from "../HeaderLayout.tsx";
import styles from "./AutomotiveHeader.module.scss";

const AutomotiveHeader = () => {
	const handleBriaLogoClick = () => {
		location.replace(RouterConstants.AUTOMOTIVE.path);
	};
	return (
		<HeaderLayout className={styles.header} onLogoClick={handleBriaLogoClick}>
			<OrganizationsDropdown labelFontSize="14px" />
			<UserDropdown />
			{isBriaPublicOrg() && <ThemeModeDropdown />}
		</HeaderLayout>
	);
};

export default observer(AutomotiveHeader);

import { makeAutoObservable, runInAction } from "mobx";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";
import { fileDownload } from "../../../../helpers/files.ts";
import { getSelectedOrganization } from "../../../../helpers/localStorage.ts";
import { OrgFeatures } from "../../../../models/billing.ts";
import { EventLogTypesEnum, PaginatedItems } from "../../../../models/common.ts";
import QueryService from "../../../../utils/QueryService";
import iframeStore from "../../../IframeNew/iframe-store.tsx";
import { ITgStore } from "../../store/new-tg-stores";

export interface ITgModelStore {
	modelsList: TgModel[];
	modelForm: TgModel;
	formErrors: { save?: boolean };
	isDatasetFromExisting: boolean;
	modelToView: TgModel;

	loadingModel: boolean;
	loadingModelsList: boolean;
	modelsListError: boolean;
	loadingCreateModel: boolean;
	loadingUpdateModel: boolean;
	loadingDeleteModel: boolean;
	loadingDuplicateModel: boolean;
	loadingStartTraining: boolean;
	loadingStopTraining: boolean;
	downloadingModel: boolean;

	handleFormChange: <K extends keyof TgModel>(key: K, value: TgModel[K]) => void;
	getModel: (id: number) => Promise<TgModel>;
	createModel: (modelToCreate: TgModel) => Promise<TgModel>;
	updateModel: (id: number, updatedModel: TgModel) => Promise<TgModel>;
	deleteModel: (id: number) => Promise<void>;
	duplicateModel: (id: number) => Promise<TgModel>;

	startTraining: (id: number) => Promise<void>;
	stopTraining: (id: number) => Promise<void>;
	listAllModels: (status?: TgModelStatus) => Promise<TgModel[]>;
	downloadModel: (id: number) => Promise<void>;
	getBaseModel: (id: number) => Promise<string>;
}

export class TgModelStore implements ITgModelStore {
	private queryService: QueryService = new QueryService("/new-tailored-generation/models");
	tgStore: ITgStore;

	modelForm: TgModel = defaultModel;
	modelsList: TgModel[] = [];
	formErrors: { save?: boolean; invalidName?: boolean } = {};
	isDatasetFromExisting: boolean = false;
	modelToView: TgModel = defaultModel;

	loadingProjectModels: boolean = false;
	loadingModel: boolean = false;
	loadingModelsList: boolean = false;
	loadingCreateModel: boolean = false;
	loadingUpdateModel: boolean = false;
	loadingDeleteModel: boolean = false;
	loadingDuplicateModel: boolean = false;
	loadingStartTraining: boolean = false;
	loadingStopTraining: boolean = false;
	modelsListError: boolean = false;
	downloadingModel: boolean = false;

	constructor(tgStore: ITgStore) {
		makeAutoObservable(this);
		this.tgStore = tgStore;
	}

	handleFormChange = <K extends keyof TgModel>(key: K, value: TgModel[K]) => {
		this.modelForm = { ...this.modelForm, [key]: value };
	};

	getModel = async (id: number) => {
		try {
			this.loadingModel = true;
			const model = await this.queryService.get(`/${id}`);

			runInAction(() => {
				this.loadingModel = false;
			});

			return model;
		} catch (err: any) {
			this.loadingModel = false;
			return Promise.reject(err);
		}
	};

	createModel = async (modelToCreate: TgModel): Promise<TgModel> => {
		try {
			this.loadingCreateModel = true;
			const newModel = await this.queryService.post(`/`, modelToCreate);

			runInAction(() => {
				this.loadingCreateModel = false;
			});

			return newModel;
		} catch (err: any) {
			this.loadingCreateModel = false;
			return Promise.reject(err);
		}
	};

	updateModel = async (id: number, modelToUpdate: TgModel): Promise<TgModel> => {
		try {
			this.loadingUpdateModel = true;
			const updatedModel = await this.queryService.put(`/${id}`, modelToUpdate);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedModels.items =
					this.tgStore.tgProjectStore.paginatedModels?.items.map((model) =>
						model.id === id ? updatedModel : model,
					);
				this.loadingUpdateModel = false;
			});

			return updatedModel;
		} catch (err: any) {
			this.loadingUpdateModel = false;
			return Promise.reject(err);
		}
	};

	deleteModel = async (id: number) => {
		try {
			this.loadingDeleteModel = true;
			await this.queryService.delete(`/${id}`);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedModels = {
					items: this.tgStore.tgProjectStore.paginatedModels.items.filter((model) => model.id !== id),
					total: this.tgStore.tgProjectStore.paginatedModels.total - 1,
				};
				this.loadingDeleteModel = false;
			});
		} catch (err: any) {
			this.loadingDeleteModel = false;
			return Promise.reject(err);
		}
	};

	duplicateModel = async (id: number): Promise<TgModel> => {
		try {
			this.loadingDuplicateModel = true;
			const newModel = await this.queryService.post(`/${id}/duplicate`);

			runInAction(() => {
				this.loadingDuplicateModel = false;
			});

			return newModel;
		} catch (err: any) {
			this.loadingDuplicateModel = false;
			return Promise.reject(err);
		}
	};

	startTraining = async (id: number): Promise<void> => {
		try {
			this.loadingStartTraining = true;
			await this.queryService.post(`/${id}/start_training`);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedModels.items =
					this.tgStore.tgProjectStore.paginatedModels.items.map((model) =>
						model.id === id ? { ...model, status: "InProgress" } : model,
					);
				this.loadingStartTraining = false;
			});
		} catch (err: any) {
			this.loadingStartTraining = false;
			return Promise.reject(err);
		}
	};

	stopTraining = async (id: number): Promise<void> => {
		try {
			this.loadingStopTraining = true;
			await this.queryService.post(`/${id}/stop_training`);

			runInAction(() => {
				this.tgStore.tgProjectStore.paginatedModels.items =
					this.tgStore.tgProjectStore.paginatedModels.items.map((model) =>
						model.id === id ? { ...model, status: "Stopping" } : model,
					);
				this.loadingStopTraining = false;
			});
		} catch (err: any) {
			this.loadingStopTraining = false;
			return Promise.reject(err);
		}
	};

	listAllModels = async (status?: TgModelStatus): Promise<TgModel[]> => {
		try {
			this.loadingModelsList = true;
			this.modelsListError = false;

			let filteredModels: TgModel[] = [];
			if (getSelectedOrganization()?.organization.uid) {
				if (
					!iframeStore.isIframe() ||
					this.tgStore.rootStore.authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)
				) {
					const iframeTgModels = iframeStore.iframe?.config.image_generation_config?.enabled_tg_models ?? [];
					if (!iframeStore.isIframe() || iframeTgModels.length) {
						const resp: PaginatedItems<TgModel> = await this.queryService.get(`/`, {
							params: status ? { status } : {},
						});
						filteredModels = resp.items;
						if (iframeStore.isIframe()) {
							filteredModels = filteredModels.filter((model: TgModel) =>
								iframeTgModels.includes(model.id.toString()),
							);
						}
					}
				}
			}

			runInAction(() => {
				this.modelsList = filteredModels;
				this.loadingModelsList = false;
			});

			return this.modelsList;
		} catch (err: any) {
			this.loadingModelsList = false;
			this.modelsListError = true;
			return Promise.reject(err);
		}
	};

	downloadModel = async (id: number): Promise<void> => {
		try {
			runInAction(() => {
				this.downloadingModel = true;
			});

			const response = await this.queryService.get(`/${id}/download`);
			fileDownload(response.download_url, response.base_model_name);
			this.tgStore.rootStore.appStore.logEvent({ eventType: EventLogTypesEnum.DOWNLOAD_TG_MODEL_SOURCE });
			this.tgStore.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.DOWNLOAD_TG_MODEL_SOURCE);
		} catch (err: any) {
			this.downloadingModel = false;
			return Promise.reject(err);
		} finally {
			runInAction(() => {
				this.downloadingModel = false;
			});
		}
	};

	getBaseModel = async (id: number): Promise<string> => {
		try {
			const response = await this.queryService.get(`/${id}/download`);
			return response.base_model_card;
		} catch (err: any) {
			return Promise.reject(err);
		}
	};
}

export type TgModel = {
	id: number;
	name: string;
	description: string;
	training_method: TgModelTrainingMethod;
	training_version: TgTrainingVersion;
	generation_prefix: string;
	status: TgModelStatus;
	project_id: number;
	dataset_id: number;
	created_at: string;
	updated_at: string;
};

export type TgModelTrainingMethod = "automatic_training" | "instant_training";
export type TgTrainingVersion = "light" | "max";
export type TgModelStatus = "Created" | "InProgress" | "Completed" | "Failed" | "Stopping" | "Stopped" | "Deleted";

export const defaultModel: TgModel = {
	id: NaN,
	name: "",
	description: "",
} as TgModel;

import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor";
import Selectable from "../../../components/common/Selectable/Selectable";
import { APPS } from "../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import { ImageEditingViewMode } from "../../../models/image-to-image.ts";
import { isAutomotiveApp } from "../../../utils/index.ts";
import { showErrorToast } from "../../../utils/toast";
import PlaygroundActionsHeader from "../ActionsHeader/PlaygroundActionsHeader";
import ImageToImageResult from "../ImageToImage/Results/ImageToImageResult";
import NewCollectionResult from "../ImageToImage/Results/New/NewCollectionResult";
import UploadedOrSavedResult from "../ImageToImage/Results/Uploaded/UploadedOrSavedResult";
import SingleImageToImage from "../ImageToImage/SingleImageToImage/SingleImageToImage.tsx";
import ProductPlacementResult from "../ProductPlacement/Generator/ProductPlacementResult";
import SandboxAPIResult from "../SandboxAPI/Generator/SandboxAPIResult";
import TextToImageSearch from "../TextToImage/Generator/Search/TextToImageSearch";
import TextToImageResult from "../TextToImage/Generator/TextToImageResult";
import EmptyPlayground from "./EmptyState/EmptyPlayground";
import styles from "./PlaygroundImages.module.scss";

const PlaygroundImages = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	const { playgroundStore, imageToImageStore, sandboxAPIStore } = useAppStore();
	const resultsRef = useRef<HTMLDivElement>(null);
	const { isPopupView } = useContext(DesignEditorContext);
	const [lastCountResults, setLastCountResult] = useState(playgroundStore.playgroundResults.length);
	const isSingleMode = playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE;
	useEffect(() => {
		if (playgroundStore.playgroundResults.length > lastCountResults) {
			debouncedScrollBottom();
		}
		setLastCountResult(playgroundStore.playgroundResults.length);
	}, [playgroundStore.playgroundResults.length]);

	useEffect(() => {
		debouncedScrollBottom();
	}, [playgroundStore.imageEditingViewMode]);

	const scrollBottom = () => {
		if (resultsRef.current) {
			resultsRef.current.scrollTo({ behavior: "smooth", top: resultsRef.current.scrollHeight });
		}
	};

	const debouncedScrollBottom = useCallback(() => {
		const timeoutId = setTimeout(scrollBottom, 100);
		return () => clearTimeout(timeoutId);
	}, []);

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const droppedFiles = e.dataTransfer.files;
		if (droppedFiles.length > 0) {
			const files = Array.from(droppedFiles);
			if (playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE) {
				if (files.length > imageToImageStore.MAX_FILES_LIMIT) {
					showErrorToast(t("imageToImage.filesExceedLimit"));
					return;
				}
				imageToImageStore.handleUploadImages({ target: { files: droppedFiles }, value: "" } as any);
			} else if (playgroundStore.selectedConfig === APPS.SANDBOX_API) {
				if (files.length > sandboxAPIStore.MAX_FILES_LIMIT) {
					showErrorToast(t("sandboxAPI.filesExceedLimit"));
					return;
				}
				sandboxAPIStore.handleUploadImages({ target: { files: droppedFiles }, value: "" } as any);
				playgroundStore.closeUploadModal();
			}
		}
	};

	return (
		<Box className={styles.root}>
			<PlaygroundActionsHeader />
			{playgroundStore.selectedConfig === APPS.PRODUCT_PLACEMENT ? (
				<Box className={styles.results}>
					{playgroundStore.playgroundResults.map((result, index) => (
						<ProductPlacementResult key={index} playgroundResult={result} rowIndex={index} />
					))}
				</Box>
			) : playgroundStore.selectedConfig === APPS.SANDBOX_API ? (
				<Box
					ref={resultsRef}
					className={`results ${styles.results} ${styles.sandboxAPIresults}`}
					onDrop={handleDrop}
					onDragOver={(e) => e.preventDefault()}
				>
					<>
						{!playgroundStore.playgroundResults.length ? (
							<EmptyPlayground />
						) : (
							playgroundStore.playgroundResults.map(
								(result, index) =>
									result.type === APPS.SANDBOX_API && (
										<SandboxAPIResult
											key={index}
											playgroundResult={result}
											scrollBottom={scrollBottom}
										/>
									),
							)
						)}
					</>
				</Box>
			) : (
				<Selectable
					selectoRef={playgroundStore.selectoRef as any}
					selectableTargets={[".selectableImage"]}
					onSelect={(e) => playgroundStore.handleSelectImagesElements(e.selected, isPopupView)}
					innerScrollRef={resultsRef}
					className={styles.selectable}
					type={isSingleMode ? "single" : "multi"}
				>
					<Box
						ref={resultsRef}
						className={isSingleMode ? styles.singleResult : `results ${styles.results}`}
						onDrop={handleDrop}
						onDragOver={(e) => e.preventDefault()}
					>
						<>
							{!playgroundStore.playgroundResults.length ? (
								<EmptyPlayground
									hideSubHeader={isAutomotiveApp()}
									header={isAutomotiveApp() ? t("automotive.uploadVehicleImages") : undefined}
								/>
							) : isSingleMode ? (
								<SingleImageToImage
									swiperClassName={clsx({
										[styles.testToImageSwiper]:
											playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE,
									})}
								/>
							) : (
								playgroundStore.playgroundResults.map((result, index) =>
									result.type === APPS.TEXT_TO_IMAGE ? (
										<TextToImageResult key={index} playgroundResult={result} />
									) : result.type === APPS.IMAGE_TO_IMAGE ? (
										<ImageToImageResult key={index} playgroundResult={result} rowIndex={index} />
									) : result.type === "upload" || result.type === "save" ? (
										<UploadedOrSavedResult
											key={index}
											playgroundResult={result}
											label={result.type}
											rowIndex={index}
										/>
									) : (
										result.type === "new" && (
											<NewCollectionResult key={index} playgroundResult={result} />
										)
									),
								)
							)}
						</>
					</Box>
					{playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE && <TextToImageSearch />}
				</Selectable>
			)}
		</Box>
	);
};

export default observer(PlaygroundImages);

import { useTranslation } from "react-i18next";
import { PanelType } from "../../../../constants/app-options";

export const useGuideItems = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplatesGuideSection" });

	return [
		{
			number: 1,
			title: t("adSize.cards.card1.title"),
			cards: [
				{
					title: t("adSize.cards.card1.title"),
					description: t("adSize.cards.card1.description"),
				},
				{
					title: t("adSize.cards.card2.title"),
					description: t("adSize.cards.card2.description"),
				},
				{
					title: t("adSize.cards.card3.title"),
					description: t("adSize.cards.card3.description"),
				},
				{
					title: t("adSize.cards.card4.title"),
					description: t("adSize.cards.card4.description"),
				},
			],
			tooltipTitle: t("adSize.tooltip.title"),
			tooltipDescription: t("adSize.tooltip.description"),
			panel: PanelType.RESIZE,
		},
		{
			number: 2,
			title: t("smartImage.cards.card1.title"),
			cards: [
				{
					title: t("smartImage.cards.card1.title"),
					description: t("smartImage.cards.card1.description"),
				},
				{
					title: t("smartImage.cards.card2.title"),
					points: [
						{
							title: t("smartImage.cards.card2.points.point1.title"),
							description: t("smartImage.cards.card2.points.point1.description"),
						},
						{
							title: t("smartImage.cards.card2.points.point2.title"),
							description: t("smartImage.cards.card2.points.point2.description"),
						},
					],
				},
				{
					title: t("smartImage.cards.card3.title"),
					points: [
						{
							title: t("smartImage.cards.card3.points.point1.title"),
							description: t("smartImage.cards.card3.points.point1.description"),
						},
						{
							title: t("smartImage.cards.card3.points.point2.title"),
							description: t("smartImage.cards.card3.points.point2.description"),
						},
						{
							title: t("smartImage.cards.card3.points.point3.title"),
							description: t("smartImage.cards.card3.points.point3.description"),
						},
					],
				},
				{
					title: t("smartImage.cards.card4.title"),
					description: t("smartImage.cards.card4.description"),
				},
			],
			tooltipTitle: t("smartImage.tooltip.title"),
			tooltipDescription: t("smartImage.tooltip.description"),
			panel: PanelType.IMAGES,
		},
		{
			number: 3,
			title: t("graphicElements.title"),
			cards: [
				{
					title: t("graphicElements.cards.card1.title"),
					description: t("graphicElements.cards.card1.description"),
				},
				{
					title: t("graphicElements.cards.card2.title"),
					description: t("graphicElements.cards.card2.description"),
					points: [
						{
							title: t("graphicElements.cards.card2.points.point1.title"),
							description: t("graphicElements.cards.card2.points.point1.description"),
						},
					],
				},
				{
					title: t("graphicElements.cards.card3.title"),
					description: t("graphicElements.cards.card3.description"),
					points: [
						{
							title: t("graphicElements.cards.card3.points.point1.title"),
							description: t("graphicElements.cards.card3.points.point1.description"),
						},
						{
							title: t("graphicElements.cards.card3.points.point2.title"),
							description: t("graphicElements.cards.card3.points.point2.description"),
						},
					],
				},
				{
					title: t("graphicElements.cards.card4.title"),
					description: t("graphicElements.cards.card4.description"),
				},
			],
			tooltipTitle: t("graphicElements.tooltip.title"),
			tooltipDescription: t("graphicElements.tooltip.description"),
			panel: PanelType.GRAPHICS,
		},
		{
			number: 4,
			title: t("textElements.title"),
			cards: [
				{
					title: t("textElements.cards.card1.title"),
					description: t("textElements.cards.card1.description"),
				},
				{
					title: t("textElements.cards.card2.title"),
					points: [
						{
							description: t("textElements.cards.card2.points.point1.description"),
						},
						{
							description: t("textElements.cards.card2.points.point2.description"),
						},
						{
							description: t("textElements.cards.card2.points.point3.description"),
						},
					],
				},
				{
					title: t("textElements.cards.card3.title"),
					description: t("textElements.cards.card3.description"),
					description2: t("textElements.cards.card3.description2"),
				},
				{
					title: t("textElements.cards.card4.title"),
					description: t("textElements.cards.card4.description"),
				},
				{
					title: t("textElements.cards.card5.title"),
					description: t("textElements.cards.card5.description"),
				},
			],
			tooltipTitle: t("textElements.tooltip.title"),
			tooltipDescription: t("textElements.tooltip.description"),
			panel: PanelType.TEXT,
		},
		{
			number: 5,
			title: t("imagesAndLogos.title"),
			cards: [
				{
					title: t("imagesAndLogos.cards.card1.title"),
					description: t("imagesAndLogos.cards.card1.description"),
				},
				{
					title: t("imagesAndLogos.cards.card2.title"),
					points: [
						{
							description: t("imagesAndLogos.cards.card2.points.point1.description"),
						},
						{
							description: t("imagesAndLogos.cards.card2.points.point2.description"),
						},
					],
				},
				{
					title: t("imagesAndLogos.cards.card3.title"),
					points: [
						{
							description: t("imagesAndLogos.cards.card3.points.point1.description"),
						},
						{
							description: t("imagesAndLogos.cards.card3.points.point2.description"),
						},
						{
							description: t("imagesAndLogos.cards.card3.points.point3.description"),
						},
					],
				},
				{
					title: t("imagesAndLogos.cards.card4.title"),
					description: t("imagesAndLogos.cards.card4.description"),
				},
			],
			tooltipTitle: t("imagesAndLogos.tooltip.title"),
			tooltipDescription: t("imagesAndLogos.tooltip.description"),
			panel: PanelType.IMAGES,
		},
		{
			number: 6,
			title: t("duplicateAndResizeScene.title"),
			cards: [
				{
					title: t("duplicateAndResizeScene.cards.card1.title"),
					description: t("duplicateAndResizeScene.cards.card1.description"),
				},
				{
					title: t("duplicateAndResizeScene.cards.card2.title"),
					description: t("duplicateAndResizeScene.cards.card2.description"),
					description2Title: t("duplicateAndResizeScene.cards.card2.description2Title"),
					description2: t("duplicateAndResizeScene.cards.card2.description2"),
				},
				{
					title: t("duplicateAndResizeScene.cards.card3.title"),
					points: [
						{
							description: t("duplicateAndResizeScene.cards.card3.points.point1.description"),
						},
					],
				},
			],
			tooltipTitle: t("duplicateAndResizeScene.tooltip.title"),
			tooltipDescription: t("duplicateAndResizeScene.tooltip.description"),
		},
		{
			number: 7,
			title: t("testTemplate.title"),
			cards: [
				{
					title: t("testTemplate.cards.card1.title"),
					description: t("testTemplate.cards.card1.description"),
				},
				{
					title: t("testTemplate.cards.card2.title"),
					points: [
						{
							title: t("testTemplate.cards.card2.points.point1.title"),
							description: t("testTemplate.cards.card2.points.point1.description"),
						},
						{
							title: t("testTemplate.cards.card2.points.point2.title"),
							description: t("testTemplate.cards.card2.points.point2.description"),
						},
						{
							title: t("testTemplate.cards.card2.points.point3.title"),
							description: t("testTemplate.cards.card2.points.point3.description"),
						},
					],
				},
				{
					title: t("testTemplate.cards.card3.title"),
					points: [
						{
							description: t("testTemplate.cards.card3.points.point1.description"),
						},
						{
							description: t("testTemplate.cards.card3.points.point2.description"),
						},
						{
							description: t("testTemplate.cards.card3.points.point3.description"),
						},
					],
				},
				{
					title: t("testTemplate.cards.card4.title"),
					points: [
						{
							description: t("testTemplate.cards.card4.points.point1.description"),
						},
						{
							description: t("testTemplate.cards.card4.points.point2.description"),
						},
						{
							description: t("testTemplate.cards.card4.points.point3.description"),
						},
						{
							description: t("testTemplate.cards.card4.points.point4.description"),
						},
					],
				},
			],
		},
		{
			number: 8,
			title: t("brandCreationGuide.cards.card1.title"),
			cards: [
				{
					title: t("brandCreationGuide.cards.card1.title"),
					description: t("brandCreationGuide.cards.card1.description"),
					description2Title: t("brandCreationGuide.cards.card1.description2Title"),
					description2: t("brandCreationGuide.cards.card1.description2"),
					description3: t("brandCreationGuide.cards.card1.description3"),
				},
				{
					title: t("brandCreationGuide.cards.card2.title"),
					description: t("brandCreationGuide.cards.card2.description"),
					points: [
						{
							title: t("brandCreationGuide.cards.card2.points.point1.title"),
							description: t("brandCreationGuide.cards.card2.points.point1.description"),
						},
						{
							title: t("brandCreationGuide.cards.card2.points.point2.title"),
							description: t("brandCreationGuide.cards.card2.points.point2.description"),
						},
					],
				},
				{
					title: t("brandCreationGuide.cards.card3.title"),
					description: t("brandCreationGuide.cards.card3.description"),
					points: [
						{
							title: t("brandCreationGuide.cards.card3.points.point1.title"),
							description: t("brandCreationGuide.cards.card3.points.point1.description"),
						},
						{
							title: t("brandCreationGuide.cards.card3.points.point2.title"),
							description: t("brandCreationGuide.cards.card3.points.point2.description"),
						},
						{
							title: t("brandCreationGuide.cards.card3.points.point3.title"),
							description: t("brandCreationGuide.cards.card3.points.point3.description"),
						},
						{
							title: t("brandCreationGuide.cards.card3.points.point4.title"),
							description: t("brandCreationGuide.cards.card3.points.point4.description"),
						},
					],
				},
				{
					title: t("brandCreationGuide.cards.card4.title"),
					description: t("brandCreationGuide.cards.card4.description"),
					points: [
						{
							title: t("brandCreationGuide.cards.card4.points.point1.title"),
							description: t("brandCreationGuide.cards.card4.points.point1.description"),
						},
						{
							title: t("brandCreationGuide.cards.card4.points.point2.title"),
							description: t("brandCreationGuide.cards.card4.points.point2.description"),
						},
						{
							title: t("brandCreationGuide.cards.card4.points.point3.title"),
							description: t("brandCreationGuide.cards.card4.points.point3.description"),
						},
					],
				},
			],
		},
	];
};

import { useAppStore } from "../../../../../hooks/useStores.tsx";
import useAppContext from "../../../../hooks/useAppContext.tsx";
import { useGuideItems } from "./TemplatesTourPagesData.tsx";

type TemplatesUtils = {
	handleForward: () => void;
	handleBack: () => void;
	handleOpenGuide: (index: number) => void;
	getSpecificTemplateTourPageIndex: (title: string) => number;
};

const useGuideTemplatesUtils = (): TemplatesUtils => {
	const { uiStore } = useAppStore();
	const guideItems = useGuideItems();
	const { setActivePanel } = useAppContext();

	const handleBack = () => {
		if (uiStore.selectedGuideIndex > 0) {
			uiStore.setProperty("selectedGuideIndex", uiStore.selectedGuideIndex - 1);
			uiStore.setProperty("showGuideToolTip", true);
			const panel = guideItems[uiStore.selectedGuideIndex - 1]?.panel;
			if (panel) {
				setActivePanel(panel);
			}
		}
	};

	const handleForward = () => {
		if (uiStore.selectedGuideIndex < guideItems.length) {
			uiStore.setProperty("selectedGuideIndex", uiStore.selectedGuideIndex + 1);
			uiStore.setProperty("showGuideToolTip", true);
			const panel = guideItems[uiStore.selectedGuideIndex - 1]?.panel;
			if (panel) {
				setActivePanel(panel);
			}
		}
	};

	const handleOpenGuide = (index: number) => {
		uiStore.setProperty("showGuideSection", true);
		uiStore.setProperty("selectedGuideIndex", index);
		uiStore.setProperty("showGuideToolTip", true);
		const panel = guideItems[index - 1]?.panel;
		if (panel !== undefined) {
			setActivePanel(panel);
		}
	};

	const getSpecificTemplateTourPageIndex = (title: string) => {
		const guideItemIndexMap = guideItems.reduce((acc: any, item, index) => {
			acc[item.title] = index;
			return acc;
		}, {});
		const duplicateAndResizeIndex = guideItemIndexMap[title] + 1;
		return duplicateAndResizeIndex;
	};

	return {
		handleBack,
		handleForward,
		handleOpenGuide,
		getSpecificTemplateTourPageIndex,
	};
};

export default useGuideTemplatesUtils;

import { Replay } from "@mui/icons-material";
import { Alert, Collapse } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../../assets/images/icons/DeleteIcon";
import BriaButtonGroup from "../../../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaIconButton from "../../../../../../components/common/BriaIconButton/BriaIconButton";
import FileUploader from "../../../../../../components/common/FileUploader/FileUploader";
import { useAppStore } from "../../../../../../hooks/useStores";
import { TgDatasetImage } from "../../../store/tg-dataset-store";
import styles from "./TgDatasetImageCardError.module.scss";
type Props = {
	image: TgDatasetImage;
};

const TgDatasetImageCardError = ({ image }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "newTg.datasets.editor.form.images.error" });
	const { tgStore } = useAppStore();
	const { tgDatasetStore } = tgStore;

	const uploadNewImage = async (file: File) => {
		await tgDatasetStore.uploadImage(tgDatasetStore.datasetForm.id, file, image.id);
	};

	const replaceImage = async (e: ChangeEvent<HTMLInputElement>) => {
		const file: File = Array.from(e.target.files || [])[0];
		e.target.value = "";
		if (file) {
			await uploadNewImage(file);
		}
	};

	const deleteFailedImage = () => {
		tgDatasetStore.datasetForm.images = tgDatasetStore.datasetForm.images.filter((img) => img.id !== image.id);
	};

	const retryUpload = async () => {
		image.file && (await uploadNewImage(image.file));
	};

	return (
		<Collapse in={!!image.error}>
			{image.error?.status && image.error.status >= 500 && image.error.status < 600 ? (
				<Alert
					className={styles.warning}
					severity="info"
					color="error"
					action={
						<BriaButtonGroup className={styles.serverErrors}>
							<BriaIconButton
								className={styles.imageIconButton}
								title={t("retry")}
								onClick={retryUpload}
								disabled={tgDatasetStore.loadingUploadImages}
							>
								<Replay />
							</BriaIconButton>
							<BriaIconButton
								className={styles.imageIconButton}
								title={t("delete")}
								onClick={deleteFailedImage}
							>
								<DeleteIcon />
							</BriaIconButton>
						</BriaButtonGroup>
					}
				>
					{t("uploadFailed")}
				</Alert>
			) : (
				<Alert
					className={styles.warning}
					severity="info"
					color="error"
					action={
						<BriaButtonGroup spacing={0.5}>
							<FileUploader
								buttonClassName={styles.errorButton}
								buttonText={t("replace")}
								buttonType="textSmall"
								onUpload={replaceImage}
								maxFilesLimit={200}
								inputProps={{ accept: "image/png, image/jpg, image/jpeg, image/webp" }}
							/>
							<BriaIconButton
								className={styles.imageIconButton}
								title={t("delete")}
								onClick={deleteFailedImage}
							>
								<DeleteIcon />
							</BriaIconButton>
						</BriaButtonGroup>
					}
				>
					{image.error?.message}
				</Alert>
			)}
		</Collapse>
	);
};

export default observer(TgDatasetImageCardError);

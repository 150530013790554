import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollPosition, trackWindowScroll } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ANALYTICS_EVENTS } from "../../../../../analytics-store.tsx";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../components/common/FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../../../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import BriaImage, { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../models/image-to-image";
import { isFoxApps } from "../../../../../utils";
import styles from "./ITIGallery.module.scss";

type ITIGalleryProps = {
	images: (BriaImageProps & PlaygroundImage)[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition: ScrollPosition;
};

const ITIGallery = ({ images, maxInLine = 4, className, fullScreenProps, scrollPosition }: ITIGalleryProps) => {
	const { analyticsStore, playgroundStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.result.gallery" });
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setImageToView(0);
	}, [images.length]);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	return (
		<>
			<Box
				className={clsx(className, styles.itiGallery)}
				sx={{
					"&  > span": {
						width: `calc( ${100 / maxInLine}% - 0.8vw)`,
					},
				}}
				key={images.map((img) => img.url).join("-")}
			>
				{images.map((image, index) => (
					<span className={styles.imageWrapper}>
						<BriaImage
							key={index}
							{...image}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{ ...image.lazyImageProps, scrollPosition: scrollPosition }}
							fullScreenProps={image.fullScreenProps}
							image={image}
							hideDownloadIcon={false}
						/>
						{isFoxApps() && (
							<Box className={styles.variationLabel}>
								{t("variationLabel", { variationNum: image.variationNum?.toString().padStart(2, "0") })}
							</Box>
						)}
					</span>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
					onDownload={(fullscreenImages: FullScreenImageType[]) => {
						playgroundStore.onFullScreenViewerDownload(images, fullscreenImages);
					}}
					onCompare={(fullscreenImages: FullScreenImageType[]) => {
						fullscreenImages.forEach((fullscreenImage) => {
							const itiImage = images.find((img) => img.url === fullscreenImage.src);
							itiImage &&
								analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_COMPARE_ITI, itiImage);
						});
					}}
				/>
			)}
		</>
	);
};

export default trackWindowScroll(observer(ITIGallery));

import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, CircularProgress, Dialog, FormControlLabel, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import SubmittedFeedbackSVG from "../../../assets/images/svgs/SubmittedFeedback.svg";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaCheckbox from "../../../components/common/BriaCheckbox/BriaCheckbox";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import { PlaygroundImage } from "../../../models/image-to-image";
import styles from "./FeedbackButtons.module.scss";
import { Feedback, FeedbackTypeEnum } from "./FeedbackButtons.tsx";

type IProps = {
	image: PlaygroundImage;
	onClose: () => void;
	open: boolean;
	appName: string;
	top: number;
	left: number;
	projectName?: string;
	projectId?: string;
	modelName?: string;
	modelId?: string;
};

interface StyleProps {
	top: number;
	left: number;
}

const FeedbackPopup = ({
	image,
	open,
	onClose,
	appName,
	top,
	left,
	projectName,
	projectId,
	modelName,
	modelId,
}: IProps) => {
	const useStyles = makeStyles<Theme, StyleProps>((_theme: Theme) => ({
		dialog: (props: StyleProps) => ({
			left: `${props.left}px`,
			top: `${props.top}px`,
		}),
	}));

	const { t } = useTranslation("translation", { keyPrefix: "feedbackButtons" });
	const { playgroundStore } = useAppStore();
	const [feedbackText, setFeedbackText] = useState<string>("");
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState<boolean>(false);
	const [feedbackCheckboxes, setFeedbackCheckboxes] = useState<boolean[]>([false, false, false, false, false]);
	const classes = useStyles({ top: top, left: left });

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event: any) => {
		event.stopPropagation();
		if (!event.target.closest(".feedbackContainerRef")) {
			onClose();
		}
	};

	const sendNegativeFeedback = async (e: any, imageUrl: string) => {
		e.stopPropagation();

		let feedbackTexts: string[] = [];
		feedbackCheckboxes.forEach((checked, index) => {
			if (checked) feedbackTexts.push(t(`disLike.checkboxes.${index + 1}`));
		});

		if (feedbackText !== "") {
			feedbackTexts.push(feedbackText);
		}

		const feedback = feedbackTexts.join(", \n") || "";

		let newFeedback: Feedback = {
			appName: appName,
			type: FeedbackTypeEnum.Negative,
			imageUrl: imageUrl,
			text: `${feedback}`,
		};

		if (modelId !== undefined) {
			newFeedback = {
				...newFeedback,
				projectName: projectName,
				projectId: projectId,
				modelName: modelName,
				modelId: modelId,
			};
		}

		const alreadySent = playgroundStore.feedbackHistory.find(
			(feedback) => feedback.imageUrl === imageUrl && feedback.type === FeedbackTypeEnum.Negative,
		);

		if (!alreadySent) {
			await playgroundStore.addOrUpdateFeedback(newFeedback);
		}
		setFeedbackText("");
		setIsFeedbackSubmitted(true);

		setTimeout(() => {
			onClose();
		}, 2000);
	};

	const handleCheckboxChange = (index: number, checked: boolean) => {
		setFeedbackCheckboxes((prev) => prev.map((value, i) => (i === index ? checked : value)));
	};

	return (
		<Dialog
			classes={{
				root: clsx(classes.dialog, styles.root),
				paper: styles.dialog,
				container: styles.dialogContainer,
			}}
			onClose={() => {
				onClose();
			}}
			hideBackdrop={true}
			open={open}
		>
			<Box
				className={clsx({
					[styles.feedbackContainer]: true,
					["feedbackContainerRef"]: true,
				})}
			>
				<Box className={styles.feedbackElements}>
					{!isFeedbackSubmitted ? (
						<Box className={styles.feedbackInput}>
							<InputLayout
								className={clsx({
									[styles.inputText]: true,
									[styles.checkboxes]: true,
								})}
								label={t("disLike.label1")}
							>
								{Array.from({ length: 5 }).map((_, i: number) => (
									<FormControlLabel
										key={i}
										className={styles.feedbackCheckbox}
										onClick={(e) => e.stopPropagation()}
										control={
											<BriaCheckbox
												onChange={(e) => {
													e.stopPropagation();
													handleCheckboxChange(i, e.target.checked);
												}}
												checked={feedbackCheckboxes[i]}
											/>
										}
										label={t(`disLike.checkboxes.${i + 1}`)}
									/>
								))}
							</InputLayout>
							<InputLayout className={styles.inputText} label={t("disLike.label2")}>
								<TextField
									value={feedbackText}
									onChange={(e) => {
										setFeedbackText(e.target.value);
									}}
									onClick={(e) => {
										e.stopPropagation();
									}}
									placeholder={t("disLike.placeholder")}
									multiline
									maxRows={3}
									InputProps={{
										classes: {
											root: styles.bigTextField,
										},
									}}
								/>
								<BriaButton
									className={styles.button}
									buttonType="primary"
									onClick={(e) => {
										e.stopPropagation();
										sendNegativeFeedback(e, image.url);
									}}
								>
									{playgroundStore.loadingFeedback ? (
										<Box className={classes.circleLoader}>
											<CircularProgress color="inherit" size={15} />
										</Box>
									) : (
										<>{t("disLike.button")}</>
									)}
								</BriaButton>
							</InputLayout>
						</Box>
					) : (
						<Box className={styles.feedbackSubmitted}>
							<img className={styles.feedbackSubmittedIcon} src={SubmittedFeedbackSVG} />
							<Typography className={styles.feedbackSubmittedMessage}>
								{t("disLike.feedback1")}
								<br />
								{t("disLike.feedback2")}
							</Typography>
						</Box>
					)}
					<CloseIcon
						onClick={onClose}
						className={clsx({
							[styles.closeIcon]: true,
							["closeIcon"]: true,
						})}
					/>
				</Box>
			</Box>
		</Dialog>
	);
};

export default observer(FeedbackPopup);

import { Box } from "@mui/material";
import { ChevronDown, ChevronUp } from "baseui/icon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { MAX_THUMBNAIL_IMAGES } from "../../../constants/ImageToImageConstants.ts";
import { PlaygroundImage } from "../../../models/playground.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import { BriaImageProps } from "../../common/Galleries/BriaImage.tsx";
import { SkeletonImage } from "../../common/Skeleton/SkeletonGallery.tsx";
import styles from "./ThumbnailsNavigation.module.scss";

interface IThumbnailsNavigation {
	images: (BriaImageProps & PlaygroundImage)[];
	selectedIndex?: number;
	slideTo: (index: number) => void;
}

const ThumbnailsNavigation = ({ images, selectedIndex = 0, slideTo }: IThumbnailsNavigation) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const selectedRef = useRef<HTMLDivElement>(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(selectedIndex);

	useEffect(() => {
		if (selectedRef.current) {
			if (selectedImageIndex === 0 && containerRef.current) {
				containerRef.current.scrollTop = 0;
			} else if (
				(selectedImageIndex === images.length - 1 || selectedImageIndex === -1) &&
				containerRef.current
			) {
				containerRef.current.scrollTop = containerRef.current.scrollHeight;
			} else {
				selectedRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		}
	}, [images, containerRef, selectedImageIndex]);

	useEffect(() => {
		setSelectedImageIndex(selectedIndex);
	}, [selectedIndex]);

	return (
		<Box className={styles.container}>
			{selectedImageIndex !== 0 && images.length > MAX_THUMBNAIL_IMAGES && (
				<BriaButton
					buttonType="textMedium"
					className={clsx(styles.navigationBtn, styles.prevBtn)}
					onClick={() => slideTo(selectedImageIndex - 1)}
				>
					<ChevronUp />
				</BriaButton>
			)}
			<Box className={styles.sliderWrapper} ref={containerRef}>
				{images.map((image, index) => {
					if (image.loading) return <SkeletonImage key={index} />;

					return (
						<Box
							key={index}
							className={clsx(styles.sliderItem, {
								[styles.selectedImage]: index === selectedImageIndex,
							})}
							onClick={() => slideTo(index)}
							ref={index === selectedImageIndex ? selectedRef : null}
						>
							<img src={image.url} className={styles.sliderImage} alt={`Thumbnail ${index}`} />
						</Box>
					);
				})}
			</Box>
			{selectedImageIndex !== images.length - 1 && images.length > MAX_THUMBNAIL_IMAGES && (
				<BriaButton
					buttonType="textMedium"
					className={clsx(styles.navigationBtn, styles.nextBtn)}
					onClick={() => slideTo(selectedImageIndex + 1)}
				>
					<ChevronDown />
				</BriaButton>
			)}
		</Box>
	);
};

export default observer(ThumbnailsNavigation);

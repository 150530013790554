import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { ThemeMode } from "../../../constants/themes.ts";
import { useTheme } from "../../../context/ThemeContext.tsx";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import classes from "../Login/Login.module.scss";
import styles from "./ResetPassword.module.scss";

interface IProps {}

const ResetPassword: React.FC<IProps> = () => {
	const { t } = useTranslation();
	const navigate = useSecureNavigate();
	const { confirmPasswordResetFunction, loading, errorMessage } = useAuthService();
	const { changeTheme } = useTheme();
	let state = useLocation().state;
	const oobCode = state.oobCode;
	let continueUrl = state.continueUrl;

	if (continueUrl) {
		const url = new URL(continueUrl);
		const prompt = url.searchParams.get("prompt");
		if (!state) {
			state = {};
		}
		if (prompt) {
			state.prompt = decodeURIComponent(prompt);
		}
		continueUrl = new URL(continueUrl).pathname + `?${url.searchParams.toString()}`;
		if (continueUrl.includes(APPS.AUTOMOTIVE)) {
			changeTheme(ThemeMode.Dark);
		}
	}

	const initialValues = {
		newPassword: "",
		confirmPassword: "",
	};

	const handleSubmit = async (values: any) => {
		await confirmPasswordResetFunction(oobCode ?? "", values.newPassword);
		const loginPath = continueUrl
			? `${RouterConstants.LOGIN.path}?continueUrl=${encodeURIComponent(continueUrl)}`
			: RouterConstants.LOGIN.path;
		navigate(loginPath, { state: { ...state, passwordReset: true }, replace: true });
	};

	return (
		<Box className={styles.content}>
			<Typography className={styles.mainTitle}>{t("resetPassword")}</Typography>

			<Formik
				initialValues={initialValues}
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={Yup.object({
					newPassword: Yup.string().required(t("passwordIsRequired")),
					confirmPassword: Yup.string().test("passwords-match", "Passwords must match", function (value) {
						return this.parent.newPassword === value;
					}),
				})}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur }) => (
					<Form className={styles.form} noValidate>
						<Box className={styles.formElements}>
							<BriaInput
								id="newPassword"
								label={t("newPassword")}
								type="password"
								autoComplete="current-password"
								name="newPassword"
								value={values.newPassword}
								onChange={handleChange}
								onBlur={handleBlur}
								error={(Boolean(errors.newPassword) && touched.newPassword) as boolean}
								helperText={touched.newPassword ? errors.newPassword : ""}
								className={classes.textField}
							/>
							<BriaInput
								id="confirmPassword"
								label={t("retypeNewPassword")}
								type="password"
								autoComplete="current-password"
								name="confirmPassword"
								value={values.confirmPassword}
								onChange={handleChange}
								onBlur={handleBlur}
								error={(!!errors.confirmPassword && touched.confirmPassword) as boolean}
								helperText={touched.confirmPassword ? errors.confirmPassword : ""}
								className={classes.textField}
							/>

							{errorMessage && (
								<Typography className={classes.error} color="secondary">
									<ErrorIcon className={classes.errorIcon} />
									{t(`${errorMessage}`)}
								</Typography>
							)}

							<BriaButton
								buttonType="primary"
								type="submit"
								variant="contained"
								color="secondary"
								fullWidth={true}
							>
								{t("resetPassword")}
								<Box className={classes.circleLoader}>
									{loading && <CircularProgress color="inherit" size={25} />}
								</Box>
							</BriaButton>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default ResetPassword;

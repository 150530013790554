import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";
import StopIcon from "../../../../assets/images/icons/StopIcon.tsx";
import StoppableButton from "../../../../components/common/StoppableButton/StoppableButton.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../hooks/useImageToImageConfig.tsx";
import { useManualPlacementConfig } from "../../../../hooks/useManualPlacementConfig.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import ExpandableMenuButton from "../../../../layout/ExpandableMenu/Button/ExpandableMenuButton.tsx";
import ExpandableMenuLayout from "../../../../layout/ExpandableMenu/Menu/ExpandableMenuLayout.tsx";
import { ImageEditingViewMode, ImageToImageConfigType } from "../../../../models/image-to-image.ts";
import { isPlaygroundLoadingImages } from "../../../../utils/index.ts";
import ConfigButtonLabel from "../../ImageToImage/Config/ConfigButtonLabel/ConfigButtonLabel.tsx";
import ConfigDisplay from "../../ImageToImage/Config/ConfigDisplay/ConfigDisplay";
import BackgroundConfig from "../../ImageToImage/Config/Features/Background/BackgroundConfig";
import EraseObject from "../../ImageToImage/Config/Features/EraseObject/EraseObject.tsx";
import ForegroundPlacement from "../../ImageToImage/Config/Features/ForegroundPlacement/ForegroundPlacement";
import ManualPlacement from "../../ImageToImage/Config/Features/ManualPlacement/ManualPlacement.tsx";
import SizeConfig from "../../ImageToImage/Config/Features/Size/SizeConfig";
import styles from "./AutomotiveConfig.module.scss";

const AutomotiveConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.automotive.config" });
	const { imageToImageStore, playgroundStore, analyticsStore } = useAppStore();
	const {
		canExpandBackground,
		canRemoveBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canBlurBackground,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
	} = useImageToImageConfig();
	const { checkResolutions } = useManualPlacementConfig();

	const [activeConfig, setActiveConfig] = useState<string>("");
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [isSingleMode, setIsSingleMode] = useState<boolean>(false);

	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.AUTOMOTIVE);

	useEffect(() => {
		if (playgroundStore.selectedConfig !== APPS.AUTOMOTIVE) {
			playgroundStore.handleAppChange(APPS.AUTOMOTIVE);
		}
		setIsSingleMode(playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE);
	}, [imageToImageStore.isGeneratingImages, playgroundStore.imageEditingViewMode]);

	const handleGenerateImageToImage = async () => {
		const { logEvent } = analyticsStore;

		await imageToImageStore.generateImageToImage();
		checkResolutions();
		logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_GENERATE);

		// Background configs events
		canExpandBackground() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_EXPAND_IMAGE);
		canRemoveBackground() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_REMOVE_BACKGROUND);
		canBlurBackground() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_BLUR_BACKGROUND);
		canApplySolidBackground() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_SOLID_BACKGROUND);
		canGenerateBackgroundByText() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_GENERATE_BACKGROUND_BY_TEXT);

		// Size configs events
		canApplyAspectRatio() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_ASPECT_RATIO);
		canAppleCustomSize() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_CUSTOM_SIZE);
		canAppleMediumDestination() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_MEDIUM_DESTINATION);

		// Foreground placement configs
		canGenerateForegroundPlacement() && logEvent(ANALYTICS_EVENTS.AUTOMOTIVE_GENERATE_FOREGROUND);
	};

	const handleDeleteConfig = (configKey: keyof ImageToImageConfigType, field: string, value: any) => {
		const currentConfig = { ...(imageToImageStore.config[configKey] as Record<string, any> | undefined) };

		imageToImageStore.handleConfigChange(configKey, {
			...currentConfig,
			[field]: value,
		});
	};

	return (
		<Box className={styles.wrapper}>
			<ExpandableMenuLayout
				className={clsx(styles.config, styles.imageToImageConfigs)}
				setActiveConfig={setActiveConfig}
				isSubMenuOpen={isSubMenuOpen}
			>
				<Box className={styles.configHeader}>
					<Typography variant="h6" className={styles.title}>
						{isSingleMode ? t("singleModeTitle") : t("bulkModeTitle")}
					</Typography>
					<Typography className={styles.subTitle}>
						{isSingleMode ? t("singleModeSubTitle") : t("bulkModeSubTitle")}
					</Typography>
				</Box>

				<ExpandableMenuButton
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title"), subTitle: t("features.size.subTitle") }}
					isActive={activeConfig === t("features.size.title")}
					hidden={isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.size.button")}
						info={t("features.size.tooltip")}
					/>
				</ExpandableMenuButton>

				{isSingleMode ? (
					<ExpandableMenuButton
						expandTo={<ManualPlacement />}
						subMenuPanelProps={{
							title: t("features.manualPlacement.title"),
							subTitle: t("features.manualPlacement.subTitle"),
						}}
						isActive={activeConfig === t("features.manualPlacement.title")}
					>
						<ConfigButtonLabel
							className={clsx(styles.buttonLabel)}
							label={t("features.manualPlacement.button")}
							info={t("features.manualPlacement.tooltip")}
						/>
					</ExpandableMenuButton>
				) : (
					<ConfigDisplay
						config={imageToImageStore.config.size}
						onDelete={(configKey, value) => {
							handleDeleteConfig("size", configKey, value);
							updateSelectedSizeConfigs();
						}}
						expandTo={<SizeConfig />}
						subMenuPanelProps={{ title: t("features.size.title") }}
					/>
				)}

				<Divider className={styles.divider} />

				<ExpandableMenuButton
					expandTo={
						<BackgroundConfig
							onSubMenuOpen={setIsSubMenuOpen}
							handleGenerateImageToImage={handleGenerateImageToImage}
							hiddenConfigs={["blur"]}
						/>
					}
					subMenuPanelProps={{
						title: t("features.background.title"),
						subTitle: t("features.background.subTitle"),
					}}
					isActive={activeConfig === t("features.background.title")}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.background.button")}
						info={t("features.background.tooltip")}
					/>
				</ExpandableMenuButton>

				{!isSingleMode && (
					<ConfigDisplay
						config={imageToImageStore.config.background}
						onDelete={(configKey, value) => {
							handleDeleteConfig("background", configKey, value);
							updateSelectedBackgroundConfigs();
						}}
						expandTo={
							<BackgroundConfig
								onSubMenuOpen={setIsSubMenuOpen}
								handleGenerateImageToImage={handleGenerateImageToImage}
							/>
						}
						subMenuPanelProps={{ title: t("features.background.title") }}
					/>
				)}

				<Divider className={styles.divider} />

				{/* <ExpandableMenuButton
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{
						title: t("features.foregroundPlacement.title"),
						subTitle: t("features.foregroundPlacement.subTitle"),
					}}
					isActive={activeConfig === t("features.foregroundPlacement.title")}
					hidden={isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.foregroundPlacement.button")}
						info={t("features.foregroundPlacement.tooltip")}
					/>
				</ExpandableMenuButton> */}

				{!isSingleMode && (
					<ConfigDisplay
						config={imageToImageStore.config.foreground_placement}
						onDelete={(configKey, value) => {
							handleDeleteConfig("foreground_placement", configKey, value);
							updateSelectedForegroundPlacementConfigs();
						}}
						expandTo={<ForegroundPlacement />}
						subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					/>
				)}
				{/* {!isSingleMode && <Divider className={styles.divider} />} */}

				<ExpandableMenuButton disabled>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.reflectionAndLighting.button")}
						info={t("features.reflectionAndLighting.tooltip")}
						isPrimary
					/>
				</ExpandableMenuButton>
				<Divider className={styles.divider} />
				<ExpandableMenuButton disabled>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.harmonization.button")}
						info={t("features.harmonization.tooltip")}
						isPrimary
					/>
				</ExpandableMenuButton>
				{isSingleMode && <Divider className={styles.divider} />}

				<ExpandableMenuButton
					expandTo={<EraseObject />}
					subMenuPanelProps={{
						title: t("features.eraseObject.title"),
						subTitle: t("features.eraseObject.subTitle"),
					}}
					isActive={activeConfig === t("features.eraseObject.title")}
					hidden={!isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.eraseObject.button")}
						info={t("features.eraseObject.tooltip")}
					/>
				</ExpandableMenuButton>
			</ExpandableMenuLayout>

			{!isSingleMode && (
				<StoppableButton
					condition={
						hasLoadingImages &&
						(!imageToImageStore.isEraseConfigsEnabled || !imageToImageStore.isGeneratingImages)
					}
					onStopButtonClick={() => imageToImageStore.abortImageGeneration()}
					onButtonClick={handleGenerateImageToImage}
					disabled={!isGenerateEnabled()}
					buttonLabel={
						<>
							{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
								? t("tooManySelected")
								: t("button")}
							{imageToImageStore.getGeneratedImagesCount() > 0 &&
								isGenerateEnabled() &&
								` ${imageToImageStore.getGeneratedImagesCount()} ${t("results")}`}
						</>
					}
					stopButtonLabel={
						<>
							<StopIcon /> {t("stopGenerating")}
						</>
					}
					fullWidth
					className={`generate-image-to-image ${styles.stopGeneratingBt}`}
				/>
			)}
		</Box>
	);
};

export default observer(AutomotiveConfig);

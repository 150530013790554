import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { IGroupOption } from "../../../components/common/CustomSingleSelectDropdown/SingleSelectGroupedDropDown.tsx";
import {
	TextToImageModels,
	getModelName,
	getModelVersion,
} from "../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import { BackendError } from "../../../config/axios.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import {
	DEFAULT_TAILORED_MODEL_INFLUENCE,
	IMAGE_REFERENCE,
	IMAGE_REFERENCE_DISABLED_MODELS,
} from "../../../constants/TextToImageConstants.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import { PlaygroundImage, PlaygroundResult } from "../../../models/image-to-image.ts";
import { SyntheticEventProps, TextToImageConfigType } from "../../../models/text-to-image.ts";
import QueryService from "../../../utils/QueryService.ts";
import iframeStore from "../../IframeNew/iframe-store.tsx";

export interface ITextToImageStore {
	config: TextToImageConfigType;
	models: TextToImageModels;
	loadingModels: boolean;
	mediums: string[];
	loadingMediums: boolean;
	hideNegativePrompt: boolean;
	errors: { generate?: BackendError };
	handleConfigChange: <K extends keyof TextToImageConfigType>(key: K, value: TextToImageConfigType[K]) => void;
	getModels: () => Promise<TextToImageModels>;
	getMediums: () => Promise<string[]>;
	generateTextToImage: () => Promise<void>;
	clearConfig: () => void;
	isTextToVector: () => boolean;
	handleText2ImagePopupChange: (e: ChangeEvent<HTMLInputElement>, selectedOption: IGroupOption) => void;
	handleUploadImage: (e: ChangeEvent<HTMLInputElement> | SyntheticEventProps) => Promise<void>;
	imageReference: PlaygroundImage;
	isImageReferenceEnabled: () => boolean;
	isUploadingImageReference: boolean;
	abortImageGeneration: () => void;
	isGeneratingImages: boolean;

	handleDeleteImage(): Promise<void>;
}

export default class TextToImageStore implements ITextToImageStore {
	private queryService: QueryService = new QueryService("/text-to-image");
	private abortController: AbortController = new AbortController();
	isGeneratingImages = false;
	rootStore: IRootStore;
	config: TextToImageConfigType = defaultTextToImageConfig;
	models: TextToImageModels = {};
	loadingModels: boolean = false;
	hideNegativePrompt: boolean = false;
	mediums: string[] = [];
	loadingMediums: boolean = false;
	errorToast = useErrorToast();
	errors: { generate?: BackendError } = {};
	imageReference: PlaygroundImage = { id: "", url: "" };
	isUploadingImageReference = false;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	isTextToVector = () => {
		return window.location.pathname.endsWith(RouterConstants.TEXT_TO_VECTOR.path);
	};

	isImageReferenceEnabled = () => {
		return this.imageReference && this.imageReference.url.trim().length > 0;
	};

	clearConfig = () => {
		this.config = defaultTextToImageConfig;
	};

	handleConfigChange = <K extends keyof TextToImageConfigType>(key: K, value: TextToImageConfigType[K]) => {
		this.config[key] = value;
	};
	handleBulkConfigChange = <K extends keyof TextToImageConfigType>(
		keyOrChanges: K | Partial<TextToImageConfigType>,
		value?: TextToImageConfigType[K],
	) => {
		if (typeof keyOrChanges === "string") {
			// Handle single key-value update
			this.config[keyOrChanges] = value as TextToImageConfigType[K];
		} else {
			// Handle multiple properties update
			this.config = { ...this.config, ...keyOrChanges };
		}
		console.log(this.config, "this.config");
	};

	abortImageGeneration = () => {
		if (this.abortController) {
			this.abortController.abort();
			this.rootStore.playgroundStore.removeNotLoadingImages();
		}
	};

	generateTextToImage = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		this.abortController = new AbortController();
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config },
			type: APPS.TEXT_TO_IMAGE,
			images: Array.from({ length: this.config.num_results! }).map((_) => ({
				id: "",
				url: "",
				seed: NaN,
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

		try {
			const formData = new FormData();
			runInAction(() => {
				this.isGeneratingImages = true;
			});

			this.imageReference.file && formData.append("file", this.imageReference.file);

			formData.append(
				"config",
				JSON.stringify({
					...this.config,
					prompt: this.config.generation_prefix
						? this.config.generation_prefix + this.config.prompt
						: this.config.prompt,
				}),
			);

			const generatedImages: PlaygroundImage[] = await this.queryService.post(
				"/",
				formData,
				{ "Content-Type": "multipart/form-data" },
				{ signal: this.abortController.signal },
			);
			iframeStore.sendGeneratePostMessage(this.config, generatedImages);

			runInAction(() => {
				this.isGeneratingImages = false;
				playgroundStore.playgroundResults[savedIndex].images = generatedImages.map((image) => ({
					...image,
					loading: true,
					id: uuidv4(),
					config: playgroundStore.playgroundResults[savedIndex].config,
					type: APPS.TEXT_TO_IMAGE,
					aspect_ratio: this.imageReference?.file ? "auto " : this.config.aspect_ratio,
				}));
			});
		} catch (err: any) {
			this.isGeneratingImages = false;
			this.errors.generate = {
				message: err.response.data.message,
				status: err.response.status,
			};
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
			});
			return Promise.reject(err);
		}
	};

	getModels = async (): Promise<TextToImageModels> => {
		try {
			if (Object.keys(this.models).length === 0) {
				this.loadingModels = true;
				const models: TextToImageModels = await this.queryService.get("/models");
				runInAction(() => {
					this.loadingModels = false;
					this.models = models;
				});
			}

			const iframeEnabledModels = iframeStore.iframe?.config.image_generation_config?.enabled_foundation_models;
			if (iframeEnabledModels)
				this.models = Object.fromEntries(
					Object.entries(this.models)
						.filter(([type]) => type in iframeEnabledModels)
						.map(([type, details]) => [
							type,
							{
								...details,
								versions: details.versions.filter((version) => version in iframeEnabledModels[type]),
							},
						]),
				);

			return this.models;
		} catch (e: any) {
			this.loadingModels = false;
			return Promise.reject(`Error loading text-to-image models: ${e.message || e.toString()}`);
		}
	};

	getMediums = async (): Promise<string[]> => {
		try {
			if (Object.keys(this.mediums).length === 0) {
				this.loadingMediums = true;
				const mediums: string[] = await this.queryService.get("/mediums");

				runInAction(() => {
					this.loadingMediums = false;
					this.mediums = mediums;
				});
			}
			return this.mediums;
		} catch (e: any) {
			this.loadingMediums = false;
			return Promise.reject(`Error loading text-to-image mediums: ${e.message || e.toString()}`);
		}
	};
	clearTailoredModelsSelections = () => {
		this.handleBulkConfigChange({
			tailored_model_id: undefined,
		});
	};
	clearFoundationModelsSelections = () => {
		this.handleBulkConfigChange({
			model: "",
			model_version: "",
		});
	};
	handleText2ImagePopupChange = (e: ChangeEvent<HTMLInputElement>, selectedOption: IGroupOption) => {
		this.hideNegativePrompt = false;
		const isTailoredModel = selectedOption && selectedOption?.extraData?.isTailoredModel;
		this.handleConfigChange("model_name", selectedOption.key);

		if (!isTailoredModel) {
			this.clearTailoredModelsSelections();
			this.handleBulkConfigChange({
				model: getModelName(e.target.value as string),
				model_version: getModelVersion(e.target.value as string),
			});
			if (selectedOption.value.toLowerCase().includes("fast")) {
				this.hideNegativePrompt = true;
				this.handleConfigChange("negative_prompt", undefined);
			}
		} else {
			this.clearFoundationModelsSelections();
			this.handleBulkConfigChange({
				tailored_model_id: selectedOption?.id,
				negative_prompt: undefined,
			});
			this.hideNegativePrompt = true;
		}
	};

	handleUploadImage = async (e: ChangeEvent<HTMLInputElement> | SyntheticEventProps) => {
		const file = e.target.files?.[0];
		e.target.value = "";

		if (file) {
			runInAction(() => {
				this.isUploadingImageReference = true;
			});
			// const compressedBlob = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
			// const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });

			const imageBeforeUpload: PlaygroundImage = {
				id: uuidv4(),
				url: URL.createObjectURL(file),
				file: file,
				type: "upload",
				offline_props: {
					prefix_id: file.name.split(".")[0],
					variation: 0,
				},
			};

			runInAction(() => {
				this.isUploadingImageReference = false;
				this.imageReference = imageBeforeUpload;
				this.config.aspect_ratio = defaultTextToImageConfig.aspect_ratio;
				this.config.medium = defaultTextToImageConfig.medium;
				if (IMAGE_REFERENCE_DISABLED_MODELS.includes(this.config.model) && !this.config.tailored_model_id) {
					this.config.model = defaultTextToImageConfig.model;
					this.config.model_version = defaultTextToImageConfig.model_version;
					this.config.model_name = defaultTextToImageConfig.model_name;
				}
			});
		}
	};

	handleDeleteImage = async () => {
		runInAction(() => {
			this.imageReference = { id: "", url: "" };
		});
	};
}

export const defaultTextToImageConfig: TextToImageConfigType = {
	model: "fast",
	model_version: "2.3",
	model_name: "Bria 2.3 fast",
	aspect_ratio: "4:3",
	medium: "",
	num_results: 4,
	steps_num: 8,
	prompt_enhancement: true,
	tailored_model_influence: DEFAULT_TAILORED_MODEL_INFLUENCE,
	image_reference: IMAGE_REFERENCE,
	fast: true,
};

import { Box, Pagination, Typography, styled } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, ReactElement, ReactNode, useEffect, useState } from "react";
import BriaButtonGroup from "../../components/common/BriaButtonGroup/BriaButtonGroup.tsx";
import { BriaButtonProps } from "../../components/common/BriaIconButton/BriaIconButton.tsx";
import { FilterByOptions, PaginationOptions } from "../../components/common/BriaTable/BriaTable";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import SearchInput from "../../components/common/SearchInputField/SearchInputField.tsx";
import styles from "./DataDashboardLayout.module.scss";

type DataDashboardLayoutProps<T> = {
	title?: string;
	description?: string;
	primaryButton?: ReactElement<BriaButtonProps>;
	secondaryButton?: ReactElement<BriaButtonProps>;
	enableSearch?: boolean;
	handleSearchChange?: (value: any) => void;
	searchBoxPlaceholder?: string;
	loading?: boolean;
	hideHeader?: boolean;
	filterBy?: FilterByOptions<T>;
	paginationOptions?: PaginationOptions<T>;
	children?: ReactNode;
	className?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	searchInputVisible?: boolean;
	searchBoxPosition?: string;
};

const DataDashboardLayout = <T,>({
	title,
	description,
	primaryButton,
	secondaryButton,
	enableSearch,
	handleSearchChange,
	searchBoxPlaceholder = "Search",
	children,
	loading = false,
	filterBy,
	paginationOptions,
	hideHeader = false,
	className,
	titleClassName,
	descriptionClassName,
	searchInputVisible = false,
	searchBoxPosition = "right",
}: DataDashboardLayoutProps<T>) => {
	const [page, setPage] = useState(0);
	const pagesCount = paginationOptions ? Math.ceil(paginationOptions.totalRows / paginationOptions.rowsPerPage!) : 1;

	const handleChangePage = async (_event: ChangeEvent<unknown> | null, page: number) => {
		setPage(page - 1);
		await paginationOptions?.loadNextPage(page, filterBy);
	};

	useEffect(() => {
		if (filterBy) {
			setPage(0);
		}
	}, [filterBy]);

	const [searchValue, setSearchValue] = useState("");

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		handleSearchChange?.(e);
	};

	const renderSearchInput = () => {
		return (
			enableSearch && (
				<SearchInput
					searchIcon
					value={searchValue}
					onChange={handleSearch}
					className={styles.searchRootStyle}
					classes={{ root: styles.searchRootStyle }}
					placeholder={searchBoxPlaceholder}
					showInput={searchInputVisible}
				/>
			)
		);
	};

	return (
		<Box className={`${styles.container} ${className}`}>
			{!hideHeader && (
				<Box className={styles.header}>
					<Box style={{ display: title ? "flex" : "block" }} className={styles.headerFirstLine}>
						<Box className={styles.titleSearchWrapper}>
							<Typography className={clsx(styles.title, titleClassName)}>{title}</Typography>
							{searchBoxPosition === "right" && renderSearchInput()}
						</Box>
						<BriaButtonGroup className={styles.actionBtns}>
							{searchBoxPosition === "left" && renderSearchInput()}
							{secondaryButton} {primaryButton}
						</BriaButtonGroup>
					</Box>
					<Typography className={clsx(styles.description, descriptionClassName)}>{description}</Typography>
				</Box>
			)}
			<LoadingPlaceholder className={styles.loading} isLoading={loading} alwaysRenderChildren>
				{children}
			</LoadingPlaceholder>
			{paginationOptions && pagesCount > 1 && (
				<Box className={styles.pagination}>
					<CustomPagination onChange={handleChangePage} count={pagesCount} page={page + 1} />
				</Box>
			)}
		</Box>
	);
};

export default observer(DataDashboardLayout);

const CustomPagination = styled(Pagination)(({ theme }) => ({
	ul: {
		"li:not(:last-child,:first-of-type)": {
			button: {
				fontWeight: 600,
				fontFamily: "Montserrat",
				fontSize: "10.6px",
				width: 24,
				minWidth: "unset",
				height: 24,
				borderRadius: "50%",
				backgroundColor: "#E7E7E7",
				"&.Mui-selected": {
					color: "white",
					backgroundColor: theme.palette.primary.light,
				},
			},
		},
	},
}));

import { Box, Dialog, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WelcometoBRIACampaigngenerator from "../../../assets/images/pngs/WelcometoBRIACampaigngenerator.png";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import { USER_SETTINGS } from "../../../constants/UserConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import useGuideTemplatesUtils from "../../views/DesignEditor/components/TemplatesCreationGuideSection/useGuideTemplatesUtils.tsx";
import styles from "./TemplateCreationTourPopup.module.scss";

const TemplateCreationTourPopup = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.TemplateCreationPopup" });
	const { campaignStore, authStore } = useAppStore();
	const [showTemplateCreationTour, setShowTemplateCreationTour] = useState(false);
	const { handleOpenGuide } = useGuideTemplatesUtils();
	useEffect(() => {
		const loadData = async () => {
			if (campaignStore.isAdminMode) {
				const _hideCampaignTemplateTourPopup = await authStore.user?.getFreshSettingsCopy(
					USER_SETTINGS.hideCampaignTemplateTourPopup,
				);
				setShowTemplateCreationTour(!_hideCampaignTemplateTourPopup);
			}
		};

		loadData();
	}, [campaignStore.isAdminMode]);

	const onClose = () => {
		authStore.user?.updateSettings(USER_SETTINGS.hideCampaignTemplateTourPopup, true);
		setShowTemplateCreationTour(false);
	};

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={onClose}
			open={showTemplateCreationTour && campaignStore.isAdminMode}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("header")}</Typography>
					<Box className={styles.descriptionContainer}>
						<Typography
							className={styles.description}
							dangerouslySetInnerHTML={{ __html: t("p1") }}
						></Typography>
						<Typography className={styles.description}>{t("p2")}</Typography>
						<Typography className={styles.description}>{t("p3")}</Typography>
					</Box>
					<Box className={styles.buttonsContainer}>
						<Box className={styles.buttons}>
							<BriaButton
								onClick={() => {
									handleOpenGuide(1);
									onClose();
								}}
								buttonType="primaryMedium"
							>
								{t("templateCreaionTour")}
							</BriaButton>
							<BriaButton onClick={onClose} buttonType="tertiaryMedium">
								{t("skip")}
							</BriaButton>
						</Box>
					</Box>
				</Box>
				<Box className={styles.imageContainer}>
					<img alt={t("header")} src={WelcometoBRIACampaigngenerator} />
				</Box>
			</Box>
		</Dialog>
	);
};
const ObservedComponent = observer(TemplateCreationTourPopup);
export default ObservedComponent;

import { Box } from "@mui/material";
import clsx from "clsx";
import { useContext } from "react";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import SmartImageHeader from "../../../../../components/common/SmartImageHeader/SmartImageHeader.tsx";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import FabricObjectsProperties from "../../Panels/panelItems/Common/FabricObjectsProperties/FabricObjectsProperties.tsx";
import BrandSettings from "../BrandSettings/BrandSettings.tsx";
import Flip from "../Shared/Flip/Flip";
import styles from "./Image.module.scss";
export default function () {
	const { isPopupView } = useContext(DesignEditorContext);
	const activeObject = useActiveObject() as any;
	const { uiStore } = useAppStore();
	return (
		<Box
			className={clsx({
				[styles.container]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box
				className={clsx({
					[styles.imageToolBar]: true,
					[styles.noMarginRight]: uiStore.showGuideSection,
				})}
			>
				<Box className={styles.imageControlIcons}>
					<Flip />
					<FabricObjectsProperties />
				</Box>
				<EditAllAdsSwitch />
			</Box>

			<BrandSettings activeObject={activeObject} showBrandLogoDropdown />
			{!isPopupView && <SmartImageHeader />}
		</Box>
	);
}

import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import DownloadIcon from "../../../../assets/images/icons/DownloadIcon";
import FullscreenIcon from "../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip";
import { BriaImageProps } from "../../../../components/common/Galleries/BriaImage.tsx";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../../hooks/useStores";
import { ImageToImageConfigType, PlaygroundImage, PlaygroundResult } from "../../../../models/image-to-image";
import { isFoxApps, isPlaygroundLoadingImages } from "../../../../utils";
import iframeStore from "../../../IframeNew/iframe-store.tsx";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay";
import ITIGallery from "./Gallery/ITIGallery";
import styles from "./ImageToImageResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
	rowIndex?: number;
};

const ImageToImageResult = ({ playgroundResult, rowIndex }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.result" });
	const { imageToImageStore, playgroundStore, uiStore, analyticsStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);

	const loading = !!isPlaygroundLoadingImages([playgroundResult], APPS.IMAGE_TO_IMAGE);

	const loadingDuration = playgroundResult.isFromUpload ? 4 : playgroundResult?.images.length * 10;
	const loadingStrings: string[] = Object.values(t("loadingStrings", { returnObjects: true }));

	const handleDownloadImages = () => {
		uiStore.showExportImagePopup({ images: playgroundResult.images });
	};

	return (
		<Box className={clsx(styles.itiGalleryWrapper, "galleryWrapper")}>
			<Box className={styles.header}>
				<Box className={styles.headerText}>{t("header")}</Box>
				{loading ? (
					<LoadingAnimation
						loading={loading}
						progressBarTime={loadingDuration}
						showAnimation={false}
						loadingStrings={loadingStrings}
						loadingStringsPosition={"left"}
						showLoadingStrings={isFoxApps() && !playgroundResult.isFromUpload}
						loadingStringsTimeout={7000}
					/>
				) : (
					<Box className={styles.actionBtns}>
						{(!iframeStore.isIframe() ||
							iframeStore.iframe.config.general_config?.enable_download ||
							iframeStore.iframe.config.general_config?.enable_save) && (
							<BriaTooltip title={t("download")}>
								<DownloadIcon className={styles.iconButton} onClick={handleDownloadImages} />
							</BriaTooltip>
						)}
						<BriaTooltip title={t("delete")}>
							<DeleteIcon
								className={styles.iconButton}
								onClick={() => playgroundStore.deleteResult(playgroundResult.id)}
							/>
						</BriaTooltip>
						<BriaTooltip title={t("fullScreen")}>
							<FullscreenIcon className={styles.iconButton} onClick={() => setOpenFullScreen(true)} />
						</BriaTooltip>
					</Box>
				)}
			</Box>
			<ITIGallery
				images={playgroundResult.images.map(
					(image) =>
						({
							ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
							displayOverlay: "customHover",
							selectable: true,
							htmlJsonData: image.id,
							fullScreenProps: {
								compareTo: (image.config as ImageToImageConfigType)?.original_image.url,
								compareLabel:
									isFoxApps() && image.type === "imageToImage"
										? t("gallery.variationLabel", {
												variationNum: image.variationNum?.toString().padStart(2, "0"),
										  })
										: undefined,
								fileName: playgroundStore.getFileName(image),
							},
							onSuccessPulling: async () => {
								(isFoxApps() || rowIndex === 0) &&
									playgroundStore.selectImages(playgroundResult.images);
								playgroundStore.onSuccessResult(image);
								analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_ERROR_ITI, image);
							},
							onErrorPulling: (errorType) => playgroundStore.onErrorResult(image, errorType),
							async: loading,
							asyncProps: { maxAttempts: 180, abortController: imageToImageStore.abortController },
							downloadProps: { fileName: playgroundStore.getFileName(image) },
							...image,
						}) as BriaImageProps & PlaygroundImage,
				)}
				fullScreenProps={{
					open: openFullScreen,
					onClose: () => setOpenFullScreen(false),
					displayMode: "scroll",
				}}
				className={styles.gallery}
			/>
		</Box>
	);
};

export default observer(ImageToImageResult);
